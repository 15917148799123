import React, { useCallback, useState, useRef } from 'react'
import Text from '../../../components/common/Text'
import styled from '@emotion/styled'
import { usePatchBankAccountVerify } from '../../../services/bankaccount/bankaccount-query'
import { BankAccountStatus } from '../../../services/bankaccount/bankaccount-typed'
import ButtonBankAccount from '../../../components/ButtonApproveBankAccount'
import { useCurrentUser } from '../../../services/user/user-query'
import Toast from '../../../components/common/Toast'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import Input from '../../../components/common/Input'
import { useVisible } from '../../../../utils/custom-hooks'
import { Grid } from '@material-ui/core'
import Button from '../../../components/common/Button'
import { Card } from 'react-bootstrap'
import { replaceUnderScoreAndCapitalize } from '../../../../utils/helper'

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`

const FlexButton = styled(Flex)`
  margin: 10px;
`
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

type BankAccountOnPendingActionProps = {
  bankAccountId: string
}
const BankAccountOnPendingAction = (props: BankAccountOnPendingActionProps) => {
  const { bankAccountId } = props
  const classes = useStyles()
  const modalVisible = useVisible()
  const refRemark = useRef<HTMLTextAreaElement>()
  const { data: user } = useCurrentUser()
  const { id: currentUserId } = user || {}

  const { mutate: updateStatusApprove } = usePatchBankAccountVerify(currentUserId!)
  const [statusBank, setStatusBank] = useState<BankAccountStatus>()

  const handleOpenModal = useCallback(
    status => {
      modalVisible.open()
      setStatusBank(status)
    },
    [modalVisible],
  )

  const onUpdateBankAccountStatusClick = useCallback(() => {
    const form = refRemark.current
    const remark = form?.value ? form?.value : ''
    updateStatusApprove(
      { bankAccountId, status: statusBank!, remark },
      {
        onSuccess: () => {
          Toast.info(`${replaceUnderScoreAndCapitalize(statusBank)} Success`)
        },
        onError: () => {
          Toast.error(`${replaceUnderScoreAndCapitalize(statusBank)} Fail`)
        },
      },
    )
    modalVisible.close()
  }, [bankAccountId, modalVisible, statusBank, updateStatusApprove])

  return (
    <>
      <FlexButton>
        <ButtonBankAccount
          onClick={handleOpenModal.bind(null, BankAccountStatus.APPROVED)}
          name="Approve"
          variant="primary"
        />

        <ButtonBankAccount
          onClick={handleOpenModal.bind(null, BankAccountStatus.REJECTED)}
          name="Reject"
          variant="danger"
        />
      </FlexButton>
      <Modal className={classes.modal} open={modalVisible.visible} onClose={modalVisible.close}>
        <Card style={{ width: '300px', padding: '30px' }}>
          <Text style={{ alignSelf: 'center' }}>Are You Sure</Text>
          {statusBank === BankAccountStatus.REJECTED && (
            <div style={{ alignSelf: 'center', padding: '10px' }}>
              <Input
                multiline={true}
                rows={3}
                inputProps={{ maxLength: 250 }}
                inputRef={refRemark}
              ></Input>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              paddingTop: '10px',
            }}
          >
            <Button
              style={{
                alignSelf: 'center',
                marginRight: '20px',
              }}
              onClick={onUpdateBankAccountStatusClick}
            >
              Yes
            </Button>
            <Button
              style={{
                alignSelf: 'center',
                backgroundColor: '#FF0000',
                borderColor: '#FF0000',
              }}
              onClick={modalVisible.close}
            >
              No
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  )
}

export default BankAccountOnPendingAction
