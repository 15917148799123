import { first } from 'lodash'
import { FocusEvent } from 'react'
import { Area } from 'react-easy-crop/types'
import { v4 } from 'uuid'

export const imageUrlToBase64 = async (url: string) => {
  const response = await fetch(url)
  const responseBlob = await response.blob()
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(responseBlob)
  })
}

export const base64ToFile = (dataurl: string, filename: string) => {
  var arr = dataurl.split(',')
  // console.log({ arr })
  if (!arr || arr?.length) return

  const imageMeta = first(arr)
  // console.log({ imageMeta })
  const metaSpilt = imageMeta?.match(/:(.*?);/)
  // console.log({ metaSpilt })
  if (metaSpilt) {
    const mime = metaSpilt[1]
    // console.log({ mime })

    const buff = Buffer.from(arr[1])
    // console.log({ buff })

    return new File([buff], filename, { type: mime })
  }
}

export const imageUrlToFile = async (url: string) => {
  try {
    const base64 = await imageUrlToBase64(url)
    // console.log('Here is Base64 Url', { base64 })
    if (!base64) return
    const fileData = base64ToFile(base64.toString(), 'imageName.jpg')
    // console.log('Here is JavaScript File Object', { fileData })
    return fileData
  } catch (error) {
    console.error('imageUrlToFile error', { error })
  }
}

export const urlToFile = async (url: string, option?: any) => {
  try {
    const response = await fetch(url, {
      ...option,
    })

    if (!response?.ok) {
      throw Error('fetch image erorr')
    }
    const blob = await response.blob()
    // const imageObjectURL = URL.createObjectURL(blob)
    // console.log(imageObjectURL)

    return Promise.resolve(
      new File([blob], `temp-image-${v4()}.jpg`, { type: blob.type })
    )
  } catch (error) {
    console.error('urlToFile error', { error })
    return Promise.reject(error)
  }
}

export const setCursorPositionOnInputFocus =
  (positionIndex: number) => (event: FocusEvent<HTMLInputElement>) => {
    const valueLength = event.target.value.length
    const lastPosition = valueLength - 1
    const targetPosition = Math.max(0, Math.min(positionIndex, lastPosition))
    event.target.selectionStart = targetPosition
    event.target.selectionEnd = targetPosition
  }

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export const getRadianAngle = (degreeValue: number) => {
  return (degreeValue * Math.PI) / 180
}

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0
): Promise<string> => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea
  if (ctx) {
    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    ctx.imageSmoothingQuality = 'high'

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    // return canvas.toDataURL('image/png');

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob(
        (file) => {
          if (file) {
            resolve(URL.createObjectURL(file))
          }
        },
        'image/png',
        1
      )
    })
  } else {
    return Promise.reject()
  }
}

export const getCroppedImgCircle = async (
  imageSrc: string
): Promise<string> => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  canvas.height = image.height
  canvas.width = image.width

  const imageHeight = canvas.height
  const halfImageHeight = imageHeight / 2
  if (ctx) {
    ctx.beginPath()
    ctx.arc(halfImageHeight, halfImageHeight, halfImageHeight, 0, 6.28, false) //draw the circle
    ctx.clip() //call the clip method so the next render is clipped in last path
    ctx.closePath()

    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      canvas.width,
      canvas.height
    )

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob(
        (file) => {
          if (file) {
            resolve(URL.createObjectURL(file))
          }
        },
        'image/png',
        1
      )
    })
  } else {
    return Promise.reject()
  }
}
