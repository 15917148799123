import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AppStateType = {
  toast: {
    show: boolean
    title: string
    message: string
  }
}
export const appSlice = createSlice({
  name: 'app',
  initialState: {
    toast: {
      show: false,
      title: '',
      message: '',
    },
  },
  reducers: {
    showToast: (state, action: PayloadAction<{ title: string; message: string }>) => {
      const { title, message } = action.payload
      state.toast.title = title
      state.toast.message = message
      state.toast.show = true
    },
    hideToast: state => {
      state.toast.title = ''
      state.toast.message = ''
      state.toast.show = false
    },
  },
})

export const { showToast, hideToast } = appSlice.actions
