import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import Button from '../components/common/Button'

export const CustomStyledButton = styled(Button)`
  color: white;
`
interface buttonProps {
  onClick?: any
  name: string
  variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | 'link'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-danger'
    | 'outline-warning'
    | 'outline-info'
    | 'outline-dark'
    | 'outline-light'
}
const ButtonBankAccount = (props: buttonProps) => {
  const { onClick, name, variant } = props
  return (
    <Button variant={variant} onClick={onClick}>
      {name}
    </Button>
  )
}

export default ButtonBankAccount
