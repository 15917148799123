import React, { useCallback, useMemo } from 'react'
import { replaceUnderScoreAndCapitalize, useRouter } from '../../../../utils/helper'
import ListPage from '../../../components/templates/ListPage'
import { useGetNotiList } from '../../../services/notification/notification-query'
import * as paths from '../../../constants/path'
import Button from '../../../components/common/Button'
import Text from '../../../components/common/Text'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`
const ButtonStyled = styled(Button)`
  padding: 7px 13px;
`

const NotificationList = () => {
  const { push, query } = useRouter()
  const { data: notiList } = useGetNotiList({ q: query.q, page: query.page })
  const { items, meta } = notiList || {}

  const onViewClick = useCallback(
    record => {
      const routeParam: any = {
        notificationId: record.id,
      }

      push(paths.notificationDetail({ routeParam }))
    },
    [push],
  )
  const onCreateClick = useCallback(
    record => {
      push(paths.notificationCreate())
    },
    [push],
  )
  const convertUserType = useCallback((userType: string) => {
    if (userType === 'individual') {
      return 'Multi user'
    } else if (userType === 'everyone') {
      return 'All user'
    }
  }, [])
  const columns = useMemo(() => {
    return [
      {
        title: 'Title',
        dataIndex: 'title',
      },
      { title: 'Description', dataIndex: 'description' },
      {
        title: 'Send To',
        dataIndex: 'sentDate',
        render: (text: string, record: { email: string; userType: string; userId: string }) => {
          const { email, userType, userId } = record
          return email ? (
            <Link to={`/users/${userId}`}>
              <Text>{email}</Text>
            </Link>
          ) : (
            <Text>
              {replaceUnderScoreAndCapitalize(convertUserType(userType))} {}
            </Text>
          )
        },
      },
      {
        title: 'Send Time',
        dataIndex: 'sentDate',
        render: (text: string, record: { sentDate: string }) => {
          const { sentDate } = record
          return <Text>{dayjs(sentDate).format('D MMMM  YYYY HH:mm ')}</Text>
        },
      },
      {
        title: 'Send',
        dataIndex: 'isSend',
        render: (text: string, record: { isSend: string }) => {
          const { isSend } = record
          return <Text>{` ${isSend}`}</Text>
        },
      },
      {
        title: '',
        dataIndex: 'action',
        render: (text: string, record: {}) => (
          <Button onClick={() => onViewClick(record)}>View</Button>
        ),
      },
    ]
  }, [convertUserType, onViewClick])

  const setQueryParam = useCallback(
    params => {
      push(paths.notification({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )
  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  return (
    <ListPage
      topbar={
        <Layout>
          <ButtonStyled variant="warning" onClick={onCreateClick}>
            Create
          </ButtonStyled>
        </Layout>
      }
      tableProps={{
        columns,
        data: items,
        onPageChange,
        pagination: {
          currentPage: meta?.currentPage || 0,
          itemCount: meta?.itemCount || 0,
          itemsPerPage: meta?.itemsPerPage || 0,
          totalItems: meta?.totalItems || 0,
          totalPages: meta?.totalPages || 0,
        },
      }}
      searchInputProps={{ onSearch, value: query.q }}
    />
  )
}

export default NotificationList
