import React from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'

const disableBlackground = 'rgba(239, 239, 239, 0.3)'
export default (props: TextFieldProps) => {
  const { variant, margin, style, disabled, ...restProps } = { ...props }

  return (
    <TextField
      variant="outlined"
      margin="dense"
      style={{
        backgroundColor: disabled ? disableBlackground : undefined,
        ...style,
      }}
      inputProps={{
        maxLength: 250,
      }}
      disabled={disabled}
      autoComplete="off"
      {...restProps}
    />
  )
}
