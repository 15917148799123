import { useTranslation } from 'react-i18next'
import { useToastForReactQuery } from '../../../../utils/custom-hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { EnumGiveawayUnit, ICreateGiveawayBody } from '../../../services/giveaway/giveaway-typed'
import { useCreateGiveaway } from '../../../services/giveaway/giveaway-query'
import { Field, Form } from 'react-final-form'
import styled from '@emotion/styled'
import Card from '../../../components/common/Card'
import { InputField, SelectField } from '../../../components/fields'
import Button from '../../../components/common/Button'
import { Modal } from '@material-ui/core'
import Text from '../../../components/common/Text'
import { normalizeNumberWith4Digit, useRouter } from '../../../../utils/helper'
import Select from '../../../components/common/Select'
import { useGetSearchUser } from '../../../services/user/user-query'
import * as paths from '../../../constants/path'
import { OnChangeValue } from 'react-select'

const ContentLayout = styled(Card)`
  width: 500px;
  min-height: 200px;
  padding: 30px 80px 20px 80px;
  grid-row-gap: 12px;
`

const InputFieldStyled = styled(InputField)`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 0;
  }
`

const ModalStyled = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

type GiveawayFormValues = {
  tmnUser: { label: string; value: string }
  prefix: string
  amount: number
  unit: { label: string; value: EnumGiveawayUnit }
  campaign: string
}

type ModalCreateGiveawayProps = {
  visible: boolean
  onClose: () => void
}

const ModalCreateGiveaway = (props: ModalCreateGiveawayProps) => {
  const { visible, onClose } = props
  const { push, query } = useRouter()

  const toast = useToastForReactQuery()
  const { t } = useTranslation()

  const { mutate: createGiveaway } = useCreateGiveaway()
  const [search, setSearch] = useState<any>(null)
  const { data: users } = useGetSearchUser({ limit: 5, q: search })

  const setQueryParam = useCallback(
    params => {
      push(paths.rewards({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )

  const onSubmit = useCallback(
    (values: GiveawayFormValues) => {
      const createBody: ICreateGiveawayBody = {
        amount: values.amount,
        campaign: values.campaign,
        prefix: values.prefix,
        tmnId: values.tmnUser?.value,
        unit: values.unit.value,
      }
      createGiveaway(createBody, {
        onSuccess: () => {
          toast.open('Success', {
            type: 'success',
            autoClose: 3 * 1000,
          })
          onClose()
        },
        onError: () => {
          toast.open('Can not create giveaway', {
            type: 'error',
            autoClose: 3 * 1000,
          })
        },
      })
    },
    [createGiveaway, onClose, toast],
  )
  const validateRequired = useCallback((value: string) => (value ? undefined : 'required'), [])

  const units = useMemo(() => {
    return Object.keys(EnumGiveawayUnit).map(unit => {
      const value = EnumGiveawayUnit[unit as EnumGiveawayUnit]
      return {
        label: unit.toUpperCase(),
        value: value,
      }
    })
  }, [])

  const userOptions = useMemo(() => {
    return users?.items.map(
      (e): BaseOptionType => {
        return {
          label: `${e.firstnameTh} ${e.lastnameTh}`,
          value: e.tmnProfile?.tmnId,
        }
      },
    )
  }, [users])
  console.log({ userOptions })
  const onSearchChange = useCallback((input: string) => {
    setSearch(input)
  }, [])

  return (
    <ModalStyled open={visible} onClose={onClose}>
      <div>
        <Form<GiveawayFormValues>
          onSubmit={onSubmit}
          //initialValues={initialValues}

          subscription={{ dirty: true, submitting: true, valid: true }}
        >
          {({ handleSubmit, dirty, submitting, valid, form }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ContentLayout>
                  <div>
                    <Text>CAMPAIGN NAME</Text>
                    <Field
                      name="campaign"
                      component={InputFieldStyled}
                      validate={validateRequired}
                    />
                  </div>
                  <div>
                    <Text>PREFIX</Text>
                    <Field name="prefix" component={InputFieldStyled} validate={validateRequired} />
                  </div>
                  <div>
                    <Text>USER</Text>
                    <SelectField
                      name="tmnUser"
                      placeholder=""
                      options={userOptions}
                      onInputChange={onSearchChange}
                    />
                    {/* <Field name="tmnId" validate={validateRequired}>
                      {({ input, meta }) => {
                        console.log({ input: input.value })
                        return <SelectField options={userFilterOptions}  onChange={onFilterChange} />
                      }}
                    </Field> */}
                  </div>
                  <div>
                    <Text>AMOUNT</Text>
                    <Field
                      name="amount"
                      component={InputFieldStyled}
                      validate={validateRequired}
                      format={normalizeNumberWith4Digit}
                    />
                  </div>
                  <div>
                    <SelectField
                      name="unit"
                      label="UNIT"
                      placeholder="Select Unit"
                      options={units}
                      validate={validateRequired}
                      //disabled={!canChangeRole}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: '20px',
                      gridColumnGap: '10px',
                    }}
                  >
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!dirty || submitting || !valid}
                    >
                      Confirm
                    </Button>
                    <Button variant="outline-secondary" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                </ContentLayout>
              </form>
            )
          }}
        </Form>
      </div>
    </ModalStyled>
  )
}

export default ModalCreateGiveaway
