import { QueryOptions, useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { clearToken, getToken } from '../../../utils/helper'
import {
  UpdateUserProfileParams,
  Image,
  UpdateRoleUser,
  User,
  UserList,
  ChangeTmnIdParams,
  SearchUserParams,
} from '../../services/user/user-typed'

export const USERS_URL = `internal/users`
export const CURRENT_USER_URL = `${USERS_URL}/current-user`
export const IMAGE_URL = `images`
export const UPDATE_PROFILE = `update-profile`
export const CHANGE_TMN_ID = 'change-tmn-id'

export const useGetUsers = (params?: { q?: string; page?: number }) => {
  return useQuery(
    [USERS_URL, params],
    async () => {
      const datas = await api.savvyGold.get<UserList>(USERS_URL, {
        page: params?.page,
        limit: 10,
        q: params?.q,
      })

      return datas.data.data
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useGetUser = (userId: string) => {
  return useQuery(
    [USERS_URL, userId],
    async () => {
      const data = await api.savvyGold.get<User>(`${USERS_URL}/${userId}`)
      return data.data.data
    },
    {
      enabled: !!userId,
    },
  )
}

export const useGetSearchUser = (params: SearchUserParams) => {
  return useQuery(
    [USERS_URL, params],
    async () => {
      const data = await api.savvyGold.get<UserList>(`${USERS_URL}/search-by-port`, {
        page: params?.page,
        limit: params.limit,
        q: params?.q,
      })
      return data.data.data
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useGetImageUser = (id: string, size: string) => {
  return useQuery(
    [IMAGE_URL, id],
    async () => {
      const datas = await api.savvyGold.get<Image>(`${IMAGE_URL}/${id}`, {
        size: size,
      })

      return datas.data.data
    },
    {
      enabled: !!id,
    },
  )
}

export const useCurrentUser = (options?: QueryOptions<User | undefined>) => {
  return useQuery<User | undefined>(
    [USERS_URL, CURRENT_USER_URL],
    async () => {
      if (getToken()) {
        const response = await api.savvyGold.get<User>(CURRENT_USER_URL)
        return response.data.data
      }
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: () => {
        clearToken()
      },
      ...options,
    },
  )
}
export const useUpdateRoleUser = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (params: UpdateRoleUser) => {
      const { role, userId } = params
      const { data } = await api.savvyGold.put(`${USERS_URL}/${userId}/update-role`, { role })
      return data
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([USERS_URL])
      },
    },
  )
}

export const useUpdateUserProfile = (userId: string) => {
  const queryClient = useQueryClient()
  return useMutation<any, string, UpdateUserProfileParams>(
    async params => {
      const { mobileNo, email } = params
      const { data } = await api.savvyGold.patch(`${USERS_URL}/${userId}/${UPDATE_PROFILE}`, {
        mobileNo,
        email,
      })
      return data
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([USERS_URL])
      },
    },
  )
}

export const useChangeTmnId = () => {
  const queryClient = useQueryClient()
  return useMutation<any, string, ChangeTmnIdParams>(
    async params => {
      const { data } = await api.savvyGold.put(`${USERS_URL}/${CHANGE_TMN_ID}`, {
        ...params,
      })
      return data
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([USERS_URL])
      },
    },
  )
}
