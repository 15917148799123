/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useMemo } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { Layout } from '../_metronic/layout'
import BasePage from './BasePage'
import { AuthPage } from './pages/auth'
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage'
import { UserprofilePage } from './pages/UserprofilePage1'
import { useCurrentUser } from './services/user/user-query'

export function Routes() {
  const { data, isLoading } = useCurrentUser()
  const isAuthorized = useMemo(() => {
    return !!data
  }, [data])
  return isLoading ? null : (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/

        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/usersnew/:userId" component={UserprofilePage} />
      {/* <Route path="/ekycs" component={KycList} /> */}

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  )
}
