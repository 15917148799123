import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { BankAccountList, BankAccount, Image, VerifyBankAccount } from './bankaccount-typed'

export const BANK_ACCOUNT_URL = `internal/bank-accounts`
export const IMAGE_URL = `images`

export const useGetBankAccounts = (params?: { q?: string; page?: number }) => {
  return useQuery(
    [BANK_ACCOUNT_URL, params],
    async () => {
      const { data } = await api.savvyGold.get<BankAccountList>(BANK_ACCOUNT_URL, {
        page: params?.page,
        limit: 10,
        q: params?.q,
      })

      return data.data
    },

    {
      keepPreviousData: true,
    },
  )
}

export const useGetBankAccount = (id: string) => {
  return useQuery(
    [BANK_ACCOUNT_URL, id],
    async () => {
      const { data } = await api.savvyGold.get<BankAccount>(`${BANK_ACCOUNT_URL}/${id}`)

      return data.data
    },
    {
      enabled: !!id,
    },
  )
}

export const useGetImageBankAccount = (imageId: string) => {
  return useQuery(
    [IMAGE_URL, imageId],
    async () => {
      const { data } = await api.savvyGold.get<Image>(`${IMAGE_URL}/${imageId}`, {
        size: 'origin',
      })

      return data.data
    },
    {
      enabled: !!imageId,
    },
  )
}

export const usePatchBankAccountVerify = (userId: string) => {
  const queryClient = useQueryClient()
  const mutate = useMutation(
    (params: VerifyBankAccount) => {
      const { bankAccountId, status, remark } = params
      if (!bankAccountId || !userId) return Promise.reject()

      return api.savvyGold.patch<BankAccount>(`${BANK_ACCOUNT_URL}/${bankAccountId}/verify`, {
        approvedStatus: status,
        userId,
        remark,
      })
    },
    {
      onSuccess: (data, params) => {
        queryClient.invalidateQueries([BANK_ACCOUNT_URL, params.bankAccountId])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}
export const useCancelBankAccount = () => {
  const queryClient = useQueryClient()
  const mutate = useMutation(
    (bankAccountId: string) => {
      if (!bankAccountId) return Promise.reject()
      return api.savvyGold.post<BankAccount>(`${BANK_ACCOUNT_URL}/${bankAccountId}/cancel`)
    },
    {
      onSuccess: (data, bankAccountId) => {
        queryClient.invalidateQueries([BANK_ACCOUNT_URL, bankAccountId])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}
