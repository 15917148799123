import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import {
  CampaignListResponseType,
  CampaignResponseType,
  CreateCampaignBody,
} from './campaign-typed'

const BASE_CAMPAIGN = `internal/campaigns`

export const useCreateCampaign = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: CreateCampaignBody) => {
      const { data } = await api.savvyGold.post(`${BASE_CAMPAIGN}`, params)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_CAMPAIGN])
      },
    },
  )
}

export const useGetCampaignWithPage = (params?: { q?: string; page?: number }) => {
  return useQuery(
    [BASE_CAMPAIGN, params],
    async () => {
      const response = await api.savvyGold.get<CampaignListResponseType>(BASE_CAMPAIGN, {
        limit: 10,
        page: params?.page,
        q: params?.q,
        isActive: false,
      })

      return response.data.data
    },

    {
      keepPreviousData: true,
    },
  )
}

export const useGetCampaignById = (id: string) => {
  return useQuery(
    [BASE_CAMPAIGN, id],
    async () => {
      const response = await api.savvyGold.get<CampaignResponseType>(`${BASE_CAMPAIGN}/${id}`)
      return response.data.data
    },
    {
      enabled: !!id,
    },
  )
}

export const useUpdateCampaign = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: CreateCampaignBody) => {
      const { data } = await api.savvyGold.patch(`${BASE_CAMPAIGN}/${id}`, params)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_CAMPAIGN])
      },
    },
  )
}

export const useDeleteCampaign = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      const { data } = await api.savvyGold.delete(`${BASE_CAMPAIGN}/${id}`)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_CAMPAIGN])
      },
    },
  )
}
