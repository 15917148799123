import React, { useMemo } from 'react'
import { getColorStatusKycText, replaceUnderScoreAndCapitalize } from '../../../../utils/helper'
import PortalCard from '../../../components/templates/PortalCard'
import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import Text from '../../../components/common/Text'
import { useGetKyc } from '../../../services/kyc/kyc-query'
import { CallbackConvenientTime } from '../../../services/kyc/kyc-typed'
import dayjs from 'dayjs'

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`
const TextColor = styled(Text)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`

interface KycCardProps {
  kycId: string
}

export const KycCard = (props: KycCardProps) => {
  const { kycId } = props
  const { data: kyc } = useGetKyc(kycId)
  const {
    firstnameEn = '',
    lastnameEn = '',
    citizenId = '',
    callbackConvenientTime = [],
    callbackMobileNo = '',
    ekycStatus = '',
  } = kyc || {}

  const fullname = `${firstnameEn} ${lastnameEn}`
  const convenientTime = useMemo(() => {
    if (callbackConvenientTime?.length) {
      return callbackConvenientTime?.map((item: CallbackConvenientTime) => {
        const { contactEnd, contactStart } = item
        return (
          <>
            <Text bold style={{ color: '#000000' }}>
              {dayjs(contactStart).format('hh:mm')}-{dayjs(contactEnd).format('hh:mm')}
            </Text>
          </>
        )
      })
    } else {
      return <Text bold>-</Text>
    }
  }, [callbackConvenientTime])

  const dataKyc = useMemo(
    () => [
      { label: 'Name', value: firstnameEn ? fullname : '-' },
      { label: 'Citizen Id', value: citizenId ? citizenId : '-' },
      { label: 'Convenient time', valueComponent: <div>{convenientTime}</div> },
      { label: 'Callback number', value: callbackMobileNo ? callbackMobileNo : '-' },
    ],
    [callbackMobileNo, citizenId, convenientTime, firstnameEn, fullname],
  )

  return (
    <PortalCard
      title={
        <Flex>
          <Text bold variant="h5">
            Kyc
          </Text>
          <TextColor variant="h5" color={getColorStatusKycText(ekycStatus)}>
            ({replaceUnderScoreAndCapitalize(ekycStatus)})
          </TextColor>
        </Flex>
      }
      contents={dataKyc}
      viewMoreUrl={`/ekycs/${kycId}`}
    />
  )
}

export default KycCard
