import React from 'react'
import styled from '@emotion/styled'
import Button from './common/Button'

export const CustomStyledButton = styled(Button)`
  color: white;
`
interface buttonProps {
  onClick?: any
}
const ButtonViewTable = (props: buttonProps) => {
  const { onClick } = props

  return (
    <CustomStyledButton color="default" onClick={onClick}>
      View
    </CustomStyledButton>
  )
}

export default ButtonViewTable
