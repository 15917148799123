import React, { useCallback, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { TextField } from '@material-ui/core'
import styled from '@emotion/styled'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

export type SearchInputProps = {
  placeholder?: string
  onSearch: (text: string) => void
  value: string
  style?: CSSProperties
}

const RootLayout = styled.div`
  display: inline-flex;
  position: relative;
`
const Input = styled(TextField)`
  input {
    min-width: 250px;
  }
`
const IconLayout = styled(IconButton)`
  width: 35px;
  height: 35px;
  position: absolute !important;
  padding: 0 !important;
  right: 0px;
  top: 8px;
`

export default (props: SearchInputProps) => {
  const { placeholder = 'Search', onSearch, style, value } = props
  const [text, setText] = useState(value || '')
  const onKeyUp = useCallback(
    e => {
      if (e.key === 'Enter') {
        onSearch(text)
      }
    },
    [onSearch, text],
  )
  const onIconClick = useCallback(
    e => {
      onSearch(text)
    },
    [onSearch, text],
  )
  const onChange = useCallback(e => {
    const text = e.target.value
    setText(text)
  }, [])

  return (
    <RootLayout style={style}>
      <Input
        margin="dense"
        value={text}
        label={placeholder}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onChange={onChange}
        variant="outlined"
      />
      <IconLayout aria-label="Search" onClick={onIconClick}>
        <SearchIcon />
      </IconLayout>
    </RootLayout>
  )
}
