import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { FormControlLabel, Modal, makeStyles } from '@material-ui/core'
import Button from '../../../components/common/Button'
import { Form, Field } from 'react-final-form'
import ConfirmCard from '../../../components/common/Card'
import { SwitchField } from '../../../components/fields'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import Text from '../../../components/common/Text'
import { useVisible } from '../../../../utils/custom-hooks'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import { useGetSettingDetail, usePatchSetting } from '../../../services/setting/setting-query'
import { IPatchSettingDetailBodyParams } from '../../../services/setting/setting-type'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'

const CustomCard = styled(ConfirmCard)`
  width: 100%;
  max-width: 1000px;
  margin-top: 16px;
`

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const Setting = () => {
  const classes = useStyles()
  const onConfirmModalVisible = useVisible()

  const handleOpenConfirmModal = useCallback(() => {
    onConfirmModalVisible.open()
  }, [onConfirmModalVisible])

  const handleCloseConfirmModal = useCallback(() => {
    onConfirmModalVisible.close()
  }, [onConfirmModalVisible])

  const { data: settingData } = useGetSettingDetail()
  const { mutate: patchSetting } = usePatchSetting()

  const onSubmit = useCallback(
    (values: IPatchSettingDetailBodyParams) => {
      const isMaintenance = values.isMaintenance
      patchSetting(
        { isMaintenance: isMaintenance },
        {
          onSuccess: () => {},
        },
      )
      handleCloseConfirmModal()
    },
    [handleCloseConfirmModal, patchSetting],
  )

  const initialValues = useMemo(() => {
    return {
      isMaintenance: settingData?.isMaintenance === true,
    }
  }, [settingData])

  return (
    <div>
      <Text variant="h5">Setting</Text>
      <Authorize role={ROLE.ADMIN}>
        <CustomCard>
          <Form<IPatchSettingDetailBodyParams>
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => {
              const isMaintenanceDefault = settingData?.isMaintenance === true
              const isDisableSubmit = values.isMaintenance === isMaintenanceDefault
              return (
                <form>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                      control={<Field name="isMaintenance" component={SwitchField} />}
                      label="Server Maintenance "
                    />
                    <Button
                      style={{ marginTop: 24, width: 240 }}
                      onClick={handleOpenConfirmModal}
                      disabled={isDisableSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                  <Modal
                    className={classes.modal}
                    open={onConfirmModalVisible.visible}
                    onClose={onConfirmModalVisible.close}
                  >
                    <ConfirmCard
                      style={{
                        display: 'flex',
                        width: '300px',
                        padding: '30px',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Text>Confirm</Text>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignSelf: 'center',
                          paddingTop: '10px',
                        }}
                      >
                        <Button
                          style={{
                            alignSelf: 'center',
                            marginRight: '20px',
                          }}
                          onClick={handleSubmit}
                        >
                          Yes
                        </Button>
                        <Button
                          style={{
                            alignSelf: 'center',
                            backgroundColor: '#FF0000',
                            borderColor: '#FF0000',
                          }}
                          onClick={handleCloseConfirmModal}
                        >
                          No
                        </Button>
                      </div>
                    </ConfirmCard>
                  </Modal>
                </form>
              )
            }}
          />
        </CustomCard>
      </Authorize>
    </div>
  )
}

export default Setting
