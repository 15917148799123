import {
  AddressCitizen,
  AddressRecent,
  AddressWork,
  BankAccount,
} from '../bankaccount/bankaccount-typed'
import { Kyc } from '../kyc/kyc-typed'
export enum UserStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
}

export enum UserSignInType {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
  EMAIL = 'e-mail',
}
export type PublicProfile = {
  distinctId: string
  lastnameEn: string
  lastnameTh: string
  firstnameEn: string
  firstnameTh: string
  middleNameEn: string
  middleNameTh: string
  profileImage: string
}
export type TmnProfile = {
  tmnId: string
  publicProfile: PublicProfile
  dateOfBirth: string
  gender?: string
  mobile: string
  expiryDate: string
  occupation: string
  thaiIdMask: string
}

export type User = {
  addressCitizen: AddressCitizen
  addressRecent: AddressRecent
  addressWork: AddressWork
  birthdate: string
  callbackConvenientTime: string
  callbackMobileNo: string
  citizenId: string
  createdAt: string
  deletedAt: string
  bankAccount: BankAccount
  email: string
  firstnameEn: string
  firstnameTh: string
  portId: string
  gender: string
  id: string
  job: string
  laserCode: string
  lastSignInAt: string
  lastnameEn: string
  lastnameTh: string
  mobileNo: string
  role: ROLE
  tinNo: string
  titleEn: string
  titleTh: string
  updatedAt: string
  imageProfile: ImageProfile
  kyc: KycExtends
  status: UserStatus
  typeSignIn: UserSignInType
  tmnProfile: TmnProfile
  tmnId: string
}
export type KycExtends = {
  kyc: Kyc
}
export type ImageProfile = {
  id: string
  createAt: string
  updateAt: string
  deleteAt: string
  attachableId: string
  attachableType: string
  type: string
  path: string
}

export type PageMeta = {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
}

export type UserList = {
  items: User[]
  meta: PageMeta
}
export type Image = {
  image: string
}

export enum ROLE {
  USER = 'user',
  VIEWER = 'viewer',
  MANAGER = 'manager',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
}
export type UpdateRoleUser = {
  userId: string
  role: ROLE
}

export interface UpdateUserProfileParams {
  mobileNo?: string
  email?: string
}

export interface ChangeUserMobileNumberResponse {
  mobileNo: string
}

export interface ChangeTmnIdParams {
  userId: string
  thaiId: string
  oldTmnId: string
  newTmnId: string
  oldMobileNo: string
  newMobileNo: string
}

export interface SearchUserParams {
  limit?: number
  page?: number
  q?: string
  filter?: string
}
