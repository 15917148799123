import React from 'react'
import styled from '@emotion/styled'
import { Slider } from '@material-ui/core'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Area } from 'react-easy-crop/types'
import Button from './common/Button'
import Text from './common/Text'
import { getCroppedImg, getCroppedImgCircle } from '../../utils/image-helper'

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;

  /* .image-crop {
    width: fit-content;
  }
  img {
    max-height: 50vh;
    object-fit: scale-down;
  } */
`
const CropLayout = styled.div`
  /* width: 100vw; */
  position: relative;
  /* height: 40vh; */
  min-height: 100px;
  width: 100%;
  aspect-ratio: 198 / 167;
`

const ButtonStyled = styled(Button)`
  width: fit-content;
`

const ControlLayout = styled.div``

const SliderStyled = styled(Slider)`
  min-width: 200px;
`
const ButtonLayout = styled.div`
  display: flex;
  flex-flow: row;
  grid-column-gap: 14px;
`

const MIN_ZOOM = 1 as const
const MAX_ZOOM = 3 as const
const ZOOM_STEP = 0.1 as const

export enum CropAspect {
  '1:1' = 1 / 1,
  '16:9' = 16 / 9,
  '16:10' = 16 / 10,
  '167:198' = 167 / 198,
}

export type ImageEasyCropProps = {
  image: string
  circle?: boolean
  aspect?: CropAspect
  hideScaleBar?: true
  onConfirm: (image: string) => void
  onChange?: (image: string) => void
  onClose?: () => void
}
const ImageEasyCrop = (props: ImageEasyCropProps) => {
  const {
    image,
    aspect = CropAspect['1:1'],
    circle = false,
    onConfirm,
    hideScaleBar = false,
    // onChange,
    onClose,
  } = props

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<Cropper>(null)

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation] = useState(0)
  // const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
  const [croppedImage, setCroppedImage] = useState('')
  // const [croppedImageCircle, setCroppedImageCircle] = useState('')

  const onCropComplete = useMemo(
    () =>
      debounce(async (croppedArea: Area, croppedAreaPixels: Area) => {
        // console.log(croppedArea, croppedAreaPixels)
        // setCroppedAreaPixels(croppedAreaPixels)
        if (croppedAreaPixels && imageRef.current) {
          const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation)
          setCroppedImage(croppedImage)
        }
      }, 150),
    [image, rotation],
  )

  const onConfirmCrop = useCallback(async () => {
    if (croppedImage) {
      if (circle) {
        const croppedImageCircle = await getCroppedImgCircle(croppedImage)
        onConfirm?.(croppedImageCircle)
      } else {
        onConfirm?.(croppedImage)
      }
    }
  }, [circle, croppedImage, onConfirm])

  const drawCanvas = useCallback(() => {
    var img = new Image()
    if (canvasRef.current) {
      img.onload = function() {
        if (canvasRef.current) {
          const canvas = canvasRef.current
          const ctx = canvas.getContext('2d')
          const imageHeight = canvas.height
          const halfImageHeight = imageHeight / 2

          if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height)

            if (circle) {
              ctx.beginPath()
              ctx.arc(halfImageHeight, halfImageHeight, halfImageHeight, 0, 6.28, false) //draw the circle
              ctx.clip() //call the clip method so the next render is clipped in last path
              ctx.closePath()
            }

            ctx.drawImage(
              img,
              0,
              0,
              img.naturalWidth,
              img.naturalHeight, // source rectangle
              0,
              0,
              canvas.width,
              canvas.height,
            )
          }
        }
      }
      img.src = croppedImage
    }
  }, [circle, croppedImage])

  useEffect(() => {
    drawCanvas()
  }, [drawCanvas, croppedImage])

  const onCancelClick = useCallback(() => {
    onClose?.()
  }, [onClose])

  return (
    <Layout>
      <CropLayout>
        <Cropper
          ref={imageRef}
          image={image}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          cropShape={circle ? 'round' : 'rect'}
          // restrictPosition={false}
          zoomSpeed={ZOOM_STEP}
          onZoomChange={setZoom}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
        />
      </CropLayout>
      {!hideScaleBar && (
        <ControlLayout>
          <Text>{`Zoom (x${zoom.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })})`}</Text>
          <SliderStyled
            value={zoom}
            min={MIN_ZOOM}
            max={MAX_ZOOM}
            step={ZOOM_STEP}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => {
              if (typeof zoom === 'number') setZoom(zoom)
            }}
          />
        </ControlLayout>
      )}
      {/* <canvas
        ref={(ref) => ref && (canvasRef.current = ref)}
        width="200px"
        height="200px"
      /> */}
      <ButtonLayout>
        <ButtonStyled onClick={onCancelClick} buttonStyle="border">
          ยกเลิก
        </ButtonStyled>
        <ButtonStyled onClick={onConfirmCrop} buttonStyle="fill">
          ยืนยัน
        </ButtonStyled>
      </ButtonLayout>
    </Layout>
  )
}

export default ImageEasyCrop
