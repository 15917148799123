import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'

import { useUpdatePassword, useVerifyEmail } from '../../services/auth/auth-query'
import { useTranslation } from 'react-i18next'
import { useRouter } from '../../../utils/helper'

import * as paths from '../../constants/path'
import PasswordForm, {
  validation as passwordValidation,
  PasswordFormValue,
} from './shared/PasswordForm'
import OtpForm, { validation as otpValidation, OtpFormValue } from './shared/OtpForm'

import { useOtpEmail, useVerifyOtp } from '../../services/otp/otp-query'
import { Otp } from '../../services/otp/otp-types'
import Toast from '../../components/common/Toast'
import ForgotPasswordForm, {
  validation as forgotPassword,
  ForgotPasswordFormValue,
} from './shared/ForgotPasswordForm'

type SubmitValue = PasswordFormValue & OtpFormValue & ForgotPasswordFormValue
const validations = [forgotPassword, otpValidation, passwordValidation]

export default () => {
  const { t } = useTranslation()
  const { push } = useRouter()
  const { mutateAsync: verifyEmail } = useVerifyEmail()
  const { mutateAsync: requestOtp } = useOtpEmail()
  const { mutateAsync: verifyOtp } = useVerifyOtp()
  const { mutateAsync: updatePassword } = useUpdatePassword()
  const [otpData, setOtpData] = useState<Otp>()
  const [errorMessage, setErrorMessage] = useState('')

  const [page, setPage] = useState(1)

  const nextPage = useCallback(() => {
    setPage(Math.min(page + 1, 3))
  }, [page])
  const previousPage = useCallback(() => {
    setPage(Math.max(page - 1, 1))
  }, [page])

  const onSubmit = useCallback(
    async (value: SubmitValue) => {
      if (page === 1) {
        try {
          const verifyEmailData = await verifyEmail({ email: value.email })
          if (verifyEmailData.isEmailExist) {
            const otpData = await requestOtp({ email: value.email, action: 'forgot-password' })
            setOtpData(otpData)
            setErrorMessage('')
            nextPage()
          } else {
            throw new Error('email is not exist')
          }
        } catch (e) {
          setErrorMessage(t(`errors.${e?.errorCode}`, 'Something Went Wrong'))
        }
      }
      if (page === 2) {
        try {
          //   await verifyOtp({ otpId: otpData!.otpId, otp: value.otp })
          setErrorMessage('')
          nextPage()
        } catch (e) {
          setErrorMessage(t(`errors.${e?.errorCode}`, 'Something Went Wrong'))
        }
      }
      if (page === 3) {
        try {
          await updatePassword({
            email: value.email,
            password: value.password,
            otpId: otpData!.otpId,
            otp: value.otp,
          })
          Toast.info('Change Password Success')
          push(paths.login())
        } catch (e) {
          setErrorMessage(t(`errors.${e?.errorCode}`, 'Something Went Wrong'))
        }
      }
    },
    [page, verifyEmail, requestOtp, nextPage, t, otpData, updatePassword, push],
  )
  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <Form
        onSubmit={onSubmit}
        subscription={{ submitting: true }}
        validate={validations[page - 1]}
        render={formProps => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              {page === 1 && (
                <ForgotPasswordForm
                  {...formProps}
                  errorMessage={errorMessage}
                  title="Forgot Password"
                />
              )}
              {page === 2 && (
                <OtpForm
                  {...formProps}
                  onCancel={previousPage}
                  errorMessage={errorMessage}
                  otpData={otpData}
                />
              )}
              {page === 3 && (
                <PasswordForm {...formProps} errorMessage={errorMessage} title="Forgot Password" />
              )}
            </form>
          )
        }}
      />
    </div>
  )
}
