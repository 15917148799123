import React from 'react'
import { Card } from 'react-bootstrap'
import styled from '@emotion/styled'

import SearchInput, { SearchInputProps } from '../common/SearchInput'
import Table, { TableProps } from '../common/Table'

type ListPageProps = {
  searchInputProps?: SearchInputProps
  tableProps: TableProps
  topbar?: JSX.Element
}

const TopbarLayout = styled.div`
  display: flex;
  align-items: baseline;
  grid-column-gap: 12px;
`

export default (props: ListPageProps) => {
  const { searchInputProps, tableProps, topbar } = props
  return (
    <>
      <Card>
        <Card.Body>
          <TopbarLayout>
            {searchInputProps && <SearchInput {...searchInputProps} />}
            {topbar}
          </TopbarLayout>
          <Table style={{ marginTop: 10 }} {...tableProps} />
        </Card.Body>
      </Card>
    </>
  )
}
