import { LanguageKeys, makeText } from './language'

export const getTranslationErrors = (key: LanguageKeys) => {
  const text = makeText(key)
  return {
    '400-001': text('sample message', 'ข้อความตัวอย่าง'),
    '400-002': text(
      "You don't have permission to use this function.",
      'คุณไม่มีสิทธิ์ใช้งานฟังก์ชันนี้',
    ),
    '400-003': text('Missing value', 'ข้อมูลไม่ครบ'),
    '422-001': text('User not found with this e-mail', 'ไม่พบผู้ใช้งานด้วย e-mail นี้'),
    '422-002': text('Wrong e-mail or password', 'e-mail หรือ รหัสผู้ใช้งาน ผิดพลาด'),
    '422-003': text(
      "Can't create a bank account, The bank account is in a pending state",
      'คุณไม่สามารถผูกบัญชีธนาคารได้ เนื่องจากบัญชีธนาคารของคุณอยู่ในสถานะตรวจสอบ',
    ),
    '422-004': text(
      "Can't create a bank account, The bank account has a already exists",
      'คุณไม่สามารถผูกบัญชีธนาคารได้ เนื่องจากคุณมีบัญชีธนาคารแล้ว',
    ),
    '422-005': text(
      "Can't create a bank account, This number bank account has a already exists.",
      'คุณไม่สามารถผูกบัญชีธนาคารได้ เนื่องจากเลขบัญชีธนาคารซ้ำกับเลขบัญชีธนาคารของคนอื่น',
    ),
    '422-006': text('Find not found bank accounts', 'ไม่พบบัญชีธนาคาร'),
    '422-007': text(
      "This bank account has status a rejected, Can't don't anythings",
      'บัญชีได้ถูกยกเลิก ไม่สามารถทำรายการได้',
    ),
    '422-008': text('Find not found user', 'ไม่พบผู้ใช้งาน'),
    '422-009': text('Find not found port', 'ไม่พบการเปิดพอร์ตทอง'),
    '422-010': text('Date must be 1 - 31', 'ข้อมูลวันที่ควรเป็นวันที่ 1-31'),
    '422-011': text(
      'Amount must be 100 - 300,000 THB',
      'สามารถใส่จำนวนขั้นต่ำ 100 บาท และไม่เกิน 300,000 บาท',
    ),
    '422-012': text('Find not found Dca', 'ไม่พบการซื้อทองอัตโนมัติ'),
    '422-013': text('Server SafeGold connection problem', 'การเชื่อมต่อ Server SafeGold เกิดปัญหา'),
    '422-014': text('CitizenId was already used', 'รหัสบัตรประชาชนนี้ถูกใช้งานไปแล้ว'),
    '422-015': text('User was already opened port', 'คุณเคยเปิดพอร์ตทองแล้ว'),
    '422-016': text('Find not found eKyc', 'ไม่พบการทำ eKyc'),
    '422-017': text(
      "The phone number doesn't match the phone number used by eKyc",
      'เบอร์โทรศัพท์ติดต่อกลับไม่ตรงกับเบอร์โทรศัพท์ที่ทำ eKyc',
    ),
    '422-018': text('Find not found notification', 'ไม่พบการแจ้งเตือน'),
    '422-019': text(
      'The notification that has been sent could not be updated',
      'ไม่สามารถอัพเดตการแจ้งเตือนได้ เนื่องจากการแจ้งเตือนนี้ได้ถูกส่งแล้ว',
    ),
    '422-020': text('Find not found notification schedules', 'ไม่พบตารางการแจ้งเตือน'),
    '422-021': text('Server ThaiBulkSms connection problem', 'การเชื่อมต่อ ThaiBulkSms เกิดปัญหา'),
    '422-022': text('Incorrect pin', 'รหัส pin ไม่ถูกต้อง'),
    '422-023': text('Already verify pin.', 'รหัส pin นี้ถูกยืนยันไปแล้ว'),
    '422-024': text('Timeout pin', 'รหัส pin นี้หมดเวลาการใช้งาน'),
    '422-025': text('Find not found transaction', 'ไม่พบประวัติการทำรายการ'),
    '422-026': text('Payment has already exists', 'มีประวัติการจ่ายเงินแล้ว'),
    '422-027': text(
      'Prompt Pay must have phone number',
      'การจ่ายเงินแบบ Prompt pay ต้องมีเบอร์โทรศัพท์',
    ),
    '422-028': text('This payment has already success or fail', 'การจ่ายเงินนี้ถูกทำรายการแล้ว'),
    '422-029': text('Server eKyc connection problem', 'การเชื่อมต่อ Server eKyc เกิดปัญหา'),
    '422-030': text(
      'Transaction type is not delivery',
      'ประเภทประวัติการทำรายการไม่ใช่แบบการจัดส่ง',
    ),
    '422-031': text('Transaction status is not pending', 'ประวัติการทำรายการไม่ได้อยู่ในสถานะรอ'),
    '422-032': text('User not type Facebook', 'คุณสมัครสมาชิกผ่าน Facebook'),
    '422-033': text('This e-mail is already exists', 'e-mail นี้ถูกใช้งานแล้ว'),
    '422-034': text('This phone number has already exist', 'เบอร์โทรศัพท์นี้ถูกใช้งานแล้ว'),
    '422-035': text("Don't change phone number", 'ไม่สามารถเปลี่ยนเบอร์โทรศัพท์ได้'),
    '422-036': text('Server Google connection problem', 'การเชื่อมต่อ Server Google เกิดปัญหา'),
    '422-037': text('Server Facebook connection problem', 'การเชื่อมต่อ Server Facebook เกิดปัญหา'),
    '422-038': text('Server Apple connection problem', 'การเชื่อมต่อ Server Apple เกิดปัญหา'),
    '422-039': text(
      " Params approveStatus support only value 'approved' and 'rejected'",
      "การยืนยันสถานะสามารถระบุได้แค่ 'approved' และ 'rejected'",
    ),
    '422-040': text(
      "Can't update address to Kyc because Kyc has already verify",
      'ไม่สามารถเพิ่มที่อยู่ได้เนื่องจาก Kyc ได้ถูกยืนยันแล้ว',
    ),
    '422-041': text(
      "Can't upload-image to KYC because KYC has already verify",
      'ไม่สามารถอัปโหลดรูปภาพได้เนื่องจาก Kyc ได้ถูกยืนยันแล้ว',
    ),
    '422-042': text('Transaction is exists', 'การทำรายการนี้ มีอยู่ในระบบแล้ว'),
    '422-043': text('Invalid Product Code', 'รหัสสินค้าไม่ถูกต้อง'),
    '422-044': text('Product out of Stock', 'จำนวนสินค้าหมดชั่วคราว'),
    '422-045': text('Insufficient Balance', 'จำนวนทองของคุณไม่เพียงพอ'),
    '422-046': text('User is Blacklisted', 'การทำรายการถูกระงับ'),
    '422-047': text('Missing required information', 'ข้อมูลการทำรายการไม่ครบ'),
    '422-048': text('User with that ID is missing', 'ไม่พบข้อมูลของคุณ'),
    '422-049': text('Service is not functioning', 'ระบบไม่พร้อมให้บริการในขณะนี้'),
    '422-050': text('SG rate does not match current rate', 'ข้อมูลราคาทองไม่ถูกต้อง'),
    '422-051': text('Gold Amount does not match', 'ข้อมูลจำนวนทองไม่ถูกต้อง'),
    '422-052': text('Invalid Rate', 'ข้อมูลราคาทองนี้ไม่สามารถใช้งานได้'),
    '422-053': text('Invalid Transaction ID', 'ไม่พบรายการนี้ระบบ'),
    '422-054': text('User ID/Vendor ID Mismatch', 'รหัสลูกค้าไม่ตรงกัน'),
    '422-055': text('User ID missing in transaction', 'รหัสลููกค้าไม่ตรงกับรายการ'),
    '422-056': text('Transaction Timeout', 'รายการนี้หมดเวลาแล้ว'),
    '422-057': text('Rate does not match current rate', 'ข้อมูลราคาทองไม่ถูกต้อง'),
    '422-058': text('User not registered', 'รหัสของคุณยังไม่ถูกลงทะเบียน'),
    '422-059': text(
      "You can't create a Dca plan because you already have a Dca planned.",
      'คุณไม่สามารถสร้างการวางแผน Dca ได้ เนื่องจากคุณได้มีการวางแผน Dca อยู่แล้ว',
    ),
    '422-060': text(
      'The start date must be at least today or after today',
      'วันที่เริ่มต้นต้องเป็นวันปัจจุบัน หรือมากกว่า',
    ),
    '422-061': text('SIP ID does not match the User ID', 'รหัส SIP ไม่ตรงกับของคุณ'),
    '422-062': text('SIP Completed', 'SIP สิ้นสุดแล้ว'),
    '422-063': text('Invalid SIP ID', 'รหัส SIP ไม่ถูกต้อง'),
    '422-064': text('Invalid Service fee', 'ค่าธรรมเนียมไม่ถูกต้อง'),
    '422-065': text('Zip code is invalidate', 'รหัสไปรษณีย์ที่ท่านเลือกไม่สามารถใช้งานได้'),
    '422-066': text(
      'Sell Transaction cannot be initiated within 1 days from the date of purchase',
      'คุณสามารถขายทองได้หลังจาก รายการซื้อล่าสุด 1 วัน',
    ),
    '422-067': text(
      'You have already entered the address, please close / reopen the app for data refresh.',
      'ได้รับข้อมูลที่อยู่แล้ว กรุณา ปิด/เปิด  แอพใหม่เพื่อการรีเฟรชข้อมูล',
    ),
    '422-068': text(
      'Kyc status has been determined. Unable to save or change address information.',
      'สถานะ Kyc สถานะได้ถูกพิจารณาแล้ว ไม่สามารถบันทึกหรือเปลี่ยนแปลงข้อมูลที่อยู่ได้',
    ),
    '422-069': text(
      'Kyc status has been determined. Unable to save or change citizen images.',
      'สถานะ Kyc สถานะได้ถูกพิจารณาแล้ว ไม่สามารถบันทึกหรือเปลี่ยนแปลงรูปภาพบัตรประชาชนได้',
    ),
    '422-070': text(
      'You have already entered citizen images, please close / reopen the app for data refresh.',
      'ได้รับรูปภาพบัตรประชาชนแล้ว กรุณา ปิด/เปิด แอพใหม่เพื่อการรีเฟรชข้อมูล',
    ),
    '422-071': text('Payment cannot be process', 'ไม่สามารถทำรายการชำระเงินได้'),
    '422-072': text(
      'Payment request cannot be sent. PromptPay account is inactive. Please contact the bank of PromptPay account.',
      'การขอจ่ายเงินไม่สำเร็จ คุณต้องทำการอนุญาตให้ระบบสามารถร้องขอการจ่ายเงินกับธนาคารที่คุณผูกไว้บนระบบ Prompt Pay',
    ),
    '422-073': text(
      "Payment can't be processed. Please try again later.",
      'ไม่สามารถเชื่อมต่อ KBank ได้ กรุณาลองใหม่อีกครั้ง',
    ),
    '422-074': text('User not found', 'ไม่พบผู้ใช้งาน'),
    '422-075': text('Unable to update user profile', 'ไม่สามารถแก้ไขข้อมูลของคุณได้'),
    '422-076': text('Invalid user id', 'รหัสผู้ใช้งานไม่ถูกต้อง'),
    '422-077': text(
      'Minimum age for registration should be 18',
      'ไม่สามารถสมัครผู้ใช้งานได้ ผู้ใช้งานต้องมีอายุ 18 ปีขึ้นไป',
    ),
    '422-078': text(
      'Sip cannot be cancelled until pending transaction is completed',
      'คุณมีรายการซื้อทองอัตโนมัติที่กำลังดำเนินการอยู่ ยังไม่สามารถยกเลิกการซื้อทองอัตโนมัติได้ในขณะนี้  ',
    ),
    '422-079': text(
      'Daily Transaction Limit Exceeded',
      'การทำรายการของคุณในวันนี้ ถึงจำนวนจำกัดแล้ว',
    ),
    '422-080': text(
      'Monthly Transaction Limit Exceeded',
      'การทำรายการของคุณในเดือนนี้ ถึงจำนวนจำกัดแล้ว',
    ),
    '422-081': text(
      'Purchases are currently unavailable. Please try again after a few hours. You cancontinue to sell your gold or request physical delivery',
      'ไม่สามารถซื้อทองได้ในขณะนี้ โปรดลองอีกครั้งหลังจากนี้สองสามชั่วโมง แต่คุณสามารถดำเนินการขายทองหรือถอนทอง ของคุณได้',
    ),
    '422-082': text('Transaction cannot be process', 'ไม่สามารถทำรายการชำระเงินได้'),
    '422-083': text(
      'ไม่สามารถทำรายการได้ กรุณาทำรายการในเวลาซื้อขาย 8.00 - 23.00 อีกครั้ง',
      'ไม่สามารถทำรายการได้ กรุณาทำรายการในเวลาซื้อขาย 8.00 - 23.00 อีกครั้ง',
    ),
    '422-084': text(
      'Deliveries are currently unavailable. Please try again tomorrow. You can continue to purchase or sell gold in the meantime.',
      'การถอนทองอยู่ในช่วงเวลาปรับปรุงระบบ กรุณาลองใหม่อีกครั้งภายในวันพรุ่งนี้ แต่คุณยังสามารถออมทอง หรือถอนทองเป็นเงินได้ในขณะนี้',
    ),
    '422-085': text(
      'Sales are currently unavailable. Please try again tomorrow. You can continue to purchase gold or request physical delivery.',
      'การขายทองอยู่ในช่วงเวลาปรับปรุงระบบ กรุณาลองใหม่อีกครั้งภายในวันพรุ่งนี้ แต่คุณยังสามารถออมทอง หรือถอนทองแท่งได้ในขณะนี้',
    ),
    '422-086': text(
      'System upgrade in progress. Please try again after some time',
      'ระบบอยู่ในช่วงเวลาปรับปรุง กรุณาลองใหม่อีกครั้งในภายหลัง',
    ),
    '422-087': text(
      'Transactions are currently unavailable. Please try again tomorrow.',
      'การทำรายการทั้งหมดอยู่ในช่วงเวลาปรับปรุง  กรุณาลองใหม่อีกครั้งในภายหลัง',
    ),
    '422-088': text(
      'Purchases are currently unavailable. Please try again tomorrow. You can continue to sell your gold or request physical delivery.',
      'การแแมทองอยู่ในช่วงเวลาปรับปรุงระบบ กรุณาลองใหม่อีกครั้งภายในวันพรุ่งนี้ แต่คุณยังสามารถขายทอง หรือถอนทองแท่งได้ในขณะนี้',
    ),
    '422-089': text('SIP Cancelled', 'ข้อมูล SIP นี้ถูกยกเลิกแล้ว'),
    '422-090': text('No Record found', 'ไม่พบรายการนี้'),
    '422-091': text('Invalid frequency', 'ประเภทความถี่ไม่ถูกต้อง'),
    '422-092': text('Invalid duration period', 'ช่วงเวลาไม่ถูกต้อง'),
    '422-093': text('User Id missing in transaction', 'รหัสผู้ใช้งานไม่ตรงกับรายการนี้'),
    '422-094': text('Invalid sip amount', 'จำนวนเงินไม่ตรงกับที่คุณวางแผนไว้'),
    '422-095': text('Insufficient Balance', 'ยอดคงเหลือไม่เพียงพอ'),
    '422-096': text('Unable to update user profile', 'ไม่สามารถอัพเดทข้อมูลผู้ใช้งานได้'),
    "422-097": text("User profile can edit after KYC is Approved", "สามารถแก้ไขข้อมูลผู้ใช้งานได้หลังจากผ่านการทำ KYC แล้วเท่านั้น"),
    '500-001': text('Internal Server Error', 'เกิดข้อผิดพลาด'),
  }
}
