import React, { useCallback, useState } from 'react'
import Text from '../../../components/common/Text'
import styled from '@emotion/styled'
import ButtonBankAccount from '../../../components/ButtonApproveBankAccount'
import Toast from '../../../components/common/Toast'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { useVisible } from '../../../../utils/custom-hooks'
import { Grid } from '@material-ui/core'
import Button from '../../../components/common/Button'
import { Card } from 'react-bootstrap'
import { useApproveKyc, useRejectKyc } from '../../../services/kyc/kyc-query'
import { appConfig } from '../../../constants/app-config'
import {
  EnumEventName,
  EnumMixPanelType,
  EnumPeoplePropertyName,
} from '../../../services/mix-panel/mix-panel-typed'
import dayjs from 'dayjs'
import { useMixPanelTrackEvent } from '../../../services/mix-panel/mix-panel-query'
import { useBrazeTrackEvent } from '../../../services/braze/braze.query'
import { useGetUser } from '../../../services/user/user-query'

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`

const FlexButton = styled(Flex)`
  margin: 10px;
`
const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

enum CurrentButtonAction {
  REJECT = 'reject',
  APPROVE = 'approve',
}

type KycOnPendingActionProps = {
  kycId: string
  userId: string
}

const KycOnPendingAction = (props: KycOnPendingActionProps) => {
  const { kycId, userId } = props
  const { data: user } = useGetUser(userId)
  const classes = useStyles()
  const modalVisible = useVisible()
  const [currentButtonAction, setCurrentButtonAction] = useState<CurrentButtonAction>()

  const { mutate: rejectKyc } = useRejectKyc()
  const { mutate: approveKyc } = useApproveKyc()

  const handleOpenModal = useCallback(() => {
    modalVisible.open()
  }, [modalVisible])

  const { publicProfile } = user?.tmnProfile || {}
  const { distinctId } = publicProfile || {}

  const { mutate: mixPanelTrackEvent } = useMixPanelTrackEvent(distinctId || '')
  const { mutate: brazeTrackEvent } = useBrazeTrackEvent(distinctId || '')

  const onRegisterSuccess = useCallback(
    (risk: boolean) => {
      if (distinctId) {
        const peopleProperty = [
          {
            key: EnumPeoplePropertyName.GOLD_REGISTERED_DATE,
            value: `${dayjs()}`,
          },
          {
            key: EnumPeoplePropertyName.GOLD_ACCOUNT_LEVEL,
            value: '2',
          },
          {
            key: EnumPeoplePropertyName.GOLD_ACCOUNT_RISK_LEVEL,
            value: '2',
          },
        ]
        mixPanelTrackEvent({
          mixPanelType: EnumMixPanelType.TRACK_AND_PEOPLE,
          eventName: EnumEventName.GOLD_REGISTER_SUCCESS,
          peopleProperty: peopleProperty,
        })
        brazeTrackEvent({
          eventName: EnumEventName.GOLD_REGISTER_SUCCESS,
        })
      }
    },
    [brazeTrackEvent, distinctId, mixPanelTrackEvent],
  )

  const onUpdateBankAccountStatusClick = useCallback(() => {
    if (currentButtonAction === CurrentButtonAction.APPROVE) {
      approveKyc(kycId, {
        onSuccess: () => {
          Toast.info(`Kyc Approve Success`)
        },
        onError: () => {
          Toast.error(`Kyc Approve Fail`)
        },
      })
    } else {
      rejectKyc(kycId, {
        onSuccess: () => {
          Toast.info(`Kyc Reject Success`)
        },
        onError: () => {
          Toast.error(`Kyc Reject Fail`)
        },
      })
    }
    modalVisible.close()
  }, [approveKyc, currentButtonAction, kycId, modalVisible, rejectKyc])

  return (
    <>
      <FlexButton>
        {appConfig.ENABLE_FEATURE_APPROVE_KYC && (
          <ButtonBankAccount
            onClick={() => {
              setCurrentButtonAction(CurrentButtonAction.APPROVE)
              handleOpenModal()
            }}
            name="Approve"
            variant="success"
          />
        )}

        <ButtonBankAccount
          onClick={() => {
            setCurrentButtonAction(CurrentButtonAction.REJECT)
            handleOpenModal()
          }}
          name="Reject"
          variant="danger"
        />
      </FlexButton>
      <Modal className={classes.modal} open={modalVisible.visible} onClose={modalVisible.close}>
        <Card style={{ width: '300px', padding: '30px' }}>
          <Text style={{ alignSelf: 'center' }}>Are You Sure</Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              paddingTop: '10px',
            }}
          >
            <Button
              style={{
                alignSelf: 'center',
                marginRight: '20px',
              }}
              onClick={onUpdateBankAccountStatusClick}
            >
              Yes
            </Button>
            <Button
              style={{
                alignSelf: 'center',
                backgroundColor: '#FF0000',
                borderColor: '#FF0000',
              }}
              onClick={modalVisible.close}
            >
              No
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  )
}

export default KycOnPendingAction
