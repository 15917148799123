import React, { useMemo } from 'react'
import Card from '../../../components/common/Card'
import { EnumSafeGoldDataType, Transaction } from '../../../services/transaction/transaction-typed'
import Table from '../../../components/common/Table'
import dayjs from 'dayjs'
import { pascalize } from 'humps'
import { replaceUnderScoreAndCapitalize } from '../../../../utils/helper'

interface TransactionCardProps {
  transaction: Transaction
}
export enum SafeGoldTransactionStatus {
  NOT_CONFIRMED = 0,
  SUCCESS = 1,
  FAILED = 2,
}

export enum EnumDeliveryDispatchStatus {
  PACKAGE_PENDING = 1,
  PACKED = 2,
  DISPATCHED = 3,
  DELIVERED = 4,
  FAILED = 5,
}
export enum EnumSettlementStatus {
  SETTLED = 1,
  FAILED = 2,
}

export const SafeGoldCallBacksCard = (props: TransactionCardProps) => {
  const { transaction } = props
  const { safeGoldCallbacks = [] } = transaction

  const data = useMemo(() => {
    return safeGoldCallbacks.map(safeGoldData => {
      const { isSuccess, data, createdAt } = safeGoldData
      const {
        status,
        txId,
        txDate,
        type,
        settlementDate,
        settlementStatus,
        deliveryStatus,
        productDeliveryNumber,
        isRefund,
      } = data
      const typeCapitalize = replaceUnderScoreAndCapitalize(type)
      switch (type) {
        case EnumSafeGoldDataType.BUY:
        case EnumSafeGoldDataType.SIP:
          return {
            txId,
            type: typeCapitalize,
            isSuccess: isSuccess ? 'Y' : 'N',
            status: SafeGoldTransactionStatus[status] || '-',
            isRefund: isRefund ? 'True' : '',
            txDate,
            createdAt: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
          }
        case EnumSafeGoldDataType.SELL:
          return {
            txId,
            type: typeCapitalize,
            isSuccess: isSuccess ? 'Y' : 'N',
            status: SafeGoldTransactionStatus[status] || '-',
            settlementStatus: EnumSettlementStatus[settlementStatus] || '-',
            settlementDate: settlementDate ? settlementDate : '-',
            isRefund: isRefund ? 'True' : '',
            createdAt: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
          }
        case EnumSafeGoldDataType.DELIVERY:
          return {
            txId,
            type: typeCapitalize,
            isSuccess: isSuccess ? 'Y' : 'N',
            status: SafeGoldTransactionStatus[status],
            deliveryStatus: deliveryStatus ? EnumDeliveryDispatchStatus[deliveryStatus] : '-',
            trackingNumber: productDeliveryNumber || '-',
            isRefund: isRefund ? 'True' : '',
            txDate,
            createdAt: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
          }
        default:
          break
      }
      return {
        txId,
        type: typeCapitalize,
        isSuccess: isSuccess ? 'Y' : 'N',
        status: SafeGoldTransactionStatus[status],
        txDate,
        createdAt: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      }
    })
  }, [safeGoldCallbacks])

  const columns = useMemo(() => {
    return (
      data.length &&
      Object.keys(data[0]).map(key => {
        return {
          title: pascalize(key),
          dataIndex: key,
        }
      })
    )
  }, [data])
  return columns ? (
    <Card style={{ marginTop: 10 }}>
      Safe Gold CallBack
      <Table style={{ marginTop: 10 }} columns={columns} data={data} />
    </Card>
  ) : (
    <></>
  )
}

export default SafeGoldCallBacksCard
