import React, { useCallback, useEffect, useMemo } from 'react'
import { Card } from 'react-bootstrap'
import Button from '../common/Button'
import { Modal, Typography } from '@material-ui/core'
import { Field, Form } from 'react-final-form'
import { InputField, SelectField } from '../fields'
import {
  useChangeTmnId,
  useCurrentUser,
  useGetUser,
  useUpdateRoleUser,
  useUpdateUserProfile,
} from '../../services/user/user-query'
import {
  composeValidators,
  formatMobile,
  formatThaiId,
  formatTmnId,
  validateEmail,
} from '../../../utils/helper'
import styled from '@emotion/styled'
import { ChangeTmnIdParams, ROLE } from '../../services/user/user-typed'
import Text from '../common/Text'
import { useToastForReactQuery } from '../../../utils/custom-hooks'
import { useTranslation } from 'react-i18next'
import { isEqual, some } from 'lodash'
import { EKycState } from '../../services/kyc/kyc-typed'

const ContentLayout = styled(Card)`
  width: 500px;
  min-height: 200px;
  padding: 30px 80px 20px 80px;
  grid-row-gap: 12px;
`

const InputFieldStyled = styled(InputField)`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 0;
  }
`

const ModalStyled = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

type UpdateTmnProfileFormValues = {
  thaiId: string
  oldTmnId: string
  newTmnId: string
  oldMobileNo: string
  newMobileNo: string
}

type ModalChangeTmnProfileProps = {
  userId: string
  visible: boolean
  onClose: () => void
}

type RoleKeys = keyof typeof ROLE

const ModalChangeTmnProfile = (props: ModalChangeTmnProfileProps) => {
  const { userId, visible, onClose } = props

  const toast = useToastForReactQuery()
  const { t } = useTranslation()

  const { mutate: changeTmnId } = useChangeTmnId()

  // const initialValues = useMemo(() => {
  //   const _initialValues: UpdateTmnProfileFormValues = {
  //     email: user?.email,
  //     mobileNo: user?.mobileNo,
  //     role: roleOptions.find(option => option.value === user?.role),
  //   }
  //   return _initialValues
  // }, [roleOptions, user])

  const onSubmit = useCallback(
    (values: UpdateTmnProfileFormValues) => {
      const changeTmnParams: ChangeTmnIdParams = {
        userId,
        ...values,
      }
      changeTmnId(changeTmnParams, {
        onSuccess: () => {
          toast.open('Success', {
            type: 'success',
            autoClose: 3 * 1000,
          })
        },
        onError: error => {
          toast.open(error, {
            type: 'error',
            autoClose: 3 * 1000,
          })
        },
      })
      onClose()
    },
    [changeTmnId, onClose, toast, userId],
  )
  const validateRequired = useCallback((value: string) => (value ? undefined : 'required'), [])

  const validateFormatMobile = useCallback(
    (value: string) => (formatMobile.test(value) ? undefined : 'invalid format mobile number'),
    [],
  )

  const validateFormatTmnId = useCallback(
    (value: string) => (formatTmnId.test(value) ? undefined : 'invalid format tmn id'),
    [],
  )

  const validateFormatThaiId = useCallback(
    (value: string) => (formatThaiId(value) ? undefined : 'invalid format thai id'),
    [],
  )

  return (
    <ModalStyled open={visible} onClose={onClose}>
      <div>
        <Form<UpdateTmnProfileFormValues>
          onSubmit={onSubmit}
          //initialValues={initialValues}
          subscription={{ dirty: true, submitting: true, valid: true }}
          // validate={values => {
          //   const errors: Partial<Record<keyof UpdateTmnProfileFormValues, string>> = {}
          //   if (canUpdateUserProfile) {
          //     if (!values.email) {
          //       errors.email = 'Required'
          //     }
          //     if (values.email && !validateEmail(values.email)) {
          //       errors.email = 'Email is invalid'
          //     }

          //     if (!values.mobileNo) {
          //       errors.mobileNo = 'Required'
          //     }
          //     if (values.mobileNo && values.mobileNo.length !== 10) {
          //       errors.mobileNo = 'Mobile No is invalid'
          //     }
          //   }
          //   return errors
          // }}
        >
          {({ handleSubmit, dirty, submitting, valid }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ContentLayout>
                  <div>
                    <Text>THAI ID</Text>
                    <Field
                      name="thaiId"
                      component={InputFieldStyled}
                      inputProps={{
                        maxLength: 13,
                      }}
                      validate={composeValidators(validateRequired, validateFormatThaiId)}
                    />
                  </div>
                  <div>
                    <Text>OLD TMN ID</Text>
                    <Field
                      name="oldTmnId"
                      component={InputFieldStyled}
                      validate={composeValidators(validateRequired, validateFormatTmnId)}
                    />
                  </div>
                  <div>
                    <Text>NEW TMN ID</Text>
                    <Field
                      name="newTmnId"
                      component={InputFieldStyled}
                      validate={composeValidators(validateRequired, validateFormatTmnId)}
                    />
                  </div>
                  <div>
                    <Text>OLD MOBILE NO</Text>
                    <Field
                      name="oldMobileNo"
                      component={InputFieldStyled}
                      inputProps={{
                        maxLength: 10,
                      }}
                      validate={composeValidators(validateRequired, validateFormatMobile)}
                    />
                  </div>
                  <div>
                    <Text>NEW MOBILE NO</Text>
                    <Field
                      name="newMobileNo"
                      component={InputFieldStyled}
                      inputProps={{
                        maxLength: 10,
                      }}
                      validate={composeValidators(validateRequired, validateFormatMobile)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'center',
                      padding: '20px',
                      gridColumnGap: '10px',
                    }}
                  >
                    <Button
                      style={{ alignSelf: 'center' }}
                      type="submit"
                      disabled={!dirty || submitting || !valid}
                    >
                      Confirm
                    </Button>
                    <Button
                      style={{
                        alignSelf: 'center',
                        backgroundColor: '#FF0000',
                        borderColor: '#FF0000',
                      }}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </ContentLayout>
              </form>
            )
          }}
        </Form>
      </div>
    </ModalStyled>
  )
}
export default ModalChangeTmnProfile
