import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import {
  ICreateGiveawayBody,
  IExportGiveawayReportParams,
  IGiveaway,
  IGiveawayList,
} from './giveaway-typed'
import { ContentType } from '../../../utils/api/tools'
import { downloadFile } from '../../../utils/helper'
import dayjs from 'dayjs'
import axios from 'axios'
import { compact, join } from 'lodash'
import { appConfig } from '../../constants/app-config'

const GIVEAWAY = 'internal/giveaways'
const GIVEAWAY_UPLOAD = `${GIVEAWAY}/upload`
const GIVEAWAY_REPORT = `${GIVEAWAY}/report`

export const useCreateGiveaway = () => {
  const queryClient = useQueryClient()
  return useMutation<any, string, ICreateGiveawayBody>(
    async body => {
      const { data } = await api.savvyGold.post(`${GIVEAWAY}`, {
        ...body,
        amount: Number(body.amount),
      })
      return data
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([GIVEAWAY])
      },
    },
  )
}

export const useGetGiveaways = (params?: { q?: string; page?: number }) => {
  return useQuery(
    [GIVEAWAY, params],
    async () => {
      const { data } = await api.savvyGold.get<IGiveawayList>(GIVEAWAY, {
        page: params?.page,
        limit: 10,
        q: params?.q,
      })
      const result = data.data

      return result
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useGetGiveawayById = (id: string) => {
  return useQuery([GIVEAWAY, id], async () => {
    const { data } = await api.savvyGold.get<IGiveaway>(`${GIVEAWAY}/${id}`)
    const result = data.data

    return result
  })
}

export const useUploadGiveaway = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, File>(
    async (file: File) => {
      // const formData = new FormData()
      // formData.append('file', file)
      console.log({ file })

      const { data } = await api.savvyGold.post(
        `${GIVEAWAY_UPLOAD}`,
        { file },
        {
          contentType: ContentType.FORMDATA,
        },
      )
      return data
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([GIVEAWAY])
      },
    },
  )
}

export const useGetGiveawayReport = () => {
  return useMutation<any, string, IExportGiveawayReportParams>(
    async (params: IExportGiveawayReportParams) => {
      const { startDate, endDate, ...restParams } = params
      const res = await api.savvyGold.getFile(`${GIVEAWAY_REPORT}`, {
        startDate: dayjs(startDate).format(),
        endDate: dayjs(endDate).format(),
        ...restParams,
      })
      downloadFile(res)
    },
  )
}
