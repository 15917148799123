import { UseMutationOptions } from 'react-query'
import { useMutation } from 'react-query'
import { api } from '../../../utils/api'
import { BrazeTrackEvent } from './braze.typed'

export const BASE_BRAZE = 'braze'

export const useBrazeTrackEvent = (
  externalId: string,
  options?: UseMutationOptions<{ data: string }, unknown, BrazeTrackEvent>,
) => {
  return useMutation(
    [BASE_BRAZE],
    async params => {
      const { data } = await api.savvyGold.post<{ data: string }>(
        `${BASE_BRAZE}/${externalId}`,
        params,
      )
      return data.data
    },
    {
      onError: () => {
        //error
      },
      ...options,
    },
  )
}
