import React, { useMemo } from 'react'
import {
  getColorStatusBankAccountText,
  replaceUnderScoreAndCapitalize,
} from '../../../../utils/helper'
import PortalCard from '../../../components/templates/PortalCard'
import { useGetBankAccount } from '../../../services/bankaccount/bankaccount-query'
import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import Text from '../../../components/common/Text'
import * as paths from '../../../constants/path'

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`
const TextColor = styled(Text)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`

interface BankAccountProps {
  bankAccountId: string
}

export const BankAccountCard = (props: BankAccountProps) => {
  const { bankAccountId } = props
  const { data: bankAccount } = useGetBankAccount(bankAccountId)
  const { no = '', name = '', remark = '', approvedStatus } = bankAccount || {}
  const dataBankAccount = useMemo(
    () => [
      { label: 'No.', value: no ? no : '-' },
      { label: 'bank name', value: name ? name : '-' },
      { label: 'message', value: remark ? remark : '-' },
    ],
    [name, no, remark],
  )

  return (
    <PortalCard
      title={
        <Flex>
          <Text bold variant="h5">
            Bank Account
          </Text>
          <TextColor variant="h5" color={getColorStatusBankAccountText(approvedStatus)}>
            ({replaceUnderScoreAndCapitalize(approvedStatus)})
          </TextColor>
        </Flex>
      }
      contents={dataBankAccount}
      viewMoreUrl={paths.bankAccountDetail({
        routeParam: {
          bankAccountId,
        },
      })}
    />
  )
}

export default BankAccountCard
