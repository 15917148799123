import React, { ReactNode, useMemo } from 'react'
import Typography, { TypographyTypeMap } from '@material-ui/core/Typography'
import { CSSProperties } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
}))

type TypographyColorType = Exclude<TypographyTypeMap<{}>['props']['color'], undefined>

export type TextProps = {
  children: ReactNode
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1'
  style?: CSSProperties
  gutterBottom?: boolean
  bold?: boolean
  linkTo?: string
  color?: TypographyColorType | string
}
const Text = (props: TextProps) => {
  const classes = useStyles()
  const { children, gutterBottom = true, bold, linkTo, color, ...restProps } = props
  const className = useMemo(() => {
    if (bold) {
      return classes.bold
    } else {
      return ''
    }
  }, [classes.bold, bold])

  const _colorProps = useMemo((): {
    color?: TypographyColorType
    style?: {
      color?: string
    }
  } => {
    if (color) {
      if (
        [
          'initial',
          'inherit',
          'primary',
          'secondary',
          'textPrimary',
          'textSecondary',
          'error',
        ].includes(color)
      ) {
        return {
          color: color as TypographyColorType,
        }
      } else {
        return {
          style: {
            color,
          },
        }
      }
    } else {
      return {
        color: 'inherit',
      }
    }
  }, [color])

  return !linkTo ? (
    <Typography gutterBottom className={className} {..._colorProps} {...restProps}>
      {children}
    </Typography>
  ) : (
    <Link to={linkTo}>
      <Typography gutterBottom className={className} {..._colorProps} {...restProps}>
        {children}
      </Typography>
    </Link>
  )
}

export default Text
