import { Dayjs } from 'dayjs'
import { EnumDeliveryDispatchStatus } from '../../pages/transaction/TransactionDetail/SafeGoldCallBacksCard'
import { Port } from '../port/port-typed'
import { User } from '../user/user-typed'

export enum EnumTransactionStatus {
  PENDING = 'pending',
  AWAITING_TRANSFER = 'awaiting_transfer', // for sell
  AWAIT_SAFEGOLD_CONFIRM = 'await_safegold_confirm',
  SUCCESS = 'success',
  FAIL = 'fail',
  REFUND = 'refund',
}

export enum EnumTransactionType {
  DELIVERY = 'delivery',
  SELL = 'sell',
  BUY = 'buy',
  DCA = 'dca',
  PICK_UP = 'pick_up',
  TRANSFER_IN = 'transfer_in',
  TRANSFER_OUT = 'transfer_out',
  ONE_TIME_DELIVERY = 'one_time_delivery',
}

export enum EnumTransactionTypeCapitalize {
  BUY = 'Buy',
  SELL = 'Sell',
  DCA = 'Dca',
  DELIVERY = 'Delivery',
  PICK_UP = 'Pick Up',
  TRANSFER_IN = 'Transfer In',
  TRANSFER_OUT = 'Transfer Out',
  ONE_TIME_DELIVERY = 'One Time Delivery',
}

export enum EnumSafeGoldDataType {
  JEWELLER = 'jeweller',
  DELIVERY = 'redeem',
  TRANSFER_IN = 'transferIn',
  TRANSFER_OUT = 'transferOut',
  SIP = 'sip',
  BUY = 'buy',
  SELL = 'sell',
}

export type PromoCodeDetail = {
  promoCode: string
  message: string
  promoType: number
  rewardPrice: number
  rewardAmount: number
}

export type Transaction = {
  bankAccountNo: string
  bankName: string
  createdAt: string
  deletedAt: string
  deliveryDetails: Delivery[]
  expiredTime: string
  goldAmount: number
  id: string
  invoiceId: string
  invoiceLink: string
  port: Port
  user: User
  portId: string
  price: string
  rateId: string
  sgRate: string
  sgUserId: string
  status: EnumTransactionStatus
  transactionValidity: string
  txId: string
  type: EnumTransactionType
  payment: Payment
  reference: string
  safeGoldCallbacks?: SafeGoldCallbacksType[]
  promoCodeDetail?: PromoCodeDetail
  txIdCc: string
  patternId: string
  errorMessage?: string
  spentGoldAmount?: number
}

export type SafeGoldCallbacksType = {
  createdAt: string
  deletedAt: string
  id: string
  isSuccess: boolean
  remark: string
  txId: string
  type: string
  updatedAt: string
  data: IDataSafeGoldCallback
}

export type IDataSafeGoldCallback = {
  buyPrice: string
  feeAmount: string
  feeAmountTax: string
  goldAmount: string
  preFeesBuyPrice: string
  rate: string
  status: number
  txDate: string
  txId: string
  type: EnumSafeGoldDataType
  userId: string
  settlementStatus: number
  settlementDate: string
  deliveryStatus?: EnumDeliveryDispatchStatus
  productDeliveryNumber?: string
  isRefund?: boolean
}

export type Payment = {
  amount: string
  createdAt: string
  currency: string
  deletedAt: string
  externalReference: string
  failOddLog: FailOddLog
  failureCode: string
  failureMessage: string
  id: string
  isChecksumError: boolean
  messageChecksumError: string
  orderId: string
  payplusId: string
  redirectUrl: string
  ref1: string
  referenceOrder: string
  status: string
  transactionId: string
  transactionState: string
  type: string
  updatedAt: string
  tmnIsvRefundRef: string
  tmnPaymentId: string
  tmnRefundId: string
}
export type FailOddLog = {
  authParameter: string
  externalSystem: string
  transactionList: {
    amount: number
    espaId: string
    externalReference: string
    payeeShortName: string
    payerAccount: string
    payerShortName: string
    returnCode: string
    returnMessage: string
    returnStatus: string
    timestamp: string
    transactionNo: string
  }[]
  transactionMode: string
  transactionType: string
}

export type PageMeta = {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
}

export type Delivery = {
  createdAt: string
  deletedAt: string
  id: string
  courierCompany: string
  courierTrackingId: string
  deliveryCost: string
  deliveryStatus: number
  message: string
  mintingCost: string
  patternId: string
  patternName: string
  productId: string
  trackingId: string
  trackingNumber: string
  transactionId: string
  updatedAt: string
  address: addresstype
  skuNumber: string
  trackingLink: string
  vat: number
  amount: number
}
export type addresstype = {
  addressableId: string
  addressableType: string
  createdAt: string
  deletedAt: string
  detail: string
  district: string
  houseNo: string
  id: string
  mobileNo: string
  province: string
  subDistrict: string
  type: string
  updatedAt: string
  zipCode: string
}

export type TransactionList = {
  items: Transaction[]
  meta: PageMeta
}

export type ExportTransactionReportParams = {
  startDate: Dayjs
  endDate: Dayjs
  status?: EnumTransactionStatus
  type?: EnumTransactionType
}

export type GoldProductType = {
  id: number
  name: string
  inStock: 'Y' | 'N'
  skuNumber: string
  purity: string
  weight: string
  goldPattern: string
  description: string
  description2: string
  packaging: string
  certification: string
  opportunities: string
  serialNumber: string
  deliveryCost: string
  mintingCost: string
  pattern: string
  images: string[]
  cardName: string
  vatFees: number
  brand: string
}

export type GetProductsResponseType = {
  products: GoldProductType[]
}
