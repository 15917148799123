import React, { useCallback } from 'react'
import ListPage from '../../../components/templates/ListPage'
import * as pahts from '../../../constants/path'
import { useRouter } from '../../../../utils/helper'
import { useGetPorts } from '../../../services/port/port-query'
import ButtonViewTable from '../../../components/ButtonViewTable'
import { Link, useHistory } from 'react-router-dom'
import UsernameTableColumn from '../../../components/UsernameTableColumn'
import { Port } from '../../../services/port/port-typed'
import Text from '../../../components/common/Text'
import numeral from 'numeral'

const column = (onClick: any) => [
  {
    title: 'User',
    dataIndex: 'name',
    render: (text: string, port: Port) => {
      const { firstnameTh = '', lastnameTh = '', citizenId = '' } = port.user || {}
      return (
        <UsernameTableColumn
          firstnameTh={firstnameTh}
          lastnameTh={lastnameTh}
          citizenId={citizenId}
        />
      )
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (text: string, port: Port) => {
      const { email, id } = port.user || {}
      return email ? (
        <Link to={`/users/${id}`}>
          <Text>{email}</Text>
        </Link>
      ) : (
        <Text>''</Text>
      )
    },
  },
  {
    title: 'Mobile No.',
    dataIndex: 'mobileNo',
    render: (text: string, port: Port) => {
      const { sgMobileNo, userId } = port || {}
      return sgMobileNo ? (
        <Link to={`/users/${userId}`}>
          <Text>{sgMobileNo}</Text>
        </Link>
      ) : (
        <Text>''</Text>
      )
    },
  },
  {
    title: 'Balance (Baht Gold)',
    dataIndex: 'sgGoldBalance',
    render: (text: string, record: { sgGoldBalance: string }) => {
      const { sgGoldBalance } = record
      let b = Number(sgGoldBalance)
      const goldbalance = b / 15.244
      const balance = numeral(goldbalance).format('0,0.0000')
      return <Text>{balance}</Text>
    },
  },
  {
    title: 'Balance (g.)',
    dataIndex: 'sgGoldBalance',
    render: (text: string, record: { sgGoldBalance: string }) => {
      const { sgGoldBalance } = record
      let b = Number(sgGoldBalance)
      const goldbalance = b
      const balance = numeral(goldbalance).format('0,0.0000')
      return <Text>{balance}</Text>
    },
  },
  {
    title: '',
    width: 100,
    dataIndex: 'buttonView',
    render: (text: string, port: Port) => {
      return <ButtonViewTable onClick={onClick.bind(null, port.id)} />
    },
  },
]

const PortList = () => {
  const { push, query } = useRouter()
  const { q, page } = query
  const { data: ports } = useGetPorts({ q, page })

  const history = useHistory()
  const setQueryParam = useCallback(
    params => {
      push(pahts.ports({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )
  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )
  const onViewClick = useCallback(
    (portId: string) => {
      history.push(pahts.portDetail({ routeParam: { portId } }))
    },
    [history],
  )

  return (
    <ListPage
      searchInputProps={{ onSearch, value: q }}
      tableProps={{
        columns: column(onViewClick),
        data: ports?.items,
        onPageChange,
        pagination: ports?.meta,
      }}
    />
  )
}

export default PortList
