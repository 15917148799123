import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { imageUrlToBase64, urlToFile } from '../../../../utils/image-helper'
import ImageEasyCrop, { CropAspect } from '../../../components/ImageEasyCrop'

const UploadArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 10px 14px;
  border-radius: 4px;
  border: 1px dotted gray;
`

const ImageEasyCropLayout = styled.div`
  position: relative;
`

export type IUploadRenderProps = {
  onChange?: (value: string) => void
}

export const UploadThumbNail = (props: IUploadRenderProps) => {
  const { onChange } = props
  const [imageBase64, setImageBase64] = useState<string>('')

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (acceptedFiles.length > 0) {
      // Update the state with the uploaded file
      const file = acceptedFiles[0]
      const base64 = await readFileAsBase64(file)
      setImageBase64(base64)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
    }, // Only allow image files
    multiple: false, // Only allow single file upload
  })

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        // Read the file as base64 data
        const base64Data = reader.result as string
        resolve(base64Data)
      }

      reader.onerror = () => {
        reject(reader.error)
      }

      reader.readAsDataURL(file)
    })
  }

  const resizeImage = async (
    base64: string,
    targetSizeKB: number,
    targetAspect: number,
  ): Promise<string> => {
    return new Promise(resolve => {
      const img = new Image()
      img.src = base64

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')!

        // Set a maximum size to ensure better performance
        const maxSize = 1080
        let newWidth, newHeight

        // Calculate new dimensions based on the target aspect ratio
        if (img.width > img.height) {
          newWidth = Math.min(img.width, maxSize)
          newHeight = newWidth / targetAspect
        } else {
          newHeight = Math.min(img.height, maxSize)
          newWidth = newHeight * targetAspect
        }

        // Set the new dimensions on the canvas
        canvas.width = newWidth
        canvas.height = newHeight

        // Draw the image on the canvas with the new dimensions
        ctx.drawImage(img, 0, 0, newWidth, newHeight)

        // Initialize quality and compression settings
        let currentQuality = 1 // Starting with a high quality
        let currentSizeKB = Number.MAX_SAFE_INTEGER
        const step = 0.1

        // Iterate until the size is below the target
        while (currentSizeKB > targetSizeKB && currentQuality >= 0.7) {
          console.log('currentSizeKB:', currentSizeKB)
          const imageDataUrl = canvas.toDataURL('image/jpeg', currentQuality)
          currentSizeKB = imageDataUrl.length / 1024
          currentQuality -= step
        }

        // Resolve with the final base64 string
        resolve(canvas.toDataURL('image/jpeg', currentQuality))
      }
    })
  }

  const onConfirm = useCallback(
    async (imagePath: string): Promise<void> => {
      const base64Result = await imageUrlToBase64(imagePath)
      const base64 = base64Result?.toString()

      if (base64) {
        // Resize the image to target size (e.g., 100KB)
        const aspect = 16 / 9
        const resizedBase64 = await resizeImage(base64, 100, aspect)
        onChange?.(resizedBase64)
        // onChange?.(base64)
      }
    },
    [onChange],
  )

  const onClose = useCallback(() => {
    onChange?.('')
  }, [onChange])

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })}>
        <UploadArea>
          Upload Thumbnail Image
          <input {...getInputProps()} />
        </UploadArea>
      </div>
      <ImageEasyCropLayout>
        {!!imageBase64 && (
          <ImageEasyCrop
            image={imageBase64}
            aspect={CropAspect['16:9']}
            onConfirm={onConfirm}
            onClose={onClose}
          />
        )}
      </ImageEasyCropLayout>
    </div>
  )
}
