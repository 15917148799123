import React from 'react'
import styled from '@emotion/styled'

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
const Flexrow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
`
const FlexName = styled(Flex)`
  gap: 10px;
`

interface UserProfileNameCitizenProps {
  firstnameTh: string
  lastnameTh: string
  citizenId: string
}
const UserProfileNameCitizen = (props: UserProfileNameCitizenProps) => {
  const { firstnameTh, lastnameTh, citizenId } = props

  return (
    <div>
      <Flex>
        <Flexrow>
          <FlexName>
            <div>{firstnameTh}</div>
            <div>{lastnameTh}</div>
          </FlexName>

          <div>{citizenId}</div>
        </Flexrow>
      </Flex>
    </div>
  )
}

export default UserProfileNameCitizen
