import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { TRANSACTION_URL } from '../transaction/transaction-query'
import { Port, PortList, SellClearPortParams, SellType } from './port-typed'

export const PORT_URL = `internal/ports`

export const useGetPorts = (params?: { q?: string; page?: number }) => {
  return useQuery(
    [PORT_URL, params],
    async () => {
      const datas = await api.savvyGold.get<PortList>(PORT_URL, {
        page: params?.page,
        limit: 10,
        q: params?.q,
      })

      return datas.data.data
    },
    {
      keepPreviousData: true,
    },
  )
}
export const useGetPort = (id: string) => {
  return useQuery(
    [PORT_URL, id],
    async () => {
      const data = await api.savvyGold.get<Port>(`${PORT_URL}/${id}`)
      return data.data.data
    },
    {
      enabled: !!id,
    },
  )
}

export const useSellClearPortByPortId = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: SellClearPortParams) => {
      const { data } = await api.savvyGold.post(`${PORT_URL}/clear-port`, param)
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PORT_URL])
        queryClient.invalidateQueries([TRANSACTION_URL])
      },
    },
  )
}
