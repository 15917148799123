export enum EnumEventName {
  GOLD_ONBOARD_BEGIN = 'Gold - Onboard Begin',
  GOLD_ONBOARD_ACCEPT_TC = 'Gold - Onboard Accept T&C',
  GOLD_ONBOARD_ACCOUNT_VERIFIED = 'Gold - Onboard Account Verified',
  GOLD_ONBOARD_ACCOUNT_FILL_INFO = 'Gold - Onboard Account Fill info',
  GOLD_ONBOARD_CONFIRMED_INFO = 'Gold - Onboard confirmed info',
  GOLD_ONBOARD_OTP_SUCCESS = 'Gold - Onboard OTP Success',
  GOLD_REGISTER_SUCCESS = 'Gold - Register Success',
  GOLD_REQUIRED_EDD = 'Gold - Required EDD',
  GOLD_SUBMITTED_EDD = 'Gold - Submitted EDD',
  GOLD_HOME_DASHBOARD = 'Gold - Home Dashboard',
  GOLD_CLICK_BUTTON = 'Gold - Click Button',
  GOLD_BUY_INPUT_AMOUNT = 'Gold - Buy Input Amount',
  GOLD_BUY_GOLD_SUCCESS = 'Gold - Buy Gold Success',
  GOLD_DCA_SETUP_SUCCESS = 'Gold - DCA Setup Success',
  GOLD_DCA_DELETE = 'Gold - DCA Delete',
  GOLD_SELL_INPUT_AMOUNT = 'Gold - Sell Input Amount',
  GOLD_SELL_GOLD_SUCCESS = 'Gold - Sell Gold Sucess',
  GOLD_ADD_BOOK_BANK_SUCCESS = 'Gold - Add Book Bank Success',
  GOLD_WITHDRAW_DELIVERY_GOLD_CONFIRM = 'Gold - Withdraw Delivery Gold Confirm',
  GOLD_PAY_FEE_SUCCESS = 'Gold - Pay Fee Success',
  GOLD_ERROR = 'Gold - Error',
}

export enum EnumMixPanelType {
  TRACK = 'track',
  PEOPLE = 'people',
  TRACK_AND_PEOPLE = 'track_and_people',
}

export enum EnumPropertyName {
  CHANNEL = 'Channel',
  MARKETING_CONSENT = 'Marketing consent',
  SOURCE = 'Source',
  QUESTION = 'Question',
  TOTAL_GOLD = 'Total Gold',
  TOTAL_BALANCE = 'Total balance',
  IS_CUSTOMER = 'isCustomer',
  PROFIT_LOSS = 'Profit/Loss',
  PERCENT_PROFIT_LOSS = '%Profit/Loss',
  GOLD_MARKET_PRICE = 'Gold Market Price',
  PERCENT_CHANGE = '% Change',
  BUTTON_NAME = 'Button name',
  AMOUNT = 'Amount',
  GOLD_WEIGHT = 'Gold Weight',
  GOLD_PRICE = 'Gold Price',
  DATE = 'Date',
  BANK = 'Bank',
  PATTERN = 'Pattern',
  PREMIUM_FEE = 'Premium fee',
  DELIVERY_FEE = 'Delivery fee',
  ERROR_CODE = 'Error code',
  ERROR_MESSAGE = 'Error message',
}

export enum EnumPeoplePropertyName {
  GOLD_BIRTHYEAR = 'Gold - Birthyear',
  GOLD_MARKETING_CONSENT = 'Gold - Marketing consent',
  GOLD_ACCOUNT_LEVEL = 'Gold - Account Level',
  GOLD_OCCUPATION = 'Gold - Occupation',
  GOLD_INCOME_RANGE = 'Gold - Income Range',
  GOLD_REGISTERED_DATE = 'Gold - Registered Date',
  GOLD_ACCOUNT_RISK_LEVEL = 'Gold - Account Risk Level',
  GOLD_ONBOARD_STATUS = 'Gold - Onboard Status',
  GOLD_TOTAL_GOLD = 'Gold - Total Gold',
  GOLD_TOTAL_BALANCE = 'Gold - Total Balance',
  GOLD_PROFIT_LOSS = 'Gold - Profit/Loss',
  GOLD_DCA_DATE = 'Gold - DCA Date',
  GOLD_DCA_AMOUNT = 'Gold - DCA Amount',
}

export type Property = {
  key: string
  value: string
}

export type PeopleProperty = {
  key: string
  value: string
}

export type MixPanelTrackEvent = {
  mixPanelType: EnumMixPanelType
  eventName: EnumEventName
  property?: Property[]
  peopleProperty?: PeopleProperty[]
}
