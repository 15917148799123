import React, { useCallback } from 'react'
import ListPage from '../../../components/templates/ListPage'
import ButtonViewTable from '../../../components/ButtonViewTable'
import Text from '../../../components/common/Text'
import {
  getTransactionTypeColor,
  replaceUnderScoreAndCapitalize,
  useRouter,
} from '../../../../utils/helper'
import { Link, useHistory } from 'react-router-dom'
import * as paths from '../../../constants/path'
import { useGetTransactions } from '../../../services/transaction/transaction-query'
import {
  EnumTransactionStatus,
  EnumTransactionTypeCapitalize,
  Transaction,
} from '../../../services/transaction/transaction-typed'
import numeral from 'numeral'
import dayjs from 'dayjs'
import ExportTransactionReportButton from './ExportTransactionReportButton'
import { ColumnsProps } from '../../../components/common/Table'
import TransactionStatusTag from './TransactionStatusTag'

const column = (onClick: any) =>
  [
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: EnumTransactionTypeCapitalize) => {
        return (
          <Text bold style={{ color: getTransactionTypeColor(type) }}>
            {replaceUnderScoreAndCapitalize(type)}
          </Text>
        )
      },
    },
    {
      title: 'Gold Amount',
      dataIndex: 'goldAmount',
      render: (goldAmount: number, transaction: Transaction) => {
        const { spentGoldAmount = 0 } = transaction
        const totalGoldAmount = goldAmount + spentGoldAmount
        return <Text>{totalGoldAmount} </Text>
      },
    },
    {
      title: 'Price (THB)',
      dataIndex: 'price',
      render: (price: string, transaction: Transaction) => {
        const { sgRate = 0, goldAmount = 0, spentGoldAmount = 0 } = transaction
        const totalPrice = Number(sgRate) * (goldAmount + spentGoldAmount)
        return <Text>{numeral(totalPrice).format('0,0.00')} </Text>
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text: string, transaction: Transaction) => {
        const { email = '', id = '' } = transaction.port.user || {}
        return email ? (
          <Link to={`/users/${id}`}>
            <Text>{email}</Text>
          </Link>
        ) : (
          <Text>''</Text>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (createdAt: string) => {
        return <Text>{dayjs(createdAt).format('D MMMM  YYYY HH:mm ')}</Text>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: EnumTransactionStatus) => {
        return <TransactionStatusTag status={status} />
      },
    },
    {
      title: '',
      dataIndex: 'buttonView',
      render: (text: string, transaction: Transaction) => {
        return <ButtonViewTable onClick={onClick.bind(null, transaction.id)} />
      },
    },
  ] as ColumnsProps

export const TransactionList = (props: any) => {
  const { push, query } = useRouter()
  const { q, page } = query
  const { data: transactions } = useGetTransactions({ q, page })
  const history = useHistory()
  const setQueryParam = useCallback(
    params => {
      push(paths.transactions({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )
  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )

  const onViewClick = useCallback(
    (transactionId: string) => {
      history.push(paths.transactionDetail({ routeParam: { transactionId } }))
    },
    [history],
  )
  return (
    <ListPage
      searchInputProps={{ onSearch, value: q }}
      topbar={<ExportTransactionReportButton />}
      tableProps={{
        columns: column(onViewClick),
        data: transactions?.items,
        onPageChange,
        pagination: transactions?.meta,
      }}
    />
  )
}

export default TransactionList
