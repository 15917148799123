import { Paging } from '../shared-typed'

export type CreateCampaignBody = {
  title: string
  startDate: string
  endDate: string
  body?: string
  buttonName: string
  route: string
  thumnailImage?: string
}

export enum EnumCampaignPath {
  market = '/market-place',
  profile = '/profile',
  faq = '/faq',
  buy = '/buy-option/buy-now',
  sell = '/sell-gold',
}

export type CampaignResponseType = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  body: string
  creatorId: string
  updaterId: string
  title: string
  buttonName: string
  route: string
  thumbnailImage?: string
  startDate: Date
  endDate: Date
}

export type CampaignListResponseType = {
  items: CampaignResponseType[]
  meta: Paging
}
