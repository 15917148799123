import { ceil, floor, isNumber } from 'lodash'
import { useCallback, useMemo } from 'react'

export const GOLD_WEIGHT_PER_GOLD_BAHT = 15.244 as const

export const roundDownOnly = (value: number, digit = 0) => {
  return isNumber(Number(value)) ? floor(Number(value), digit) : 0
}

export const roundUpOnly = (value: number, digit = 0) => {
  return isNumber(Number(value)) ? ceil(Number(value), digit) : 0
}

export const numberWithCommas = (value: number, digit?: number) => {
  const option = isNumber(digit)
    ? {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      }
    : {}
  return isNumber(Number(value)) ? Number(value).toLocaleString('en-US', option) : value
}

export const useGoldConvertor = () => {
  const moneyToGoldWeight = useCallback((money: number, goldPriceInGrams: number) => {
    return goldPriceInGrams > 0 ? money / goldPriceInGrams : 0
  }, [])

  const goldWeightToGoldBaht = useCallback((goldWeight: number) => {
    return goldWeight / GOLD_WEIGHT_PER_GOLD_BAHT
  }, [])
  const goldWeightToMoney = useCallback((goldWeight: number, goldPriceInGrams: number) => {
    return goldWeight * goldPriceInGrams
  }, [])

  const goldBahtToGoldWeight = useCallback((goldBaht: number) => {
    return goldBaht * GOLD_WEIGHT_PER_GOLD_BAHT
  }, [])

  const moneyToGoldBaht = useCallback(
    (money: number, goldPriceInGrams: number) => {
      const goldWeight = moneyToGoldWeight(money, goldPriceInGrams)
      return goldWeightToGoldBaht(goldWeight)
    },
    [moneyToGoldWeight, goldWeightToGoldBaht],
  )

  const goldBahtToMoney = useCallback(
    (goldBaht: number, goldPriceInGrams: number) => {
      const goldWeight = goldBahtToGoldWeight(goldBaht)
      return goldWeightToMoney(goldWeight, goldPriceInGrams)
    },
    [goldWeightToMoney, goldBahtToGoldWeight],
  )

  const goldPriceInGramsToGoldPriceInBaht = useCallback((goldPriceInGrams: number) => {
    return goldPriceInGrams * GOLD_WEIGHT_PER_GOLD_BAHT
  }, [])

  return {
    GOLD_WEIGHT_PER_GOLD_BAHT,
    moneyToGoldWeight,
    moneyToGoldBaht,
    goldWeightToGoldBaht,
    goldWeightToMoney,
    goldBahtToGoldWeight,
    goldBahtToMoney,
    goldPriceInGramsToGoldPriceInBaht,
  }
}

export const useSellMoneyHelper = (sellInBaht: number, goldPriceInGrams: number) => {
  const {
    moneyToGoldWeight,
    goldPriceInGramsToGoldPriceInBaht,
    // goldWeightToMoney,
    goldWeightToGoldBaht,
  } = useGoldConvertor()

  /**
   * calculate gold weight
   * and round down only to 4 digit for safe gold
   * */
  const sellInGoldWeight = useMemo(
    () => roundDownOnly(moneyToGoldWeight(sellInBaht, goldPriceInGrams), 4),
    [sellInBaht, goldPriceInGrams, moneyToGoldWeight],
  )

  /**
   * convert gold weight to thai baht
   * and round up only to 2 digit is real amount for payment
   * */
  // const sellInThaiBaht = useMemo(
  //   () => roundUpOnly(goldWeightToMoney(sellInGoldWeight, goldPriceInGrams), 2),
  //   [sellInGoldWeight, goldPriceInGrams, goldWeightToMoney],
  // )

  /**
   * convert gold weight to gold baht
   * */
  const sellInGoldBaht = useMemo(() => goldWeightToGoldBaht(sellInGoldWeight), [
    sellInGoldWeight,
    goldWeightToGoldBaht,
  ])

  /**
   * convert gold price in grams to gold price in thai baht
   * */
  const goldPriceInBaht = useMemo(
    () => roundUpOnly(goldPriceInGramsToGoldPriceInBaht(goldPriceInGrams), 2),
    [goldPriceInGrams, goldPriceInGramsToGoldPriceInBaht],
  )

  return {
    sellInThaiBaht: sellInBaht,
    sellInGoldWeight,
    sellInGoldBaht,
    goldPriceInGrams,
    goldPriceInBaht,
  }
}

type DisplayNumberFormatOptions = {
  digits?: number
  roundMode?: 'up' | 'down'
}

export const useDisplayNumberFormat = () => {
  const getRoundValue = useCallback((value: number, options?: DisplayNumberFormatOptions) => {
    const { digits = 4, roundMode = 'down' } = options || {}
    if (roundMode === 'down') {
      return roundDownOnly(value, digits)
    } else {
      return roundUpOnly(value, digits)
    }
  }, [])

  const formatMoney = useCallback(
    (value: number, options?: DisplayNumberFormatOptions) => {
      const { digits = 2 } = options || {}
      const tempValue = getRoundValue(value, {
        digits,
        roundMode: 'up',
      })
      return numberWithCommas(tempValue, digits)
    },
    [getRoundValue],
  )

  const formatGoldBaht = useCallback(
    (value: number, options?: DisplayNumberFormatOptions) => {
      const { digits = 4, roundMode } = options || {}
      const tempValue = getRoundValue(value, {
        digits,
        roundMode,
      })
      return numberWithCommas(tempValue, digits)
    },
    [getRoundValue],
  )
  return {
    formatMoney,
    formatGoldBaht,
  }
}
