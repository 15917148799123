import { useQuery, useQueryClient, useMutation } from 'react-query'

import { api } from '../../../utils/api'
import { CreateBannerBody, GetAllBannersResponse, GetOneBannerResponse } from './banner-type'

export const BASE_BANNER_URL = 'bo/banners'

export const useGetAllBanner = (params?: {
  searchTitle?: string
  page?: number
  sort?: string
}) => {
  return useQuery(
    [BASE_BANNER_URL, params],
    async () => {
      const response = await api.savvyGold.get<GetAllBannersResponse>(BASE_BANNER_URL, {
        limit: 10,
        page: params?.page,
        searchTitle: params?.searchTitle,
      })

      return response.data.data
    },

    {
      keepPreviousData: true,
    },
  )
}

export const useCreateBanner = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: CreateBannerBody) => {
      const { data } = await api.savvyGold.post(`${BASE_BANNER_URL}`, params)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_BANNER_URL])
      },
    },
  )
}

export const useGetBannerById = (bannerId: string) => {
  return useQuery(
    [BASE_BANNER_URL, bannerId],
    async () => {
      const response = await api.savvyGold.get<GetOneBannerResponse>(
        `${BASE_BANNER_URL}/${bannerId}`,
      )
      return response.data.data
    },
    {
      enabled: !!bannerId,
    },
  )
}

export const useEditBanner = (bannerId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: CreateBannerBody) => {
      const { data } = await api.savvyGold.patch(`${BASE_BANNER_URL}/${bannerId}`, params)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_BANNER_URL])
      },
    },
  )
}

export const useDeleteBanner = (bannerId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    [BASE_BANNER_URL],
    async () => {
      const { data } = await api.savvyGold.delete(`${BASE_BANNER_URL}/${bannerId}`)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_BANNER_URL])
      },
    },
  )
}
