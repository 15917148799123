import { getTranslationErrors } from './errors'

export const languages = [
  {
    label: 'ภาษาไทย',
    value: 'th',
  },
  {
    label: 'English',
    value: 'en',
  },
] as const

export type LanguageKeys = typeof languages[number]['value']
export const makeText = (key: LanguageKeys) => (en: string, th: string) =>
  ({
    en,
    th,
  }[key])

export const getTranslation = (key: LanguageKeys) => {
  return {
    [key]: {
      translation: {
        ...getTranslationErrors(key),
      },
    },
  }
}
