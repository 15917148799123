import { Attachment } from '../bankaccount/bankaccount-typed'
import { Paging } from '../shared-typed'
import { User } from '../user/user-typed'

export type Port = {
  createdAt: string
  deletedAt: string
  id: string
  kycId: string
  sgGoldBalance: string
  sgMobileNo: string
  sgPincode: string
  sgUserId: string
  updatedAt: string
  user?: User
  userId: string
  dcaPlan: DcaPlans
  address: Address[]
  attachments: Attachment[]
}
export type DcaPlans = {
  portId: string
  date: string
  value: string
  deletedAt: string
  id: string
  createdAt: string
  updatedAt: string
}
export type PortList = {
  items: Port[]
  meta: Paging
}
export type Address = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  addressableId: string
  addressableType: string
  type: 'citizen' | 'recent' | 'work'
  province: string
  district: string
  subDistrict: string
  detail: string
  mobileNo: string
  zipCode: string
  houseNo: string
}

export enum SellType {
  BANK = 'bank',
  TMNWallet = 'true_money_wallet',
}


export type SellClearPortParams = {
  portId: string
  sellType?: SellType
}