import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

type SubMenuProps = {
  title: string;
  to: string;
};

export default (props: SubMenuProps) => {
  const { title, to } = props;
  const location = useLocation();
  const getMenuItemActive = (url: string, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  return (
    <li
      className={`menu-item ${getMenuItemActive(to, false)}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to={to}>
        <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
        </span>
        <span className="menu-text">{title}</span>
      </NavLink>
    </li>
  );
};
