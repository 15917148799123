import React, { Fragment } from 'react'
import { RiskAssessment } from "../../../services/kyc/kyc-typed"
import styled from '@emotion/styled'
import Text from '../../../components/common/Text'

export type RiskAssessmentDetailProps = {
  riskAssessments: RiskAssessment[]
}

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
`

const RiskAssessmentDetail = (props: RiskAssessmentDetailProps) => {
  const {riskAssessments} = props
  return (
    <Grid>
        {riskAssessments.map((risk, index)=>{
          return (
              <Fragment key={`${risk.id}`}>
                <div>
                  <Text>
                    {`${index+1}. ${risk.description}`}
                  </Text>
                  <Text bold style={{ color: 'black' }}>
                    {risk.answer ? '- ใช่ -' : '- ไม่ใช่ -'}
                  </Text>
                </div>
              </Fragment>)
            }
          )
        }
        </Grid>
 
  )
}

export default RiskAssessmentDetail