import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styled from '@emotion/styled'
import Text from '../../../components/common/Text'
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import Button from '../../../components/common/Button'
import Card from 'react-bootstrap/Card'
import { Typography } from '@material-ui/core'
import {
  useDisplayNumberFormat,
  useGoldConvertor,
  useSellMoneyHelper,
} from '../../../../utils/core'
import { useGetSellPrice } from '../../../services/sell/sell-query'
import { SellType } from '../../../services/port/port-typed'

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  /* *:nth-of-type(odd) {
    margin-right: 8px;
  } */
`
const Divider = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const ContentLayout = styled(Card)`
  width: fit-content;
  min-width: 350px;
  /* min-height: 300px; */
  padding: 2.25rem;
  grid-row-gap: 12px;
`

const TextLib = styled(Typography)`
  line-height: 2 !important;
`
const TextColor = styled(TextLib)<{ color?: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`

export type SellGoldOption = {
  title: string
  sellType: SellType
}

type SellGoldModalProps = {
  isOpen: boolean
  onClosed: () => void
  onConfirm: (sellType?: SellType) => void
  sellOptions: SellGoldOption[]
  balance?: string
}

export const SellGoldModal = (props: SellGoldModalProps) => {
  const { isOpen, onClosed, onConfirm, balance, sellOptions } = props
  const { data: sellPriceDetail, isLoading, refetch } = useGetSellPrice()

  const [selectedSellType, setSelectedSellType] = useState<SellType>()

  const classes = useStyles()
  const { formatMoney, formatGoldBaht } = useDisplayNumberFormat()
  const { goldPriceInGrams, goldPriceInBaht } = useSellMoneyHelper(
    0,
    sellPriceDetail?.currentPrice || 0,
  )
  const { goldWeightToMoney, goldWeightToGoldBaht } = useGoldConvertor()

  const handleClose = useCallback(() => {
    onClosed()
  }, [onClosed])

  const onClickSubmit = useCallback(() => {
    onConfirm(selectedSellType)
  }, [onConfirm, selectedSellType])

  const onClickCancel = useCallback(() => {
    onClosed()
  }, [onClosed])

  const sellAmountInThaiBaht = useMemo(() => {
    const userMoney = goldWeightToMoney(Number(balance), goldPriceInGrams)
    return userMoney
  }, [balance, goldPriceInGrams, goldWeightToMoney])

  const userGoldBalanceInGoldWeight = useMemo(() => {
    const userGoldBaht = balance !== undefined ? goldWeightToGoldBaht(Number(balance)) : 0

    return userGoldBaht !== undefined
      ? `${
          userGoldBaht === 0
            ? formatGoldBaht(userGoldBaht, { digits: 2 })
            : formatGoldBaht(userGoldBaht, { digits: 4 })
        } gold baht`
      : '-'
  }, [balance, formatGoldBaht, goldWeightToGoldBaht])

  useEffect(() => {
    if (isOpen) {
      refetch()
    }
  }, [isOpen, refetch])

  const contents = useMemo(() => {
    return [
      { label: 'Balance', value: userGoldBalanceInGoldWeight },
      {
        label: 'Sell Rate',
        value: `฿${formatMoney(goldPriceInBaht ?? 0, {
          digits: 2,
        })}`,
      },
      { value: <Divider /> },
      {
        label: 'Money To Receive',
        value: `฿${formatMoney(sellAmountInThaiBaht ?? 0, {
          digits: 2,
        })}`,
      },
    ]
  }, [formatMoney, goldPriceInBaht, sellAmountInThaiBaht, userGoldBalanceInGoldWeight])

  return (
    <Modal className={classes.modal} open={isOpen} onClose={handleClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <ContentLayout>
          <TextColor variant="h5">Cancel Saving Gold</TextColor>
          {contents.map((content, index) => {
            const { label, value } = content
            return (
              <Row
                key={`${index}-${label}`}
                style={{ justifyContent: 'space-between', marginTop: 10 }}
              >
                {label && <Text>{label}</Text>}

                {typeof value === 'string' ? <Text bold>{value}</Text> : value}
              </Row>
            )
          })}

          <Divider />

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Payment Type</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {sellOptions?.map((option, index) => {
                return (
                  <FormControlLabel
                    key={option.sellType + index}
                    value={option.sellType}
                    control={<Radio />}
                    label={option.title}
                    onClick={() => setSelectedSellType(option.sellType)}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              gridColumnGap: '10px',
            }}
          >
            <Button onClick={onClickSubmit} variant={'primary'} disabled={!selectedSellType}>
              Confirm
            </Button>
            <Button onClick={onClickCancel} variant={'danger'}>
              Cancel
            </Button>
          </div>
        </ContentLayout>
      )}
    </Modal>
  )
}
