import React, { useCallback, useMemo } from 'react'
import { useRouter } from '../../../../utils/helper'
import ListPage from '../../../components/templates/ListPage'
import * as paths from '../../../constants/path'
import Button from '../../../components/common/Button'
import Text from '../../../components/common/Text'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { useGetAllArticle } from '../../../services/article/article-query'
import { Chip } from '@material-ui/core'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import { useGetCampaignWithPage } from '../../../services/campaign/campaign-query'
import { EnumCampaignPath } from '../../../services/campaign/campaign-typed'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`
const ButtonStyled = styled(Button)`
  padding: 7px 13px;
`

const CampaignList = () => {
  const { push, query } = useRouter()
  const { data: campaign } = useGetCampaignWithPage({ page: query.page, q: query.q })
  const { items, meta } = campaign || {}

  const onViewClick = useCallback(
    record => {
      const routeParam: any = {
        id: record.id,
      }

      push(paths.campaignDetail({ routeParam }))
    },
    [push],
  )

  const onCreateClick = useCallback(
    record => {
      push(paths.createCampaign())
    },
    [push],
  )

  const columns = useMemo(() => {
    return [
      {
        title: 'Title',
        dataIndex: 'title',
      },
      // { title: 'Author', dataIndex: 'authorName' },
      // {
      //   title: 'Status',
      //   dataIndex: 'isActive',
      //   render: (text: string, record: { isActive: boolean }) => {
      //     const { isActive } = record
      //     return isActive ? (
      //       <Chip label="Active" color="primary" style={{ color: 'white' }} />
      //     ) : (
      //       <Chip label="Inactive" variant="outlined" color="default" />
      //     )
      //   },
      // },
      {
        title: 'route',
        dataIndex: 'route',
        render: (text: string, record: { route: string }) => {
          const { route } = record
          const pathName = Object.keys(EnumCampaignPath).find(path => {
            const value = EnumCampaignPath[path as keyof typeof EnumCampaignPath]
            return route === value
          })
          return <Text>{pathName ? pathName?.toUpperCase() : '-'}</Text>
        },
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        render: (text: string, record: { startDate: string }) => {
          const { startDate } = record
          return startDate ? (
            <Text>{dayjs(startDate).format('D MMMM  YYYY HH:mm ')}</Text>
          ) : (
            <Text>{`Not starDate yet`}</Text>
          )
        },
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        render: (text: string, record: { endDate: string }) => {
          const { endDate } = record
          return endDate ? (
            <Text>{dayjs(endDate).format('D MMMM  YYYY HH:mm ')}</Text>
          ) : (
            <Text>{`Not endDate yet`}</Text>
          )
        },
      },
      {
        title: '',
        dataIndex: 'action',
        render: (text: string, record: {}) => (
          <Button onClick={() => onViewClick(record)}>View</Button>
        ),
      },
    ]
  }, [onViewClick])

  const setQueryParam = useCallback(
    params => {
      push(paths.campaign({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )
  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  return (
    <ListPage
      topbar={
        <Layout>
          <Authorize role={ROLE.MANAGER}>
            <ButtonStyled variant="warning" onClick={onCreateClick}>
              Create
            </ButtonStyled>
          </Authorize>
        </Layout>
      }
      tableProps={{
        columns,
        data: items,
        onPageChange,
        pagination: {
          currentPage: meta?.currentPage || 0,
          itemCount: meta?.itemCount || 0,
          itemsPerPage: meta?.itemsPerPage || 0,
          totalItems: meta?.totalItems || 0,
          totalPages: meta?.totalPages || 0,
        },
      }}
      searchInputProps={{ onSearch, value: query.q, placeholder: 'Search title' }}
    />
  )
}

export default CampaignList
