import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { Content } from './Content'

export function ContentRoute({ children, component: Component, render, ...props }: RouteProps) {
  return (
    <Route {...props}>
      {(routeProps: any) => {
        if (typeof children === 'function') {
          return <Content>{children(routeProps)}</Content>
        }

        if (!routeProps.match) {
          return null
        }

        if (children) {
          return <Content>{children}</Content>
        }

        if (Component) {
          return <Content>{React.createElement(Component as any, routeProps)}</Content>
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>
        }

        return null
      }}
    </Route>
  )
}
