import dayjs from 'dayjs'
import { useQuery, UseQueryOptions } from 'react-query'
import { savvyGoldClient } from '../../../utils/api/savvy-gold-client'
import { GetSellPriceResponse } from './sell-typed'

export const BASE_SELL_URL = `internal/sell`
export const GET_SELL_PRICE_URL = `${BASE_SELL_URL}/sell-prices`

export const useGetSellPrice = (options?: UseQueryOptions<GetSellPriceResponse>) => {
  return useQuery<GetSellPriceResponse>(
    [GET_SELL_PRICE_URL],
    async () => {
      const { data: res } = await savvyGoldClient.get(GET_SELL_PRICE_URL)
      const data = res.data as GetSellPriceResponse
      return { ...data, updatedAt: dayjs() }
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      ...options,
    },
  )
}
