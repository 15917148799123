import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { FormControlLabel, Modal, TextField, TextFieldProps, makeStyles } from '@material-ui/core'
import Button from '../../../components/common/Button'
import { Form, Field } from 'react-final-form'
import ConfirmCard from '../../../components/common/Card'
import { useRouter } from '../../../../utils/helper'
import { InputField, CheckBoxField, DatePickerField } from '../../../components/fields'
import { createValidation, required, maxLength, cp, when } from '../../../../utils/field-validation'
import Toast from '../../../components/common/Toast'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import Text from '../../../components/common/Text'

import { useCurrentUser } from '../../../services/user/user-query'

import { useVisible } from '../../../../utils/custom-hooks'
import * as paths from '../../../constants/path'
import mobileFrameImg from '../../../../asset/images/mobile_frame.png'
import bannerIcon from '../../../../asset/images/bannerIcon.png'
import { Authorize, validateCorrectRole } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import Quill from 'quill'
import BlotFormatter from 'quill-blot-formatter'
import ImageCompress from 'quill-image-compress'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'

import {
  useCreateBanner,
  useDeleteBanner,
  useEditBanner,
  useGetBannerById,
} from '../../../services/banner/banner-query'
import { CreateBannerBody } from '../../../services/banner/banner-type'
import dayjs from 'dayjs'

import { isEmpty } from 'lodash'

// Register Quill modules
Quill.register('modules/blotFormatter', BlotFormatter)
Quill.register('modules/imageCompress', ImageCompress)

const CustomCard = styled(ConfirmCard)`
  width: 100%;
  max-width: 1000px;
`

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`

const MarginTopButton = styled.div`
  margin-top: 86px;
  margin-left: 24px;
`

const ButtonStyled = styled(Button)`
  padding: 7px 13px;
`

const BannerWrapper = styled.div`
  background-color: #e0f7fa; /* Light blue background */
  border-radius: 8px; /* Rounded corners */
  padding: 8px 16px; /* Padding inside the notification */
  display: flex;
  justify-content: space-between;
  width: 70%;

  height: fit-content;
  min-height: 160px;
  margin-top: 180px;

  border: 1px solid #b3e5fc; /* Light border */
  word-wrap: break-word;
  overflow: hidden;
`

const BannerContent = styled.div`
  display: flex;
  margin-top: 8px;

  justify-content: space-between;
  word-break: break-word;
  text-align: left;
`

const BannerText = styled.div`
  margin-left: 12px; /* Space between icon and text */
  font-size: 16px; /* Text size */

  color: #333; /* Text color */
`

const CloseButton = styled.button`
  background: none;
  border: none;
  height: 16px;
  font-size: 28px;
  cursor: pointer;
  color: #999;
  margin-left: 4px;
  margin-top: -8px;

  &:hover {
    color: #000;
  }
`

const IPhoneFrame = styled.div`
  background-image: url(${mobileFrameImg});
  background-repeat: no-repeat;
  background-size: 1080px;
  background-position-x: center;
  width: 500px;
  height: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -78px;
`

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

type FormValue = {
  title: string
  description: string
  isActive: boolean
  publishDate: string
  endDate: string
}

const validate = createValidation<FormValue>(values => ({
  title: cp(required('required title'), maxLength(50, 'Maximum length required 50')),
  publishDate: cp(required('required publish date')),
  endDate: cp(
    required('required end date'),
    when(
      () => {
        return dayjs(values.endDate) < dayjs(values.publishDate)
      },
      () => 'End date must greater than publish date',
    ),
  ),
}))

const BannerDetail = () => {
  const history = useRouter()
  const { query } = useRouter()
  const { data: currentUser } = useCurrentUser()
  const bannerId = useMemo(() => query.id, [query.id])
  const { data: bannerData } = useGetBannerById(bannerId)
  const [isViewMode, setIsViewMode] = useState(false)
  const [bodyValue, setBodyValue] = useState<string>('')
  const [isEmptyBody, setIsEmptyBody] = useState<boolean>(true)
  const [isExceedingLength, setIsExceedingLength] = useState<boolean>(false)
  const onConfirmModalVisible = useVisible()
  const onDeleteModalVisible = useVisible()
  const classes = useStyles()
  const maxDescriptionLength = 150 // Set your maximum length here

  const handleOpenConfirmModal = useCallback(() => {
    onConfirmModalVisible.open()
  }, [onConfirmModalVisible])

  const handleCloseConfirmModal = useCallback(() => {
    onConfirmModalVisible.close()
  }, [onConfirmModalVisible])

  const handleOpenDeleteModal = useCallback(() => {
    onDeleteModalVisible.open()
  }, [onDeleteModalVisible])

  const handleCloseDeleteModal = useCallback(() => {
    onDeleteModalVisible.close()
  }, [onDeleteModalVisible])

  const modules = useMemo(() => {
    return {
      toolbar: [
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ['link'], // Include 'image' in the toolbar options
        // Add other modules as needed
      ],
      blotFormatter: {},
    }
  }, [])

  useEffect(() => {
    if (isEmpty(bodyValue) || bodyValue === '<p><br></p>') {
      setIsEmptyBody(true)
    } else {
      setIsEmptyBody(false)
    }
  }, [bodyValue])

  useEffect(() => {
    if (isViewMode) {
      setBodyValue(bannerData?.description || '')
    }
  }, [bannerData, isViewMode])

  useEffect(() => {
    if (bannerData?.id || !validateCorrectRole(ROLE.MANAGER, currentUser?.role)) {
      setIsViewMode(true)
    }
  }, [bannerData, currentUser])

  const initialValues = useMemo(() => {
    return query.id
      ? {
          title: bannerData?.title,
          publishDate: dayjs(bannerData?.publishDate).format('YYYY-MM-DDTHH:mm'),
          endDate: dayjs(bannerData?.endDate).format('YYYY-MM-DDTHH:mm'),
          isActive: bannerData?.isActive,
          description: bannerData?.description,
        }
      : {
          isActive: false,
        }
  }, [bannerData, query.id])

  const { mutate: updateBanner } = useEditBanner(bannerData?.id || '')
  const { mutate: createBanner } = useCreateBanner()
  const { mutate: deleteBanner } = useDeleteBanner(bannerData?.id || '')

  const onGoBackToBanner = useCallback(() => {
    history.push(paths.banner())
  }, [history])

  const onSubmit = useCallback(
    values => {
      const { title, publishDate, isActive, endDate } = values
      const params: CreateBannerBody = {
        title,

        isActive,
        description: bodyValue,
        publishDate: dayjs(publishDate).toISOString(),
        endDate: dayjs(endDate).toISOString(),
        userId: currentUser?.id || '',
      }

      if (query.id) {
        updateBanner(params, {
          onSuccess: () => {
            Toast.info('Success')
            handleCloseConfirmModal()
            onGoBackToBanner()
          },
          onError: error => {
            Toast.info('อัพเดทผิดพลาด')
            handleCloseConfirmModal()
          },
        })
      } else {
        createBanner(params, {
          onSuccess: error => {
            Toast.info('Success')
            handleCloseConfirmModal()
            onGoBackToBanner()
          },
          onError: error => {
            Toast.info('สร้างผิดพลาด')
            handleCloseConfirmModal()
          },
        })
      }
    },
    [
      bodyValue,
      createBanner,
      currentUser,
      handleCloseConfirmModal,
      onGoBackToBanner,
      query.id,
      updateBanner,
    ],
  )

  const onClickEdit = useCallback(() => {
    setIsViewMode(false)
  }, [])

  const onClickDelete = useCallback(() => {
    deleteBanner()
    onGoBackToBanner()
  }, [deleteBanner, onGoBackToBanner])

  return (
    <>
      <Authorize role={ROLE.MANAGER}>
        {query.id && (
          <div
            style={{
              marginBottom: '16px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <ButtonStyled
              variant="warning"
              onClick={onClickEdit}
              style={{
                marginRight: '16px',
              }}
            >
              Edit
            </ButtonStyled>
            <ButtonStyled variant="danger" onClick={handleOpenDeleteModal}>
              Delete
            </ButtonStyled>
          </div>
        )}
      </Authorize>
      <CustomCard>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, invalid }) => {
            return (
              <>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                    <ContentLayout>
                      <div style={{ paddingBottom: '10px' }}>
                        <Text>Title</Text>
                        <Field
                          name="title"
                          component={InputField}
                          placeholder="Title"
                          id="title"
                          disabled={isViewMode}
                          style={{ width: '100%' }}
                        />
                      </div>

                      <div style={{ paddingBottom: '10px' }}>
                        <Text>Publish Date</Text>
                        <Field
                          name="publishDate"
                          component={DatePickerField}
                          id="publishDate"
                          label=""
                          type="datetime-local"
                          disabled={isViewMode}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div style={{ paddingBottom: '10px' }}>
                        <Text>End Date</Text>
                        <Field
                          name="endDate"
                          component={DatePickerField}
                          id="endDate"
                          label=""
                          type="datetime-local"
                          disabled={isViewMode}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <FormControlLabel
                        control={
                          <Field name="isActive" component={CheckBoxField} disabled={isViewMode} />
                        }
                        label="Active"
                      />
                      <div style={{ paddingBottom: '10px' }}>
                        {!isViewMode && (
                          <>
                            <Text>Description</Text>

                            <ReactQuill
                              theme="snow"
                              value={bodyValue}
                              onChange={value => {
                                if (value.length < maxDescriptionLength) {
                                  setBodyValue(value)
                                  setIsExceedingLength(false)
                                } else {
                                  setIsExceedingLength(true)
                                }
                              }}
                              modules={modules}
                              style={{
                                width: '100%',
                                maxWidth: '500px',
                                height: '110px',
                                maxHeight: '110px',
                              }}
                              placeholder="Write your banner..."
                            />
                            {isExceedingLength && (
                              <Text style={{ color: 'red', marginTop: '64px' }}>
                                The description exceeds the maximum length.
                              </Text>
                            )}
                          </>
                        )}
                      </div>
                    </ContentLayout>

                    <Authorize role={ROLE.MANAGER}>
                      <MarginTopButton style={{ marginTop: isExceedingLength ? '0px' : '' }}>
                        <Button
                          onClick={handleOpenConfirmModal}
                          disabled={invalid || isViewMode || isEmptyBody || isExceedingLength}
                        >
                          Submit
                        </Button>
                      </MarginTopButton>
                    </Authorize>
                  </div>

                  <IPhoneFrame>
                    <BannerWrapper>
                      <BannerContent>
                        <img
                          alt=""
                          src={bannerIcon}
                          style={{ height: '32px', width: '32px' }}
                        ></img>

                        <BannerText
                          dangerouslySetInnerHTML={{
                            __html: bodyValue,
                          }}
                        ></BannerText>
                      </BannerContent>
                      <CloseButton>×</CloseButton>
                    </BannerWrapper>
                  </IPhoneFrame>
                </div>

                <Modal
                  className={classes.modal}
                  open={onConfirmModalVisible.visible}
                  onClose={onConfirmModalVisible.close}
                >
                  <ConfirmCard
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '30px',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Confirm</Text>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'center',
                        paddingTop: '10px',
                      }}
                    >
                      <Button
                        style={{
                          alignSelf: 'center',
                          marginRight: '20px',
                        }}
                        onClick={handleSubmit}
                      >
                        Yes
                      </Button>
                      <Button
                        style={{
                          alignSelf: 'center',
                          backgroundColor: '#FF0000',
                          borderColor: '#FF0000',
                        }}
                        onClick={onConfirmModalVisible.close}
                      >
                        No
                      </Button>
                    </div>
                  </ConfirmCard>
                </Modal>
                <Modal
                  className={classes.modal}
                  open={onDeleteModalVisible.visible}
                  onClose={onDeleteModalVisible.close}
                >
                  <ConfirmCard
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '30px',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Confirm Delete</Text>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'center',
                        paddingTop: '10px',
                      }}
                    >
                      <Button
                        style={{
                          alignSelf: 'center',
                          marginRight: '20px',
                        }}
                        onClick={onClickDelete}
                      >
                        Yes
                      </Button>
                      <Button
                        style={{
                          alignSelf: 'center',
                          backgroundColor: '#FF0000',
                          borderColor: '#FF0000',
                        }}
                        onClick={handleCloseDeleteModal}
                      >
                        No
                      </Button>
                    </div>
                  </ConfirmCard>
                </Modal>
              </>
            )
          }}
        />
      </CustomCard>
    </>
  )
}

export default BannerDetail
