import React, { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-bootstrap/Carousel'
import { green } from '@material-ui/core/colors'
import { useGetUser } from '../services/user/user-query'
import dayjs from 'dayjs'
import Card from '../components/common/Card'
import Text from '../components/common/Text'
import { useLocation } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  maindetail: {
    display: 'flex',
    flexDirection: 'column',

    width: '100%',
    maxWidth: 1000,
  },
  PortandTransactionandKYC: {
    padding: '10px',
    width: '100%',
    marginBlockStart: 10,
  },
  imageprofile: {
    padding: 10,
  },
  columUnderphoto: {
    padding: 10,
    width: '80%',
  },
  OtherDetail: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    width: '100%',
  },

  bigAvatar: {
    padding: 0,
    margin: 0,
    width: '280px',
    height: '280px',
  },
  bigAvatarSlide: {
    padding: 0,
    margin: 0,
    width: '100%',
    height: '500px',
  },
  avatar: {
    padding: 10,
    margin: 10,
  },
  columnPort: {
    width: '100%',
    padding: '10px',
    height: '300px',
  },
  columProfile: {
    width: '50%',
    padding: '0px',
    margin: 0,
    height: '300px',
  },
  columLastTransaction: {
    width: '100%',
    padding: '10px',
    height: '300px',
  },
  columKYC: {
    width: '100%',
    padding: '10px',
    height: '300px',
  },
  columnDetailsidephoto: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    height: '300px',
    marginLeft: '10px',
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
  },
  gridPortLastTransactionKYC: {
    display: 'grid',
    gridTemplateColumns: '505px auto',
  },
  gridheaderport: {
    display: 'grid',
    gridTemplateColumns: '50px auto',
  },
  gridheaderKYC: {
    display: 'grid',
    gridTemplateColumns: '130px auto',
  },
  gridheaderBank: {
    display: 'grid',
    gridTemplateColumns: '240px auto',
  },
  gridheaderLastTransaction: {
    display: 'grid',
    gridTemplateColumns: '170px auto',
  },
  gridcolum1: {
    display: 'grid',
    gridTemplateColumns: 'auto',
  },
  gridcolum2: {
    display: 'grid',
    gridTemplateColumns: '170px auto',
  },
  gridcolum2detail: {
    display: 'grid',
    gridColumnGap: '0px',
    gridTemplateColumns: '40px auto',
  },
  gridcolum3: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },
  gridphoto: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    margin: '10px',
    padding: '10px',
  },
  flexphotoanddetail: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexrow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    margin: '5px',
  },
  gridPhoto: {
    display: 'flex',
    flexDirection: 'row',
    justify: 'Left',
    alignItems: 'Left',
    margin: '10',
  },
  marginBlockStart: {
    marginBlockStart: '10px',
  },
}))
const ImageArray = [0, 1, 2]
export const UserprofilePage = () => {
  const location = useLocation()
  const userId = useMemo(() => {
    return location.pathname.split('/')[2]
  }, [location])

  const { data: userRes } = useGetUser(userId)

  const classes = useStyles()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [currentCoroselIndex, setCurrentCoroselIndex] = useState()
  const handleShow = (id: any) => {
    setShow(true)
    setCurrentCoroselIndex(id)
  }
  const onCoroselChange = (index: any) => {
    setCurrentCoroselIndex(index)
  }

  return (
    <div className={classes.maindetail}>
      <div>
        <Text variant="h4">User Profile</Text>
        <Card style={{ width: 1000 }}>
          <div className={classes.flexphotoanddetail}>
            <Avatar
              variant="rounded"
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              className={classes.bigAvatar}
            />

            <div className={classes.columnDetailsidephoto}>
              <div className={classes.gridcolum1}>
                <Text variant="h3" bold>
                  {userRes?.firstnameEn} {userRes?.lastnameEn}
                </Text>
                <Text variant="h3" bold>
                  {userRes?.firstnameTh} {userRes?.lastnameTh}
                </Text>
              </div>
              <div className={classes.gridcolum2}>
                <Text variant="h6">Citizen Id</Text>

                <Text variant="h5" bold>
                  {userRes?.citizenId}
                </Text>

                <Text variant="h6">Gender</Text>

                <Text variant="h5" bold>
                  {userRes?.gender}
                </Text>

                <Text variant="h6"> Mobile No</Text>

                <Text variant="h5" bold>
                  {userRes?.mobileNo}
                </Text>

                <Text variant="h6"> Email</Text>

                <Text variant="h5" bold>
                  {userRes?.email}
                </Text>
              </div>
            </div>
          </div>
          <div className={classes.flexrow}>
            <div className={(classes.OtherDetail, classes.gridcolum2)}>
              <Text variant="h6"> Job</Text>

              <Text variant="h5" bold>
                {userRes?.job}
              </Text>

              <Text variant="h6">Birth Date</Text>

              <Text variant="h5" bold>
                {dayjs(userRes?.birthdate).format('DD MMM')}{' '}
                {dayjs(userRes?.birthdate).format(`YYYY`)}
              </Text>

              <Text variant="h6">Convenent</Text>

              <Text variant="h5" bold>
                {userRes?.callbackConvenientTime}
              </Text>

              <Text variant="h6">Callback Number</Text>

              <Text variant="h5" bold>
                {userRes?.callbackMobileNo}
              </Text>

              <Text variant="h6">Attachment</Text>
              <div>
                <Grid className={classes.gridPhoto}>
                  {ImageArray.map(id => (
                    <span key={id} onClick={() => handleShow(id)}>
                      <Avatar
                        variant="rounded"
                        alt="First"
                        src="/static/images/avatar/1.jpg"
                        className={classes.avatar}
                      />
                    </span>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className={classes.marginBlockStart}>
        <div className={(classes.PortandTransactionandKYC, classes.gridPortLastTransactionKYC)}>
          <Card style={{ width: 492.5 }}>
            <div className={classes.columnPort}>
              <div className={classes.gridheaderport}>
                <Text variant="h5">Port</Text>
                <Text variant="h5" style={{ color: '#00BFFF' }}>
                  (Link view more)
                </Text>
              </div>
              <div className={classes.gridcolum2}>
                <Text variant="h6">Balance</Text>

                <Text variant="h5" bold>
                  12,342 (+300)
                </Text>

                <Text variant="h6">Default Address </Text>

                <Text variant="h5" bold>
                  {userRes?.addressCitizen}
                </Text>

                <Text variant="h6">DCA date </Text>

                <Text variant="h5" bold>
                  every 25 of mounth
                </Text>
              </div>
            </div>
          </Card>

          <Card style={{ width: 492.5 }}>
            <div className={classes.columLastTransaction}>
              <div className={classes.gridheaderLastTransaction}>
                <Text variant="h5">Last Transaction </Text>
                <Text variant="h5" style={{ color: '#00BFFF' }}>
                  (Link view more)
                </Text>
              </div>
              <div className={classes.gridcolum2}>
                <Text variant="h6">Type </Text>
                <div className={classes.gridcolum2detail}>
                  <Text variant="h5" style={{ color: '#00FF00' }}>
                    Buy
                  </Text>
                  <Text variant="h5">(Pending)</Text>
                </div>
                <Text variant="h6">Rate </Text>
                <Text variant="h5" bold>
                  0.334 per gram
                </Text>
                <Text variant="h6">Gold amount </Text>
                <Text variant="h5" bold>
                  50 gram
                </Text>
                <Text variant="h6">Price </Text>
                <Text variant="h5" bold>
                  100 bath
                </Text>
                <Text variant="h6">Bank Account </Text>
                <Text variant="h5" bold>
                  {userRes?.bankAccount} -scb
                </Text>
              </div>
            </div>
          </Card>

          <Card style={{ width: 492.5 }}>
            <div className={classes.columKYC}>
              <div className={classes.gridheaderKYC}>
                <Text variant="h5">KYC Pending </Text>
                <Text variant="h5" style={{ color: '#00BFFF' }}>
                  (Link view more)
                </Text>
              </div>
              <div className={classes.gridcolum2}>
                <Text variant="h6">Username </Text>
                <Text variant="h5" bold>
                  {userRes?.firstnameEn}
                </Text>

                <Text variant="h6">Citizen Id </Text>
                <Text variant="h5" bold>
                  {userRes?.citizenId}
                </Text>

                <Text variant="h6">Convenient time </Text>
                <Text variant="h5" bold>
                  {userRes?.callbackConvenientTime}
                </Text>

                <Text variant="h6">Callback number </Text>
                <Text variant="h5" bold>
                  {userRes?.callbackMobileNo}
                </Text>
              </div>
            </div>
          </Card>
          <Card style={{ width: 492.5 }}>
            <div className={classes.columKYC}>
              <div className={classes.gridheaderBank}>
                <Text variant="h5">Bank Account(Pending)</Text>
                <Text variant="h5" style={{ color: '#00BFFF' }}>
                  (Link view more)
                </Text>
              </div>
              <div className={classes.gridcolum2}>
                <Text variant="h6">No.</Text>
                <Text variant="h5" bold>
                  {userRes?.firstnameEn}
                </Text>

                <Text variant="h6">bank name:</Text>
                <Text variant="h5" bold>
                  {userRes?.citizenId}
                </Text>

                <Text variant="h6">Remark</Text>
                <Text variant="h5" bold>
                  {userRes?.callbackConvenientTime}
                </Text>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Body>
          <Carousel activeIndex={currentCoroselIndex} onSelect={onCoroselChange}>
            <Carousel.Item>
              <Avatar
                variant="square"
                alt="First slide"
                src="/static/images/avatar/1.jpg"
                className={classes.bigAvatarSlide}
              />
            </Carousel.Item>
            <Carousel.Item>
              <Avatar
                variant="square"
                alt="First slide"
                src="/static/images/avatar/1.jpg"
                className={classes.bigAvatarSlide}
              />
            </Carousel.Item>
            <Carousel.Item>
              <Avatar
                variant="square"
                alt="First slide"
                src="/static/images/avatar/1.jpg"
                className={classes.bigAvatarSlide}
              />
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  )
}
