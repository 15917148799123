import React, { CSSProperties, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import LightBox from 'react-image-lightbox'
import { Image } from 'react-bootstrap'

import 'react-image-lightbox/style.css'

const ImageLayout = styled.div`
  display: flex;
  gap: 10px;
`

const ThumbImage = styled(Image)`
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
  margin-right: 10px;
`

type ImageViewerProps = {
  images: string[]
  className?: string
  style?: CSSProperties
}

const ImageViewer = (props: ImageViewerProps) => {
  const { images, ...restProps } = props

  const [visible, setVisible] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const onThumbImageClick = useCallback(index => {
    setVisible(true)
    setActiveIndex(index)
  }, [])

  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <>
      <ImageLayout {...restProps}>
        {images.map((img, index) => (
          <ThumbImage
            key={`${index}`}
            className={`thumb-image`}
            src={img}
            onClick={onThumbImageClick.bind(null, index)}
            rounded
          />
        ))}
      </ImageLayout>
      {visible && (
        <LightBox
          mainSrc={images[activeIndex]}
          nextSrc={images[(activeIndex + 1) % images.length]}
          prevSrc={images[(activeIndex + images.length - 1) % images.length]}
          onCloseRequest={onClose}
          onMovePrevRequest={() =>
            setActiveIndex((activeIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setActiveIndex((activeIndex + 1) % images.length)}
        />
      )}
    </>
  )
}
export default ImageViewer
