/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useCallback } from 'react'
import SVG from 'react-inlinesvg'
import { useHistory } from 'react-router-dom'
import { useLogout } from '../../../../../app/services/auth/auth-query'
import { toAbsoluteUrl } from '../../../../_helpers'
import { useCurrentUser, useGetImageUser } from '../../../../../app/services/user/user-query'
import ImageViewer from '../../../../../app/components/common/ImageViewer'
import profile from '../../../../../asset/images/default_profile_picture.jpg'

export function QuickUser() {
  const { mutate: logout } = useLogout()

  const logoutClick = useCallback(() => {
    logout()
  }, [logout])

  const { data } = useCurrentUser()
  const { firstnameTh, lastnameTh, email, imageProfile } = data || {}
  const { id: imageUserProfileId = '' } = imageProfile || {}
  const { data: imageUSerProfile } = useGetImageUser(imageUserProfileId, 'origin')
  const { image } = imageUSerProfile || { image: 'noImage' }

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          User Profile
          {/*  <small className="text-muted font-size-sm ml-2">12 messages</small> */}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div>
            {image === 'noImage' ? (
              <ImageViewer images={[profile]} />
            ) : (
              <ImageViewer images={[image]} />
            )}
          </div>
          <i className="symbol-badge bg-success" />

          <div className="d-flex flex-column">
            <a href="#" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
              {firstnameTh} {lastnameTh}
            </a>

            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl('/media/svg/icons/Communication/Mail-notification.svg')}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">{email}</span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>
              Sign out
            </button>
          </div>
        </div>

        <div className="separator separator-dashed my-7"></div>
      </div>
    </div>
  )
}
