import React, { useCallback, useMemo } from 'react'

import PortalCard from '../../../components/templates/PortalCard'

import { useGetPort } from '../../../services/port/port-query'
import { useGetTransaction } from '../../../services/transaction/transaction-query'
import { addresstype } from '../../../services/transaction/transaction-typed'
import { CardMedia } from '@material-ui/core'
import { first } from 'lodash'

interface UserIdProps {
  transactionIdAPI: string
  attachment?: string
}

export const DeliveryCard = (props: UserIdProps) => {
  const { transactionIdAPI, attachment } = props
  const { data: transaction } = useGetTransaction(transactionIdAPI)
  const transactionAddress = first(transaction?.deliveryDetails)?.address
  const { portId = '' } = transaction || {}
  const { data: port } = useGetPort(portId)
  const { user } = port || {}
  const { firstnameTh: firstNameTh = ' ', lastnameTh: lastNameTh = '' } = user || {}

  const {
    patternName = '',
    deliveryStatus,
    mintingCost = '',
    vat = 0,
    deliveryCost = 0,
    amount = 0,
  } = first(transaction?.deliveryDetails) || {}
  const customAddressLabel = useCallback((address: addresstype | undefined) => {
    const {
      province = '',
      district = '',
      subDistrict = '',
      detail = '',
      zipCode = '',
      houseNo = '',
    } = address || {}
    return province ? `${houseNo} ${detail} ${subDistrict} ${district} ${province} ${zipCode}` : '-'
  }, [])

  /*
   PACKAGE_PENDING = 1,
  PACKED = 2,
  DISPATCHED = 3,
  DELIVERED = 4,
  FAILED = 5,
  */
  const getStatusDelivery = useMemo(() => {
    if (deliveryStatus === 1) {
      return 'PACKAGE_PENDING'
    }
    if (deliveryStatus === 2) {
      return 'PACKED'
    }
    if (deliveryStatus === 3) {
      return 'DISPATCHED'
    }
    if (deliveryStatus === 4) {
      return 'DELIVERED'
    }
    if (deliveryStatus === 5) {
      return 'FAILED'
    } else {
      return '-'
    }
  }, [deliveryStatus])

  const dataDelivery = useMemo(
    () => [
      { label: 'Name', value: firstNameTh ? `${firstNameTh} ${lastNameTh}` : '-' },
      { label: 'Address', value: customAddressLabel(transactionAddress) },
      { label: 'Amount', value: `${amount} g.` },
      { label: 'Pattern Name', value: patternName ? patternName : '-' },
      { label: 'Minting Cost ', value: mintingCost ? mintingCost : '-' },
      { label: 'Vat', value: vat },
      { label: 'Delivery Cost', value: deliveryCost },
      { label: 'Delivery Status', value: getStatusDelivery },
      {
        label: 'Attachment',
        value: attachment ? (
          <CardMedia component="img" width="100%" image={attachment} alt="product_image" />
        ) : (
          '-'
        ),
      },
    ],
    [
      amount,
      attachment,
      customAddressLabel,
      deliveryCost,
      firstNameTh,
      getStatusDelivery,
      lastNameTh,
      mintingCost,
      patternName,
      transactionAddress,
      vat,
    ],
  )

  return <PortalCard title={'Delivery Detail'} contents={dataDelivery} />
}

export default DeliveryCard
