import { Dayjs } from 'dayjs'
import { Attachment } from '../bankaccount/bankaccount-typed'
import { Address } from '../port/port-typed'

export type RiskAssessment = {
  answer: true
  createdAt: string
  deletedAt: string | null | undefined
  description: string
  id: string
  kycId:string
  questionCode: string
  updatedAt: string
}

export type Kyc = {
  birthdate: string
  callbackConvenientTime: CallbackConvenientTime[] | null
  attachments: Attachment[]
  callbackMobileNo: string
  citizenId: string
  createdAt: string
  deletedAt: string
  ekycStatus: string
  ekycStatusAt: string
  ekycUserId: string
  email: string
  firstnameEn: string
  firstnameTh: string
  gender: string
  id: string
  idempotencyKey: string
  job: string
  laserCode: string
  lastnameEn: string
  lastnameTh: string
  mobileNo: string
  refCode: string
  registerError?: string
  rejectReason?: string
  tinNo: string
  titleEn: string
  titleTh: string
  updatedAt: string
  userId: string
  dopa: {
    isError: 'true' | 'false'
    errorMessage: any[]
    code: string
    desc: string
  }
  amlo: {
    status: 'not pass' | 'pass'
    data: string
  }
  addresses?: Address[]
  riskAssessments:RiskAssessment[]
}

export type CallbackConvenientTime = {
  contactEnd: string
  contactStart: string
}

export type PageMeta = {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
}
export type KycList = {
  items: Kyc[]
  meta: PageMeta
}

export type ExportKycReportParams = {
  startDate: Dayjs
  endDate: Dayjs
  status?: EKycState
}

export enum EKycState {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
