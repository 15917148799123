import { useQuery, useQueryClient, useMutation } from 'react-query'
import { ArticleBodyType, ArticleListResponseType, ArticleResponseType } from './article-type'
import { api } from '../../../utils/api'

export const BASE_ARTICLE_URL = 'internal/articles'

export const useGetAllArticle = (params?: { q?: string; page?: number }) => {
  return useQuery(
    [BASE_ARTICLE_URL, params],
    async () => {
      const response = await api.savvyGold.get<ArticleListResponseType>(BASE_ARTICLE_URL, {
        limit: 10,
        page: params?.page,
        q: params?.q,
      })

      return response.data.data
    },

    {
      keepPreviousData: true,
    },
  )
}

export const useCreateArticle = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: ArticleBodyType) => {
      const { data } = await api.savvyGold.post(`${BASE_ARTICLE_URL}`, params)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_ARTICLE_URL])
      },
    },
  )
}

export const useGetArticleById = (articleId: string) => {
  return useQuery(
    [BASE_ARTICLE_URL, articleId],
    async () => {
      const response = await api.savvyGold.get<ArticleResponseType>(
        `${BASE_ARTICLE_URL}/${articleId}`,
      )
      return response.data.data
    },
    {
      enabled: !!articleId,
    },
  )
}

export const useEditArticle = (articleId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: ArticleBodyType) => {
      const { data } = await api.savvyGold.patch(`${BASE_ARTICLE_URL}/${articleId}`, params)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_ARTICLE_URL])
      },
    },
  )
}

export const useDeleteArticle = (articleId: string) => {
  const queryClient = useQueryClient()
  return useMutation(
    [BASE_ARTICLE_URL],
    async () => {
      const { data } = await api.savvyGold.delete(`${BASE_ARTICLE_URL}/${articleId}`)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_ARTICLE_URL])
      },
    },
  )
}
