import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import ReactSelect, { Props, OnChangeValue } from 'react-select'
import Text from './Text'

const Layout = styled.div`
  display: flex;
  flex-flow: column;
`

export type SelectProps<OptionType extends BaseOptionType, IsMulti extends boolean = false> = {
  label?: string
  onChange?: (value: OnChangeValue<OptionType, IsMulti>) => void
  disabled?: boolean
  className?: string
} & Partial<Omit<Props<OptionType, IsMulti>, 'isDisabled' | 'isMulti' | 'onChange'>>

const Select = <OptionType extends BaseOptionType, IsMulti extends boolean = false>(
  props: SelectProps<OptionType, IsMulti>,
) => {
  const { label, value, onChange, disabled = false, ...restProps } = props

  const handleChange = useCallback(
    (value: OnChangeValue<OptionType, IsMulti>) => {
      onChange?.(value)
    },
    [onChange],
  )

  return (
    <Layout>
      {label && <Text>{label}</Text>}
      <ReactSelect
        value={value}
        onChange={handleChange}
        isDisabled={disabled}
        {...restProps}
        menuPosition={'fixed'}
        isOptionDisabled={e => !!e.disabled}
      />
    </Layout>
  )
}

export default Select
