import React, { ElementType } from 'react'
import styled from '@emotion/styled'

const ErrorMessage = styled.div`
  margin-top: 4px;
  color: red;
`

export const makeField = <T extends any = any>(Components: ElementType) => (props: T) => {
  /* @ts-ignore */
  const { input, meta, ...restProps } = props
  return (
    <div>
      <Components {...input} {...restProps} />
      {meta.error && meta.touched && (
        <div className="fv-plugins-message-container">
          <ErrorMessage>{meta.error}</ErrorMessage>
        </div>
      )}
    </div>
  )
}
