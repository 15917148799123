/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Routes } from '../app/Routes'
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import './initialize'
import Toast from './components/common/Toast'
import i18n from '../utils/i18n'
import { I18nextProvider } from 'react-i18next'
import { AppHelmet } from './AppHelmet'

type AppProps = {
  store: any
  persistor: any
  basename: any
}

export const queryCache = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 5 * 1000,
      staleTime: 10 * 1000,
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
})

export default function App({ store, persistor, basename }: AppProps) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <AppHelmet />
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <QueryClientProvider client={queryCache}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nextProvider i18n={i18n}>
                  {/* Render routes with provided `Layout`. */}
                  <Routes />
                  <Toast />
                </I18nextProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  )
}
