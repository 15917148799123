import React, { useCallback, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { FormControlLabel, TextField } from '@material-ui/core'
import Button from '../../../components/common/Button'
import { Form, Field } from 'react-final-form'
import {
  useCreateNotification,
  useGetNotificationDetail,
  useGetUserOptions,
  useUpdateNotification,
} from '../../../services/notification/notification-query'
import Card from '../../../components/common/Card'
import { useRouter } from '../../../../utils/helper'
import { DatePickerField, InputField, SelectField, CheckBoxField } from '../../../components/fields'
import { createValidation, required, maxLength, cp, when } from '../../../../utils/field-validation'
import Toast from '../../../components/common/Toast'
import { debounce } from 'lodash'

import Text from '../../../components/common/Text'
import dayjs from 'dayjs'

const ContentLayout = styled.div`
  display: flex;
  flex-direction: row;
`
const CustomCard = styled(Card)`
  width: 100%;
  max-width: 1000px;
`
const FlexChild = styled.div`
  flex: 1;
  padding: 24px;
`
const FlexChildCenter = styled.div`
  flex: 1;
  padding: 24px;
  text-align: center;
`
const AndroidPreview = styled.div`
  width: 600px;
  height: 200px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-image: url('https://www.gstatic.com/mobilesdk/190403_mobilesdk/android.png');
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 24px;
`
const IOSPreview = styled.div`
  width: 600px;
  height: 200px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-image: url('https://www.gstatic.com/mobilesdk/190403_mobilesdk/iphone.png');
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 16px;
`
const NotiPreviewCard = styled(Card)`
  width: 58%;
  height: 50%;
  align-self: center;
  padding: 8px;
  .card-body {
    padding: 0;
  }
`
const NotiText = styled.div`
  text-align: left;
  word-break: break-all;
`
export const SentTime = styled(TextField)`
  margin-bottom: 16px;
`
const UserSelect = styled(SelectField)`
  width: 250px;
  margin: 16px 0 16px 0;
`

const MargintopButton = styled.div`
  margin-top: 20px;
  text-align: left;
`

type FormValue = { title: string; description: string; sentDate: string; isAllUser: boolean }

const validate = createValidation<FormValue>(values => ({
  title: cp(required('required title'), maxLength(100, 'Maximum length required 100')),
  description: cp(required('required description'), maxLength(100, 'Maximum length required 100')),
  sentDate: cp(
    required('required send date'),
    when(
      () => {
        return dayjs().isAfter(dayjs(values.sentDate))
      },
      () => 'The sending time must be greater than the current time.',
    ),
  ),
  users: when(() => !values.isAllUser, required('required user send to')),
}))

const Notification = () => {
  const [q, setQ] = useState('')
  const { query } = useRouter()

  const { data: notiDetailData } = useGetNotificationDetail({
    notificationId: query.notificationId,
  })

  const initialValues = useMemo(() => {
    const tempData = notiDetailData
    const { users = [] } = tempData || {}

    return {
      ...tempData,
      isAllUser: tempData?.userType === 'everyone',
      sentDate: tempData?.sentDate.toDayjs().format('YYYY-MM-DDTHH:mm'),
      users: users.map(({ id, email }) => ({ value: id, label: email })),
      isSend: tempData?.isSend,
    }
  }, [notiDetailData])

  const { data: usersResponse } = useGetUserOptions(q)
  const userOptions = useMemo(() => {
    return q && usersResponse
      ? usersResponse.items.map(item => ({ label: item.email, value: item.id }))
      : []
  }, [usersResponse, q])

  const makeCalllbackWithDebounce = (callback: (...args: any) => any, wait: number = 300) => {
    return debounce(callback, wait, { leading: true, trailing: false })
  }

  const onUserInputChange = useMemo(() => {
    return makeCalllbackWithDebounce(value => {
      setQ(value)
    }, 300)
  }, [])

  const { mutate: updateNotiDetail } = useUpdateNotification()
  const { mutate: createNotification } = useCreateNotification()

  const onSubmit = useCallback(
    values => {
      const { title, description, sentDate, users, isAllUser } = values
      Toast.info('Submit Success')

      const params = {
        title,
        description,
        sentDate,
        userType: isAllUser ? 'everyone' : 'individual',
        users: isAllUser
          ? []
          : users.map((option: { value: string; label: string }) => option.value),
        type: 'buy',
      }

      if (query.notificationId) {
        updateNotiDetail({ params, notificationId: query.notificationId })
      } else {
        createNotification({ params })
      }
    },
    [createNotification, query.notificationId, updateNotiDetail],
  )

  return (
    <>
      <CustomCard>
        <Form
          initialValues={initialValues}
          subscription={{ submitting: true, pristine: true, values: true }}
          onSubmit={onSubmit}
          validate={validate}
          render={({
            values = {
              title: '',
              description: '',
              sentDate: new Date().toDayjs().format('YYYY-MM-DDTHH:mm'),
              isAllUser: false,
            },
            handleSubmit,
            form,
          }) => {
            values.isAllUser === true && form.change('users', '')
            return (
              <form onSubmit={handleSubmit}>
                <ContentLayout>
                  <FlexChild>
                    <div style={{ paddingBottom: '10px' }}>
                      <Text>Title</Text>
                      <Field
                        name="title"
                        component={InputField}
                        placeholder="Title"
                        id="title"
                        disabled={initialValues.isSend}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div style={{ paddingBottom: '10px' }}>
                      <Text>Description</Text>
                      <Field
                        name="description"
                        component={InputField}
                        id="description"
                        placeholder="Description"
                        disabled={initialValues.isSend}
                        multiline
                        rowsMax="4"
                        style={{ width: '100%' }}
                      />
                    </div>
                    <Text>Users</Text>
                    <UserSelect
                      name="users"
                      id="react-select-multiple"
                      options={userOptions}
                      onInputChange={onUserInputChange}
                      isMulti
                      placeholder="Select Users"
                      disabled={values.isAllUser || initialValues.isSend}
                      style={{ width: '100%' }}
                    />

                    <FormControlLabel
                      control={
                        <Field
                          name="isAllUser"
                          component={CheckBoxField}
                          disabled={initialValues.isSend}
                        />
                      }
                      label="send to all user?"
                    />
                    <Text>Send Time *</Text>
                    <Field
                      name="sentDate"
                      component={DatePickerField}
                      id="date"
                      label=""
                      type="datetime-local"
                      disabled={initialValues.isSend} //รอเช็คของจาก api
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    ></Field>

                    <MargintopButton>
                      <Button type="submit" disabled={initialValues.isSend}>
                        Submit
                      </Button>
                    </MargintopButton>
                    <Text
                      style={{
                        paddingTop: '25px',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                      }}
                    >
                      *** The sending time must be three minutes greater than the current time. ***
                    </Text>
                  </FlexChild>

                  <FlexChildCenter>
                    <AndroidPreview>
                      <NotiPreviewCard>
                        <NotiText>{values.title}</NotiText>
                        <NotiText>{values.description}</NotiText>
                      </NotiPreviewCard>
                    </AndroidPreview>
                    <div>Android</div>
                    <IOSPreview>
                      <NotiPreviewCard>
                        <NotiText>{values.title}</NotiText>
                        <NotiText>{values.description}</NotiText>
                      </NotiPreviewCard>
                    </IOSPreview>
                    <div>iOS</div>
                  </FlexChildCenter>
                </ContentLayout>
              </form>
            )
          }}
        />
      </CustomCard>
    </>
  )
}

export default Notification
