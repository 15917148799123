import { UseMutationOptions } from 'react-query'
import { MixPanelTrackEvent } from './mix-panel-typed'
import { api } from '../../../utils/api'
import { useMutation } from 'react-query'

export const BASE_MIX_PANEL = 'mix-panel'

export const useMixPanelTrackEvent = (
  distinctId: string,
  options?: UseMutationOptions<{ data: string }, unknown, MixPanelTrackEvent>,
) => {
  return useMutation(
    [BASE_MIX_PANEL],
    async params => {
      const { data } = await api.savvyGold.post<{ data: string }>(
        `${BASE_MIX_PANEL}/${distinctId}`,
        params,
      )
      return data.data
    },
    {
      onError: () => {
        //error
      },
      ...options,
    },
  )
}
