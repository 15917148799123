import React, { useCallback, useEffect, useMemo } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import moment from 'moment'
import BaseDateRangePicker, {
  Props as BaseDateRangePickerProps,
} from 'react-bootstrap-daterangepicker'
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css'
import styled from '@emotion/styled'
import Text from './Text'
import { DateOrString } from 'daterangepicker'
import DateRangeOutlined from '@material-ui/icons/DateRangeOutlined'

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
`
const DateRangeOutlinedStyled = styled(DateRangeOutlined)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  pointer-events: none;
`

const InputStyled = styled.input`
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 2px 8px;
`

const parseToDayjs = (date?: DateOrString) => {
  let _date: Dayjs | DateOrString | undefined = date
  if (typeof _date === 'string') {
    _date = dayjs(_date)
  } else if (_date instanceof Date) {
    _date = dayjs(_date)
  } else if (_date instanceof moment) {
    _date = dayjs(_date.toDate())
  }
  return _date
}

export type DateRangePickerValue = {
  startDate: Dayjs
  endDate: Dayjs
}
export type DateRangePickerProps = {
  value?: DateRangePickerValue
  onChange?: (value: DateRangePickerValue) => void
  label: string
  option?: Partial<BaseDateRangePickerProps['initialSettings']>
}

const DateRangePicker = (props: DateRangePickerProps) => {
  const { label, value, onChange, option } = props
  const now = useMemo(() => dayjs(), [])

  const startDate = useMemo(() => {
    return value?.startDate ? dayjs(value.startDate) : now.subtract(1, 'months').startOf('months')
  }, [now, value])

  const endDate = useMemo(() => {
    return value?.endDate ? dayjs(value?.endDate) : now
  }, [now, value])

  const handleCallback: BaseDateRangePickerProps['onCallback'] = useCallback(
    (start, end, label) => {
      const _start = parseToDayjs(start)
      const _end = parseToDayjs(end)

      if (_start && _end) {
        onChange?.({
          startDate: dayjs(_start.toDate()),
          endDate: dayjs(_end.toDate()),
        })
      }
    },
    [onChange],
  )

  useEffect(() => {
    if (!value) {
      onChange?.({
        startDate: now.subtract(1, 'months').startOf('months'),
        endDate: now,
      })
    }
  }, [now, onChange, value])

  return (
    <Layout>
      <Text>{label}</Text>
      <BaseDateRangePicker
        initialSettings={{
          opens: 'center',
          startDate: startDate.format('DD/MM/YYYY'),
          endDate: endDate.format('DD/MM/YYYY'),
          alwaysShowCalendars: true,
          autoApply: true,
          locale: {
            format: 'DD/MM/YYYY',
            // separator: ' - ',
            // cancelLabel: 'Cancel',
            // fromLabel: 'From',
            // toLabel: 'To',
            // customRangeLabel: 'Custom',
            // weekLabel: 'W',
            // daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            // monthNames: [
            //   'January',
            //   'February',
            //   'March',
            //   'April',
            //   'May',
            //   'June',
            //   'July',
            //   'August',
            //   'September',
            //   'October',
            //   'November',
            //   'December',
            // ],
            // firstDay: 1,
          },
          ...option,
        }}
        onCallback={handleCallback}
      >
        <InputStyled />
      </BaseDateRangePicker>
      <DateRangeOutlinedStyled />
    </Layout>
  )
}

export default DateRangePicker
