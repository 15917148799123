import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { appSlice } from './app-reducer'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  app: appSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {}
