import React from 'react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const info = (message: string) => {
  toast(<div style={{ color: '#3f4254' }}>{message}</div>, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}
const error = (message: string) => {
  toast(<div style={{ color: 'white' }}>{message}</div>, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: 'error',
  })
}

const Toast = () => {
  return <ToastContainer />
}

Toast.info = info
Toast.error = error
export default Toast
