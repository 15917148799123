import { uniqueId } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { toast, ToastContent, ToastOptions, UpdateOptions } from 'react-toastify'

export const useVisible = () => {
  const [visible, setVisible] = useState(false)

  const open = useCallback(() => {
    setVisible(true)
  }, [])

  const close = useCallback(() => {
    setVisible(false)
  }, [])

  return {
    visible,
    open,
    close,
  }
}

export const useToastForReactQuery = (option?: ToastOptions) => {
  const [updateId] = useState<string | number>(uniqueId())

  const defaultOption = useMemo(() => {
    const _toastOption: ToastOptions = {
      position: 'top-right',
      autoClose: 2.5 * 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      ...option,
    }
    return _toastOption
  }, [option])

  const open = useCallback(
    (content?: ToastContent, option?: ToastOptions) => {
      return toast(content, {
        ...defaultOption,
        type: 'default',
        closeOnClick: false,
        autoClose: 10 * 1000,
        hideProgressBar: true,
        bodyStyle: {
          fontWeight: 'bold',
        },
        ...option,
        toastId: updateId,
        updateId,
      })
    },
    [defaultOption, updateId],
  )

  const onSuccess = useCallback(
    (toastId: string | number, content?: ToastContent, option?: UpdateOptions) => {
      toast.update(toastId, {
        ...defaultOption,
        render: content || 'Success',
        type: 'success',
        ...option,
        toastId,
        updateId,
      })
    },
    [updateId, defaultOption],
  )

  const onError = useCallback(
    (toastId: string | number, content?: ToastContent, option?: UpdateOptions) => {
      toast.update(toastId, {
        ...defaultOption,
        render: content || 'Fail',
        type: 'warning',
        ...option,
        toastId,
        updateId,
      })
    },
    [updateId, defaultOption],
  )

  return {
    open,
    onSuccess,
    onError,
  }
}
