import React from 'react'
import ListPage from '../../../components/templates/ListPage'
import ButtonViewTable from '../../../components/ButtonViewTable'
import Text from '../../../components/common/Text'
import {
  getColorStatusBankAccountText,
  replaceUnderScoreAndCapitalize,
  useRouter,
} from '../../../../utils/helper'
import { Link, useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import * as paths from '../../../constants/path'
import { useGetBankAccounts } from '../../../services/bankaccount/bankaccount-query'
import { BankAccount, BankAccountStatus } from '../../../services/bankaccount/bankaccount-typed'
import { User } from '../../../services/user/user-typed'

const column = (onClick: any) => [
  {
    title: 'Bank',
    dataIndex: 'bank',

    render: (text: string, record: { no: string; bank: string; branch: string }) => {
      const { no, bank, branch } = record
      return (
        <div>
          <Text>{no}</Text>{' '}
          <Text>
            {bank} {branch}
          </Text>{' '}
        </div>
      )
    },
  },

  {
    title: 'User',
    dataIndex: 'name',
    render: (text: string, bankaccount: { name: string; user: User }) => {
      const { name, user } = bankaccount
      const { citizenId } = user
      return (
        <div>
          <Text>{name}</Text>
          <Text>{citizenId}</Text>
        </div>
      )
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (text: string, bankaccount: { name: string; user: User }) => {
      const { user } = bankaccount
      const { email = '', id = '' } = user
      return email ? (
        <Link to={`/users/${id}`}>
          <Text>{email}</Text>
        </Link>
      ) : (
        <Text>''</Text>
      )
    },
  },
  {
    title: 'Status',
    dataIndex: 'approvedStatus',
    render: (text: string, record: { approvedStatus: BankAccountStatus }) => {
      const { approvedStatus } = record
      return (
        <Text variant="h6" style={{ color: getColorStatusBankAccountText(approvedStatus) }}>
          {replaceUnderScoreAndCapitalize(approvedStatus)}{' '}
        </Text>
      )
    },
  },
  {
    title: '',
    dataIndex: 'buttonView',
    render: (text: string, bankaccount: BankAccount) => {
      return <ButtonViewTable onClick={onClick.bind(null, bankaccount.id)} />
    },
  },
]

export const BackAccountList = () => {
  const { push, query } = useRouter()
  const { q, page } = query
  const { data: bankaccount } = useGetBankAccounts({ q, page })

  const history = useHistory()

  const setQueryParam = useCallback(
    params => {
      push(paths.bankAccounts({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )
  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )
  const onViewClick = useCallback(
    (bankAccountId: string) => {
      history.push(paths.bankAccountDetail({ routeParam: { bankAccountId } }))
    },
    [history],
  )
  return (
    <ListPage
      searchInputProps={{ onSearch, value: q }}
      tableProps={{
        columns: column(onViewClick),
        data: bankaccount?.items,
        onPageChange,
        pagination: bankaccount?.meta,
      }}
    />
  )
}
export default BackAccountList
