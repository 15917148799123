import React from 'react'
import { Field, FormRenderProps } from 'react-final-form'
import { Link } from 'react-router-dom'

import NoLabelInput from '../../../components/common/NoLabelInput'
import { createValidation, required, cp, email } from '../../../../utils/field-validation'
import { User } from '../../../services/user/user-typed'

export type ForgotPasswordFormValue = {
  email: string
}

export type ResponseForgotPassword = {
  verifyOtp: boolean
  user: User
}

export const validation = createValidation<ForgotPasswordFormValue>(value => ({
  email: cp(required('requried email'), email('Invalid email')),
}))

export default (props: FormRenderProps<any> & { errorMessage: string; title: string }) => {
  const { submitting, errorMessage, title } = props
  return (
    <>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">{title}</h3>
        <p className="text-muted font-weight-bold">
          Enter your details to change password your for account
        </p>
      </div>
      {errorMessage && (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{errorMessage}</div>
        </div>
      )}

      <div className="form-group fv-plugins-icon-container">
        <Field placeholder="Email" type="email" name="email" component={NoLabelInput} />
      </div>

      <div className="form-group d-flex flex-wrap flex-center">
        <button
          type="submit"
          disabled={submitting}
          className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
        >
          <span>Submit</span>
          {submitting && <span className="ml-3 spinner spinner-white"></span>}
        </button>

        <Link to="/auth/login">
          <button
            type="button"
            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            Cancel
          </button>
        </Link>
      </div>
    </>
  )
}
