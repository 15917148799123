import React, { useCallback } from 'react'
import Text from '../../../components/common/Text'
import styled from '@emotion/styled'
import { useCancelBankAccount } from '../../../services/bankaccount/bankaccount-query'
import ButtonBankAccount from '../../../components/ButtonApproveBankAccount'
import Toast from '../../../components/common/Toast'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { useVisible } from '../../../../utils/custom-hooks'
import { Grid } from '@material-ui/core'
import Button from '../../../components/common/Button'
import { Card } from 'react-bootstrap'

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`

const FlexButton = styled(Flex)`
  margin: 10px;
`
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

type BankAccountOnApprovedActionProps = {
  bankAccountId: string
}
const BankAccountOnApprovedAction = (props: BankAccountOnApprovedActionProps) => {
  const { bankAccountId } = props
  const classes = useStyles()
  const modalVisible = useVisible()

  const { mutate: cancelBankAccount } = useCancelBankAccount()

  const onCancelBankAccountClick = useCallback(() => {
    cancelBankAccount(bankAccountId, {
      onSuccess: () => {
        Toast.info(`Cancel Success`)
      },
      onError: () => {
        Toast.error(`Cancel Fail`)
      },
    })
    modalVisible.close()
  }, [bankAccountId, cancelBankAccount, modalVisible])

  return (
    <>
      <FlexButton>
        <ButtonBankAccount onClick={modalVisible.open} name="Cancel" variant="danger" />
      </FlexButton>
      <Modal className={classes.modal} open={modalVisible.visible} onClose={modalVisible.close}>
        <Card style={{ width: '300px', padding: '30px' }}>
          <Text style={{ alignSelf: 'center' }}>Are You Sure</Text>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              paddingTop: '10px',
            }}
          >
            <Button
              style={{
                alignSelf: 'center',
                marginRight: '20px',
              }}
              onClick={onCancelBankAccountClick}
            >
              Yes
            </Button>
            <Button
              style={{
                alignSelf: 'center',
                backgroundColor: '#FF0000',
                borderColor: '#FF0000',
              }}
              onClick={modalVisible.close}
            >
              No
            </Button>
          </div>
        </Card>
      </Modal>
    </>
  )
}

export default BankAccountOnApprovedAction
