import styled from '@emotion/styled'
import { capitalize } from 'lodash'
import React, { useMemo } from 'react'
import { getColorUserStatus } from '../../../../utils/helper'
import Text from '../../../components/common/Text'
import PortalCard from '../../../components/templates/PortalCard'
import { useGetUser } from '../../../services/user/user-query'
const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  *:nth-of-type(odd) {
    margin-right: 8px;
  }
`
interface UserIdProps {
  userId: string
}

export const UserCard = (props: UserIdProps) => {
  const { userId } = props
  const { data: user } = useGetUser(userId)
  const { firstnameTh = '', citizenId = '', mobileNo = '', gender = '', lastnameTh = '', status } =
    user || {}

  const dataUser = useMemo(
    () => [
      {
        label: 'Name',
        valueComponent: firstnameTh ? (
          <Row>
            <Text>{`${firstnameTh} ${lastnameTh}`}</Text>
            {status ? (
              <Text bold style={{ color: getColorUserStatus(status) }}>
                {`(${capitalize(status)})`}
              </Text>
            ) : null}
          </Row>
        ) : (
          '-'
        ),
      },
      { label: 'Citizen No.', value: citizenId ? citizenId : '-' },
      { label: 'Mobile No.', value: mobileNo ? mobileNo : '-' },
      { label: 'Gender', value: gender ? (gender === 'M' ? 'male' : 'female') : '-' },
    ],
    [citizenId, firstnameTh, gender, lastnameTh, mobileNo, status],
  )

  return <PortalCard title={'User'} contents={dataUser} viewMoreUrl={`/users/${userId}`} />
}

export default UserCard
