import React, { useCallback } from 'react'
import ListPage from '../../../components/templates/ListPage'
import ButtonViewTable from '../../../components/ButtonViewTable'
import UserTableColumn from '../../../components/UserTableColumn'
import { useGetUsers } from '../../../services/user/user-query'
import { useHistory } from 'react-router'
import { User } from '../../../services/user/user-typed'
import * as pahts from '../../../constants/path'
import { useRouter } from '../../../../utils/helper'
import Text from '../../../components/common/Text'
import { Link } from 'react-router-dom'

const column = (onClick: any) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (data: string, user: User) => {
        const { firstnameTh, citizenId, lastnameTh, imageProfile } = user
        const { id } = imageProfile || { id: '' }

        return (
          <UserTableColumn
            id={id}
            firstNameTh={firstnameTh}
            lastNameTh={lastnameTh}
            citizenId={citizenId}
          />
        )
      },
    },
    {
      title: 'Email',
      dataIndex: 'gender',
      render: (data: string, user: User) => {
        const { email, id } = user || {}
        return email ? (
          <Link to={`/users/${id}`}>
            <Text>{email}</Text>
          </Link>
        ) : (
          <Text>''</Text>
        )
      },
    },
    {
      title: 'Mobile No.',
      dataIndex: 'mobileNo',
      render: (text: string, user: User) => {
        const { mobileNo, id } = user || {}
        return mobileNo ? (
          <Link to={`/users/${id}`}>
            <Text>{mobileNo}</Text>
          </Link>
        ) : (
          <Text>''</Text>
        )
      },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      render: (data: string, user: User) => {
        const { gender } = user

        return <Text>{gender ? (gender === 'M' ? 'male' : 'female') : ''}</Text>
      },
    },

    {
      title: '',
      dataIndex: 'buttonView',
      render: (text: string, user: User) => {
        return <ButtonViewTable onClick={onClick.bind(null, user.id)} />
      },
    },
  ]
}

export const UserList = (props: any) => {
  const { push, query } = useRouter()
  const { data: users } = useGetUsers({ q: query.q, page: query.page })

  const history = useHistory()

  const setQueryParam = useCallback(
    params => {
      push(pahts.users({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )

  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )

  const onViewClick = useCallback(
    (userId: string) => {
      history.push(pahts.userProfile({ routeParam: { userId } }))
    },
    [history],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  return (
    <ListPage
      searchInputProps={{ onSearch, value: query.q }}
      tableProps={{
        columns: column(onViewClick),
        data: users?.items,
        onPageChange,
        pagination: users?.meta,
      }}
    />
  )
}
export default UserList
