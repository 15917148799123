import { Card } from 'react-bootstrap'
import React, { ReactNode } from 'react'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type CustomCard = {
  children: ReactNode
  style?: CSSProperties
  border?: 'light' | 'dark'
}

export default (props: CustomCard) => {
  const { children, border = 'light', ...restProps } = props

  return (
    <Card {...restProps}>
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}
