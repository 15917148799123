import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import { useToastForReactQuery, useVisible } from '../../../../utils/custom-hooks'
import Button from '../../../components/common/Button'
import DialogExportTransactionFilter, {
  ExportTransactionFilterFormValues,
} from './DialogExportTransactionFilter'
import 'react-toastify/dist/ReactToastify.css'
import { useExportTransactionReport } from '../../../services/transaction/transaction-query'
import { ExportTransactionReportParams } from '../../../services/transaction/transaction-typed'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`
const ButtonStyled = styled(Button)`
  padding: 7px 13px;
`

const ExportTransactionReportButton = () => {
  const { visible, open, close } = useVisible()

  const { mutate: exportTransactionReport } = useExportTransactionReport()
  const toast = useToastForReactQuery()

  const onExportClick = useCallback(
    (values: ExportTransactionFilterFormValues) => {
      const { filterRange, status, type } = values
      const { endDate, startDate } = filterRange || {}
      if (startDate && endDate) {
        const params: ExportTransactionReportParams = {
          startDate,
          endDate,
          status: status?.value ? status?.value : undefined,
          type: type?.value ? type?.value : undefined,
        }

        const toastId = toast.open('Exporting...')
        exportTransactionReport(params, {
          onSuccess: () => {
            toast.onSuccess(toastId)
          },
          onError: error => {
            toast.onError(toastId, error)
          },
        })
      }
      close()
    },
    [close, toast, exportTransactionReport],
  )
  const onClose = useCallback(() => {
    close()
  }, [close])

  return (
    <Layout>
      <ButtonStyled variant="warning" onClick={open}>
        Export
      </ButtonStyled>
      <DialogExportTransactionFilter visible={visible} onClose={onClose} onSubmit={onExportClick} />
    </Layout>
  )
}

export default ExportTransactionReportButton
