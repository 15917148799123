import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { default as AvatarLib } from '@material-ui/core/Avatar'
import { useGetImageUser } from '../services/user/user-query'
import ImageViewer from './common/ImageViewer'
import profile from '../../asset/images/default_profile_picture.jpg'
const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
const Flexrow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
`
const FlexName = styled(Flex)`
  gap: 10px;
`
interface UserTableColumnProps {
  id: string
  firstNameTh: string
  lastNameTh: string
  citizenId: string
}
const UserTableColumn = (props: UserTableColumnProps) => {
  const { firstNameTh, lastNameTh, citizenId, id } = props

  const { data: imageUSerProfile } = useGetImageUser(id, 'thumb')
  const { image } = imageUSerProfile || { image: 'noImage' }

  return (
    <div>
      <Flex>
        <div>
          {image === 'noImage' ? (
            <ImageViewer images={[profile]} />
          ) : (
            <ImageViewer images={[image]} />
          )}
        </div>
        <Flexrow>
          <FlexName>
            <div>{firstNameTh}</div>
            <div>{lastNameTh}</div>
          </FlexName>

          <div>{citizenId}</div>
        </Flexrow>
      </Flex>
    </div>
  )
}

export default UserTableColumn
