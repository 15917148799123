import { Checkbox, CheckboxProps as MaterailCheckboxProps } from '@material-ui/core'
import React from 'react'

type CheckboxProps = MaterailCheckboxProps & {
  value?: boolean
}

const CheckBox = (props: CheckboxProps) => {
  const { checked, value, ...rest } = props
  return <Checkbox checked={value} {...rest} />
}

export default CheckBox
