import dayjs from 'dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../../utils/api'
import { downloadFile } from '../../../utils/helper'

import { ExportKycReportParams, Kyc, KycList } from './kyc-typed'

export const KYC_URL = `internal/ekycs`
export const KYC_REPORT_URL = `${KYC_URL}/report`

export enum EnumGetKycListFilter {
  ALL = 'all',
  HIGH_RISK = 'high_risk',
  WAITING_APPROVE = 'waiting_approve',
}

export const useGetkycs = (params?: {
  q?: string
  page?: number
  filter?: EnumGetKycListFilter
}) => {
  return useQuery(
    [KYC_URL, params],
    async () => {
      const datas = await api.savvyGold.get<KycList>(KYC_URL, {
        page: params?.page,
        limit: 10,
        q: params?.q,
        filter: params?.filter,
      })

      return datas.data.data
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useGetKyc = (id: string) => {
  return useQuery(
    [KYC_URL, id],
    async () => {
      const data = await api.savvyGold.get<Kyc>(`${KYC_URL}/${id}`)
      return data.data.data
    },
    {
      enabled: !!id,
    },
  )
}

export const useExportKycReport = () => {
  return useMutation<any, string, ExportKycReportParams>(async (params: ExportKycReportParams) => {
    const { startDate, endDate, ...restParams } = params
    const res = await api.savvyGold.getFile(`${KYC_REPORT_URL}`, {
      startDate: dayjs(startDate).format(),
      endDate: dayjs(endDate).format(),
      ...restParams,
    })
    downloadFile(res)
  })
}

export const useRejectKyc = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (kycId: string) => {
      const { data } = await api.savvyGold.post(`${KYC_URL}/${kycId}/reject`)
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KYC_URL])
      },
    },
  )
}

export const useApproveKyc = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (kycId: string) => {
      const { data } = await api.savvyGold.post(`${KYC_URL}/${kycId}/approve`)
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KYC_URL])
      },
    },
  )
}
