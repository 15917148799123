import React, { useCallback, useEffect, useMemo } from 'react'
import { Card } from 'react-bootstrap'
import Button from '../common/Button'
import { Modal, Typography } from '@material-ui/core'
import { Field, Form } from 'react-final-form'
import { InputField, SelectField } from '../fields'
import {
  useCurrentUser,
  useGetUser,
  useUpdateRoleUser,
  useUpdateUserProfile,
} from '../../services/user/user-query'
import { validateEmail } from '../../../utils/helper'
import styled from '@emotion/styled'
import { ROLE } from '../../services/user/user-typed'
import Text from '../common/Text'
import { useToastForReactQuery } from '../../../utils/custom-hooks'
import { useTranslation } from 'react-i18next'
import { isEqual, some } from 'lodash'
import { EKycState } from '../../services/kyc/kyc-typed'
import { Authorize, validateCorrectRole } from '../Authorize'

const ContentLayout = styled(Card)`
  width: 500px;
  min-height: 200px;
  padding: 30px 80px 20px 80px;
  grid-row-gap: 12px;
`

const InputFieldStyled = styled(InputField)`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 0;
  }
`

type UpdateUserProfileFormValues = {
  mobileNo?: string
  email?: string
  role?: BaseOptionType<ROLE>
}

type ModalUpdateUserProfileProps = {
  userId: string
  visible: boolean
  onClose: () => void
  className: string
}

type RoleKeys = keyof typeof ROLE

const ModalUpdateUserProfile = (props: ModalUpdateUserProfileProps) => {
  const { userId, visible, onClose, className } = props

  const toast = useToastForReactQuery()
  const { t } = useTranslation()

  const { data: currentUser } = useCurrentUser()
  const { data: user } = useGetUser(userId)

  const { mutateAsync: updateRoleUser } = useUpdateRoleUser()
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile(userId)

  const canUpdateUserProfile = useMemo(() => {
    const hasKycApproved = user?.kyc.kyc ? user?.kyc.kyc.ekycStatus === EKycState.APPROVED : false
    const hasMobileNo = !!user?.mobileNo
    const hasEmail = !!user?.email
    const hasKyc = !!user?.kyc
    const currentUserIsAdmin = validateCorrectRole(ROLE.ADMIN, currentUser?.role)
    return currentUserIsAdmin && hasMobileNo && hasEmail && hasKyc && hasKycApproved
  }, [currentUser, user])

  const canChangeRole = useMemo(() => {
    const isNotSameUser = !isEqual(currentUser?.id, user?.id)
    return isNotSameUser
  }, [currentUser, user])

  const roleOptions = useMemo((): BaseOptionType<ROLE>[] => {
    return Object.keys(ROLE).reduce((acc: BaseOptionType<ROLE>[], roleKey) => {
      const value = ROLE[roleKey as RoleKeys]
      if (value !== ROLE.SUPER_ADMIN || value === user?.role) {
        acc.push({
          label: roleKey,
          value: value,
          disabled: value === ROLE.SUPER_ADMIN,
        })
      }
      return acc
    }, [])
  }, [user])

  const initialValues = useMemo(() => {
    const _initialValues: UpdateUserProfileFormValues = {
      email: user?.email,
      mobileNo: user?.mobileNo,
      role: roleOptions.find(option => option.value === user?.role),
    }
    return _initialValues
  }, [roleOptions, user])

  const onSubmit = useCallback(
    async (values: UpdateUserProfileFormValues) => {
      try {
        if (
          canUpdateUserProfile &&
          (!isEqual(values.mobileNo, user?.mobileNo) || !isEqual(values.email, user?.email))
        ) {
          await updateUserProfile(values)
        }
        if (values.role?.value && !isEqual(values.role?.value, user?.role)) {
          await updateRoleUser({
            userId,
            role: values.role?.value,
          })
        }
        toast.open('Success', {
          type: 'success',
          autoClose: 3 * 1000,
        })
      } catch (error) {
        toast.open(t(error), {
          type: 'error',
          autoClose: 3 * 1000,
        })
      } finally {
        onClose()
      }
    },
    [canUpdateUserProfile, onClose, t, toast, updateRoleUser, updateUserProfile, user, userId],
  )

  return (
    <Modal className={className} open={visible} onClose={onClose}>
      <div>
        <Form<UpdateUserProfileFormValues>
          onSubmit={onSubmit}
          initialValues={initialValues}
          subscription={{ dirty: true, submitting: true, valid: true }}
          validate={values => {
            const errors: Partial<Record<keyof UpdateUserProfileFormValues, string>> = {}
            if (canUpdateUserProfile) {
              if (!values.email) {
                errors.email = 'Required'
              }
              if (values.email && !validateEmail(values.email)) {
                errors.email = 'Email is invalid'
              }

              if (!values.mobileNo) {
                errors.mobileNo = 'Required'
              }
              if (values.mobileNo && values.mobileNo.length !== 10) {
                errors.mobileNo = 'Mobile No is invalid'
              }
            }
            return errors
          }}
        >
          {({ handleSubmit, dirty, submitting, valid }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ContentLayout>
                  <Authorize role={ROLE.ADMIN}>
                    <SelectField
                      name="role"
                      label="Role"
                      placeholder="Select Role"
                      options={roleOptions}
                      disabled={!canChangeRole}
                    />
                  </Authorize>
                  <div>
                    <Text>Email</Text>
                    <Field
                      name="email"
                      component={InputFieldStyled}
                      inputProps={{
                        maxLength: 250,
                      }}
                      disabled={!canUpdateUserProfile}
                    />
                  </div>
                  {/* <div>
                    <Text>Mobile No</Text>
                    <Field
                      name="mobileNo"
                      component={InputFieldStyled}
                      inputProps={{
                        maxLength: 10,
                      }}
                      disabled={!canUpdateUserProfile}
                    />
                  </div> */}
                  <Typography className="edit-profile-desc" color="textSecondary">
                    ***สามารถแก้ไขข้อมูลผู้ใช้ เมื่อผ่านการทำ KYC แล้ว
                  </Typography>
                  {!canChangeRole ? (
                    <Typography className="edit-profile-desc" color="error">
                      ***คุณไม่สามารถแก้ไข Role ของตัวเองได้
                    </Typography>
                  ) : null}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignSelf: 'center',
                      padding: '20px',
                      gridColumnGap: '10px',
                    }}
                  >
                    <Button
                      style={{ alignSelf: 'center' }}
                      type="submit"
                      disabled={!dirty || submitting || !valid}
                    >
                      Confirm
                    </Button>
                    <Button
                      style={{
                        alignSelf: 'center',
                        backgroundColor: '#FF0000',
                        borderColor: '#FF0000',
                      }}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </ContentLayout>
              </form>
            )
          }}
        </Form>
      </div>
    </Modal>
  )
}
export default ModalUpdateUserProfile
