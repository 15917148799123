import React, { useMemo, useCallback } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import styled from '@emotion/styled'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { get } from 'lodash'

export type DataSourceProps = any
export type ColumnsProps = {
  title: string
  dataIndex: string //บังคับใส่เพราะเอาไปทำ key
  minWidth?: string | number
  maxWidth?: string | number
  width?: string | number
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined
  render?: (data: string, record: any) => JSX.Element
}[]
export type PaginationProps = {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
}
export type TableProps = {
  data: DataSourceProps
  columns: ColumnsProps
  pagination?: PaginationProps
  onPageChange?: (page: number) => void
  style?: CSSProperties
}

const CustomTableHeader = (props: { columns: ColumnsProps }) => {
  const { columns } = props
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, colIndex) => (
          <TableCell
            key={`${colIndex}-${column.dataIndex}`}
            align={column.align}
            style={{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              width: column.width,
            }}
          >
            <h6>{column.title}</h6>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const CustomTableBody = (props: { columns: ColumnsProps; data: any[] }) => {
  const { columns, data } = props

  const renderBody = useMemo(() => {
    return (
      <>
        {data.map((item, rowIndex) => {
          return (
            <TableRow key={`row-${rowIndex}`}>
              {columns.map((column, colIndex) => {
                const columnData = get(item, column.dataIndex)

                return (
                  <TableCell key={`${rowIndex}-${colIndex}-${column.dataIndex}`}>
                    {column.render && column.render(columnData, item)}
                    {!column.render && columnData}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </>
    )
  }, [data, columns])

  return <TableBody>{renderBody}</TableBody>
}

const RootLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default (props: TableProps) => {
  const {
    data = [],
    columns,
    pagination = { currentPage: 0, totalPages: 0 },
    onPageChange,
    style,
  } = props
  const { currentPage, totalPages } = pagination

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      onPageChange?.(newPage)
    },
    [onPageChange],
  )

  return (
    <RootLayout style={style}>
      <TableContainer>
        <Table>
          <CustomTableHeader columns={columns} />
          <CustomTableBody columns={columns} data={data} />
        </Table>
      </TableContainer>
      <Pagination
        style={{ marginTop: 10 }}
        count={totalPages || 0}
        color="primary"
        variant="outlined"
        shape="rounded"
        onChange={handleChangePage}
        page={currentPage || 0}
        showFirstButton
        showLastButton
      />
    </RootLayout>
  )
}
