import React, { useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_helpers'
import { NavLink } from 'react-router-dom'
import * as path from '../../../../app/constants/path'

export function QuickSettingToggler() {
  return (
    <NavLink to={path.setting()}>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="quick-setting-tooltip">Setting</Tooltip>}
      >
        <div
          className="btn btn-icon btn-clean btn-lg w-40px h-40px"
          id="kt_quick_setting_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
        >
          <span className="symbol symbol-30 symbol-lg-40">
            <span className="svg-icon svg-icon-lg">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')} />
            </span>
          </span>
        </div>
      </OverlayTrigger>
    </NavLink>
  )
}
