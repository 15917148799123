import { User } from '../user/user-typed'

export type BankAccount = {
  approvedStatus: BankAccountStatus
  bank: string
  branch: string
  createdAt: string
  deletedAt: string
  id: string
  idempotencyKey: string
  isDefault: string
  name: string
  no: string
  rejectedAt: string
  remark: string
  updatedAt: string
  userId: string
  user: User
  imageBankAccount: Attachment
}
export type AddressCitizen = {
  addressableId: string
  addressableType: string
  createdAt: string
  deletedAt: string
  detail: string
  district: string
  houseNo: string
  id: string
  mobileNo: string
  province: string
  subDistrict: string
  type: string
  updatedAt: string
  zipCode: string
}
export type AddressRecent = {
  addressableId: string
  addressableType: string
  createdAt: string
  deletedAt: string
  detail: string
  district: string
  houseNo: string
  id: string
  mobileNo: string
  province: string
  subDistrict: string
  type: string
  updatedAt: string
  zipCode: string
}
export type AddressWork = {
  addressableId: string
  addressableType: string
  createdAt: string
  deletedAt: string
  detail: string
  district: string
  houseNo: string
  id: string
  mobileNo: string
  province: string
  subDistrict: string
  type: string
  updatedAt: string
  zipCode: string
}

export type PageMeta = {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
}

export type BankAccountList = {
  items: BankAccount[]
  meta: PageMeta
}
export type Attachment = {
  attachableId: string
  attachableType: string
  createdAt: string
  deletedAt: string
  id: string
  path: string
  type: string
  updatedAt: string
}

export type Image = {
  image: string
}

export type VerifyBankAccount = {
  bankAccountId: string
  status: string
  remark?: string
}

export enum BankAccountStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}
