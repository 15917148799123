import { useMemo } from 'react'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router'
import queryString from 'qs'

import { capitalize } from 'voca'
import { UserStatus } from '../app/services/user/user-typed'
import { AxiosResponse } from 'axios'
import downloadjs from 'downloadjs'
import { Address } from '../app/services/port/port-typed'
import { BankAccountStatus } from '../app/services/bankaccount/bankaccount-typed'
import {
  EnumTransactionStatus,
  EnumTransactionType,
  EnumTransactionTypeCapitalize,
} from '../app/services/transaction/transaction-typed'

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const useRouter = <TQuery extends any = any>() => {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const query = useMemo(() => {
    return {
      ...queryString.parse(location.search.slice(1)),
      ...params,
    } as TQuery
  }, [location.search, params])

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query,
      match,
      location,
    }
  }, [location, match, history.push, history.replace, query])
}

export const setToken = (token: string) => {
  localStorage.setItem('AUTH_TOKEN', token)
}

export const getToken = () => {
  return localStorage.getItem('AUTH_TOKEN')
}

export const clearToken = () => {
  localStorage.removeItem('AUTH_TOKEN')
}

export const setLanguage = (lang: string) => {
  localStorage.setItem('LANGUAGE', lang)
}

export const getLanguage = () => {
  return localStorage.getItem('LANGUAGE')
}

export const setUserId = (userId: string) => {
  localStorage.setItem('UID', userId)
}

export const getUserId = () => {
  return localStorage.getItem('UID')
}

export const createFormData = <T extends object>(data: T) => {
  const formData = new FormData()
  Object.entries(data).forEach(([k, v]) => {
    formData.append(k, v)
  })
  return formData
}

export const getColorStatusTransactionBadge = (status: string) => {
  if (status === 'success') {
    return 'primary'
  } else if (status === 'fail') {
    return 'danger'
  } else if (status === 'pending') {
    return 'warning'
  } else return 'secondary'
}
export const getColorStatusTransactionText = (status: string) => {
  if (status === 'Success') {
    return '#00b050'
  } else if (status === 'Fail') {
    return '#FF0000'
  } else if (status === 'Pending') {
    return '#ffc000'
  } else return '#00FF7F'
}
export const getColorPaymentStatus = (status: string) => {
  if (status === 'success') {
    return '#00b050'
  } else if (status === 'failed') {
    return '#FF0000'
  } else if (status === 'pending') {
    return '#ffc000'
  } else return '#00FF7F'
}
export const getColorStatusTransactionDetail = (status: string) => {
  if (status === 'success') {
    return '#00b050'
  } else if (status === 'fail') {
    return '#FF0000'
  } else if (status === 'pending') {
    return '#ffc000'
  } else return '#00FF7F'
}

export const getColorStatusBankAccountBadge = (status: string) => {
  if (status === 'approved') {
    return 'primary'
  } else if (status === 'rejected') {
    return 'danger'
  } else if (status === 'pending') {
    return 'warning'
  } else return 'secondary'
}

export const getColorStatusKycText = (status: string) => {
  if (status === 'approved') {
    return '#00b050'
  } else if (status === 'rejected') {
    return '#FF0000'
  } else if (status === 'pending') {
    return '#ffc000'
  } else return '#00FF7F'
}

export const getColorStatusKycBadge = (status: string) => {
  if (status === 'approved') {
    return 'primary'
  } else if (status === 'rejected') {
    return 'danger'
  } else if (status === 'pending') {
    return 'warning'
  }
}
export const getColorStatusBankAccountText = (status?: BankAccountStatus) => {
  switch (status) {
    case BankAccountStatus.APPROVED:
      return '#00b050'
    case BankAccountStatus.REJECTED:
      return '#FF0000'
    case BankAccountStatus.PENDING:
      return '#ffc000'
    case BankAccountStatus.CANCELED:
      return '#002372'
    default:
      return 'transparent'
  }
}

export const getTransactionTypeColor = (
  transactionType?: EnumTransactionType | EnumTransactionTypeCapitalize,
) => {
  switch (transactionType) {
    case EnumTransactionType.BUY:
    case EnumTransactionTypeCapitalize.BUY:
      return '#00b050'
    case EnumTransactionType.SELL:
    case EnumTransactionTypeCapitalize.SELL:
      return '#FF0000'
    case EnumTransactionType.DCA:
    case EnumTransactionTypeCapitalize.DCA:
      return '#000000'
    case EnumTransactionType.DELIVERY:
    case EnumTransactionTypeCapitalize.DELIVERY:
      return '#005fa3'
    case EnumTransactionType.PICK_UP:
    case EnumTransactionTypeCapitalize.PICK_UP:
      return '#00b0f0'
    case EnumTransactionType.TRANSFER_IN:
    case EnumTransactionTypeCapitalize.TRANSFER_IN:
      return '#04ff00'
    case EnumTransactionType.TRANSFER_OUT:
    case EnumTransactionTypeCapitalize.TRANSFER_OUT:
      return '#ff0084'
    case EnumTransactionType.ONE_TIME_DELIVERY:
    case EnumTransactionTypeCapitalize.ONE_TIME_DELIVERY:
      return '#7B68EE'
    default:
      return '#FFFFFF'
  }
}
export const getColorTransactionStatus = (status?: EnumTransactionStatus) => {
  switch (status) {
    case EnumTransactionStatus.FAIL:
      return '#ff0000'
    case EnumTransactionStatus.SUCCESS:
      return '#00b050'
    case EnumTransactionStatus.PENDING:
      return '#ffc000'
    case EnumTransactionStatus.AWAITING_TRANSFER:
      return '#d98602'
    case EnumTransactionStatus.AWAIT_SAFEGOLD_CONFIRM:
      return '#e6de0e'
    case EnumTransactionStatus.REFUND:
      return '#001b74'
    default:
      return '#1E90FF'
  }
}

export const getColorUserStatus = (status: UserStatus) => {
  switch (status) {
    case UserStatus.BLOCKED:
      return '#ff0000'
    default:
      return '#00b050'
  }
}

export const replaceUnderScoreAndCapitalize = <T extends any>(text: T) => {
  if (typeof text === 'string') {
    return (text || '')
      .split('_')
      .map(v => capitalize(v))
      .join(' ') as EnumTransactionTypeCapitalize
  }

  return text
}

export const downloadFile = async (res: AxiosResponse<Blob>) => {
  const filename = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
  downloadjs(res.data, decodeURIComponent(filename))
}

export const customAddressLabel = (address?: Address): string => {
  const {
    province = '',
    district = '',
    subDistrict = '',
    detail = '',
    zipCode = '',
    houseNo = '',
  } = address || {}
  return province ? [houseNo, detail, subDistrict, district, province, zipCode].join(' ') : '-'
}

export const isDevelopment = process.env.NODE_ENV === 'development'

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const normalizeNumberWith4Digit = (value: any) => {
  if (!value) return value
  value = value.toString()

  // const regex = /^-?\d+(?:\.\d+)?$/gm
  let onlyNumber = value.replace(/[^\d.-]/g, '')
  if (onlyNumber.includes('..')) {
    onlyNumber = onlyNumber.replace('..', '.')
  }
  if (onlyNumber.includes('--')) {
    onlyNumber = onlyNumber.replace('--', '-')
  }
  if (onlyNumber.includes('-.')) {
    onlyNumber = onlyNumber.replace('-.', '-0.')
  }
  if (onlyNumber.includes('.-')) {
    onlyNumber = onlyNumber.replace('.-', '.')
  }
  if (onlyNumber.length > 1 && onlyNumber.slice(-1) === '-') {
    onlyNumber = onlyNumber.slice(0, -1) // remove last char
  }

  const temp = onlyNumber.slice(0, -1).indexOf('.')
  if (temp !== -1 && onlyNumber.slice(-1) === '.') {
    onlyNumber = onlyNumber.slice(0, -1) // remove last char
  }
  const indexOfDot = onlyNumber.indexOf('.')
  let result = ''
  if (indexOfDot !== -1) {
    if (indexOfDot === 10) {
      result = onlyNumber.substring(0, 14)
    } else if (onlyNumber.length < 14) {
      result =
        onlyNumber.substring(0, indexOfDot) + onlyNumber.substring(indexOfDot, indexOfDot + 5)
    } else {
      result = onlyNumber.substring(0, 10) + onlyNumber.substring(indexOfDot, indexOfDot + 5)
    }
  } else {
    if (onlyNumber.length >= 10) {
      result = onlyNumber.substring(0, 10)
    } else {
      result = onlyNumber
    }
  }

  let v = ''
  if (result !== '-') {
    v =
      indexOfDot !== -1
        ? Number(result.substring(0, result.indexOf('.'))).toLocaleString() +
          result.substring(result.indexOf('.'), result.length)
        : Number(result).toLocaleString()
  } else {
    v = result
  }
  return v
}

export const formatMobile = /^(^[0-9]{10}$)|(^\+[0-9]{2}\s+[0-9]{2}[0-9]{8}$)|(^[0-9]{3}-[0-9]{4}-[0-9]{4}$)/

export const formatTmnId = /^tmn\.[0-9]{11}$/

export const formatThaiId = (thaiId: string): boolean => {
  const m = thaiId.match(/(\d{12})(\d)/)
  if (!m) {
    return false
    // console.warn('Bad input from user, invalid thaiId=', thaiId)
    // throw new Error('thai-id-must-be-13-digits')
  }
  const digits = m[1].split('')
  const sum = digits.reduce((total: number, digit: string, i: number) => {
    return total + (13 - i) * +digit
  }, 0)
  const lastDigit = `${(11 - (sum % 11)) % 10}`
  const inputLastDigit = m[2]
  if (lastDigit !== inputLastDigit) {
    return false
    // console.warn('Bad input from user, invalid checksum thaiId=', thaiId)
    // throw new Error('thai-id-checksum-mismatched')
  }
  return true
}

export const composeValidators = (...validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined)
