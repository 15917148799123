import React, { useMemo } from 'react'
import {
  getColorStatusBankAccountText,
  replaceUnderScoreAndCapitalize,
  useRouter,
} from '../../../../utils/helper'
import ImageViewer from '../../../components/common/ImageViewer'
import Text from '../../../components/common/Text'
import styled from '@emotion/styled'
import DetailPage, { ContentType } from '../../../components/templates/DetailPage'
import {
  useGetBankAccount,
  useGetImageBankAccount,
} from '../../../services/bankaccount/bankaccount-query'
import UserCard from '../../user/UserProfile/UserCard'
import { BankAccountStatus } from '../../../services/bankaccount/bankaccount-typed'
import BankAccountOnPendingAction from './BankAccountOnPendingAction'
import BankAccountOnApprovedAction from './BankAccountOnApprovedAction'

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`
const TextColor = styled(Text)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`
const ActionLayout = styled.div`
  grid-column: 1 / -1;
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
  flex: 1;
  width: 100%;
`
const ImageViewerStyled = styled(ImageViewer)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
  flex: 1;
  width: 50%;
  .thumb-image {
    width: 100%;
    height: 100%;
    max-height: 500px;
    margin: unset;
  }
`

export const BankAccountDetail = () => {
  const { bankAccountId } = useRouter().query

  const { data: bankAccountData } = useGetBankAccount(bankAccountId)
  const {
    approvedStatus,
    bank = '',
    branch = '',
    no = '',
    remark = '',
    userId = '',
    imageBankAccount = '',
  } = bankAccountData || {}

  const { id: imageBankAccountId = '' } = imageBankAccount || {}
  const { data: imageBackAccountData } = useGetImageBankAccount(imageBankAccountId)

  const dataBankAccount = useMemo((): ContentType[] => {
    return [
      { label: 'Bank Name', value: bank === null || branch === null ? '-' : `${bank} - ${branch}` },
      { label: 'Remark', value: remark ? remark : '-' },
      {
        component: (
          <ActionLayout>
            {approvedStatus === BankAccountStatus.APPROVED && (
              <BankAccountOnApprovedAction bankAccountId={bankAccountId} />
            )}
            {approvedStatus === BankAccountStatus.PENDING && (
              <BankAccountOnPendingAction bankAccountId={bankAccountId} />
            )}
          </ActionLayout>
        ),
      },
    ]
  }, [approvedStatus, bank, bankAccountId, branch, remark])

  const imageBackAccount = useMemo(() => {
    const image = imageBackAccountData?.image
    return image ? <ImageViewerStyled images={[image]} /> : undefined
  }, [imageBackAccountData])

  return bankAccountData ? (
    <DetailPage
      title={
        <div>
          <Flex>
            <Text variant="h5" bold>
              Bank Account Number {no}
            </Text>
            <TextColor variant="h5" color={getColorStatusBankAccountText(approvedStatus)}>
              {`(${replaceUnderScoreAndCapitalize(approvedStatus)})`}
            </TextColor>
          </Flex>
        </div>
      }
      contents={dataBankAccount}
      gridItems={[<UserCard userId={userId} />]}
      rightContent={imageBackAccount}
    />
  ) : (
    <></>
  )
}
export default BankAccountDetail
