import { useTranslation } from 'react-i18next'
import { useToastForReactQuery } from '../../../../utils/custom-hooks'

import styled from '@emotion/styled'
import { useCallback, useMemo } from 'react'
import { DateRangePickerField, InputField, SelectField } from '../../../components/fields'
import { Field, Form } from 'react-final-form'
import { Modal } from '@material-ui/core'
import {
  EnumGiveawayStatus,
  EnumGiveawayUnit,
  IExportGiveawayReportParams,
} from '../../../services/giveaway/giveaway-typed'
import React from 'react'

import dayjs from 'dayjs'
import { DateRangePickerValue } from '../../../components/common/DateRangePicker'
import { useGetGiveawayReport } from '../../../services/giveaway/giveaway-query'
import { Button } from 'react-bootstrap'
import { values } from 'lodash'
import Card from '../../../components/common/Card'
import Text from '../../../components/common/Text'

const InputFieldStyled = styled(InputField)`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 0;
  }
`

const ContentLayout = styled(Card)`
  min-height: 200px;
  padding: 30px 40px 20px 40px;
  grid-row-gap: 12px;
`

const ModalStyled = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const MAX_DAYS_SPAN = 60
const MAX_MONTHS_SPAN = Math.floor(MAX_DAYS_SPAN / 30)

type ExportGiveawayFormValues = {
  prefix?: string
  filterRange: DateRangePickerValue
  status?: BaseOptionType<EnumGiveawayStatus>
  unit?: BaseOptionType<EnumGiveawayUnit>
}

type ModalCreateGiveawayProps = {
  visible: boolean
  onClose: () => void
}

const ModalExportGiveaway = (props: ModalCreateGiveawayProps) => {
  const { visible, onClose } = props

  const toast = useToastForReactQuery()
  const { t } = useTranslation()

  const { mutate: exportGiveaway } = useGetGiveawayReport()

  const onSubmit = useCallback(
    (values: ExportGiveawayFormValues) => {
      const createBody: IExportGiveawayReportParams = {
        prefix: values?.prefix,
        startDate: values.filterRange.startDate,
        endDate: values.filterRange.endDate,
        status: values.status?.value,
        unit: values.unit?.value,
      }
      exportGiveaway(createBody, {
        onSuccess: () => {
          toast.open('Success', {
            type: 'success',
            autoClose: 3 * 1000,
          })
          onClose()
        },
        onError: error => {
          toast.open(error, {
            type: 'error',
            autoClose: 3 * 1000,
          })
          onClose()
        },
      })
    },
    [exportGiveaway, onClose, toast],
  )
  const validateRequired = useCallback((value: string) => (value ? undefined : 'required'), [])

  const statusOptions = useMemo(() => {
    return values(EnumGiveawayStatus).map(status => {
      return {
        label: status.toUpperCase(),
        value: status,
      }
    })
  }, [])

  const unitOptions = useMemo(() => {
    return values(EnumGiveawayUnit).map(unit => {
      return {
        label: unit.toUpperCase(),
        value: unit,
      }
    })
  }, [])

  return (
    <ModalStyled open={visible} onClose={onClose}>
      <div>
        <Form<ExportGiveawayFormValues>
          onSubmit={onSubmit}
          //initialValues={initialValues}

          subscription={{ dirty: true, submitting: true, valid: true }}
          validate={values => {
            const errors: Partial<Record<keyof ExportGiveawayFormValues, string>> = {}
            if (values.filterRange) {
              const { startDate, endDate } = values.filterRange
              const monthsDiff = dayjs(endDate).diff(dayjs(startDate), 'month')
              if (Math.abs(monthsDiff) > MAX_MONTHS_SPAN) {
                errors.filterRange = `The start date should be greater that ${MAX_MONTHS_SPAN} months of the end date .`
              }
            }
            return errors
          }}
        >
          {({ handleSubmit, dirty, submitting, valid, form }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ContentLayout>
                  <div>
                    <Text>Prefix</Text>
                    <Field name="prefix" component={InputFieldStyled} />
                  </div>
                  <DateRangePickerField
                    validate={validateRequired}
                    name="filterRange"
                    label="Date Range"
                  />
                  <div style={{ margin: '16px 0px' }}>
                    <SelectField
                      name="status"
                      label="Status"
                      placeholder="Status"
                      options={statusOptions}
                    />
                  </div>
                  <SelectField name="unit" label="unit" placeholder="Unit" options={unitOptions} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: '24px',
                      gridColumnGap: '10px',
                    }}
                  >
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!dirty || submitting || !valid}
                    >
                      Confirm
                    </Button>
                    <Button variant="outline-secondary" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                </ContentLayout>
              </form>
            )
          }}
        </Form>
      </div>
    </ModalStyled>
  )
}

export default ModalExportGiveaway
