import { useMutation, useQuery } from 'react-query'
import { parseJsonText } from 'typescript'
import { api } from '../../../utils/api'
import { UserList } from '../user/user-typed'
import { NotiDetail, NotificationUpdateResponse, NotiList } from './notification-typed'

const NOTIFICATION_URL = `internal/notifications`

export const useGetNotiList = (params?: { q?: string; page?: number }) => {
  return useQuery(
    [NOTIFICATION_URL, params],
    async () => {
      const datas = await api.savvyGold.get<NotiList>(NOTIFICATION_URL, {
        page: params?.page,
        limit: 10,
        q: params?.q,
      })

      return datas.data.data
    },

    {
      keepPreviousData: true,
    },
  )
}

export const useGetNotificationDetail = ({ notificationId }: { notificationId: string }) => {
  return useQuery(
    [NOTIFICATION_URL, notificationId],
    async () => {
      const datas = await api.savvyGold.get<NotiDetail>(`${NOTIFICATION_URL}/${notificationId}`)
      return datas.data.data
    },
    { enabled: !!notificationId },
  )
}

export const useGetUserOptions = (q: string) => {
  return useQuery(
    [`internal/users/search`, q],
    async () => {
      const datas = await api.savvyGold.get<UserList>(`internal/users/search`, {
        limit: 5,
        q,
        filter: ['email'],
      })

      return datas.data.data
    },

    {
      keepPreviousData: true,
    },
  )
}

export const useUpdateNotification = () => {
  const mutate = useMutation(
    ({ params, notificationId }: any) => {
      /*   console.log('API', params, notificationId) */
      return api.savvyGold.put<NotificationUpdateResponse>(
        `${NOTIFICATION_URL}/${notificationId}`,
        params,
      )
    },
    {
      onSuccess: data => {
        console.log('sssssss', 'success')
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}

export const useCreateNotification = () => {
  const mutate = useMutation(
    ({ params }: any) => {
      /*   console.log('API', params, notificationId) */
      return api.savvyGold.post<NotificationUpdateResponse>(`${NOTIFICATION_URL}`, params)
    },
    {
      onSuccess: data => {
        console.log('sssssss', 'success')
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}
