import React from 'react'
import UserProfileCard from '../../../components/templates/UserProfileCard'
import { useCurrentUser, useGetUser } from '../../../services/user/user-query'
import { useRouter } from '../../../../utils/helper'
import PortCard from '../../port/PortDetail/PortCard'
import BankAccountCard from '../../bankaccount/BankAccountDetail/BankAccountCard'
import KycCard from '../../kyc/KycDetail/KycCard'

export interface ImageCoroselProps {
  src: string
}

export const UserProfile = () => {
  const { userId } = useRouter().query
  const { data: user } = useGetUser(userId)
  const { data: currUser } = useCurrentUser()

  const { role } = currUser || {}
  const { portId = '', bankAccount, kyc } = user || {}
  const { id: bankAccountId = '' } = bankAccount || {}
  const { id: kycId = '' } = kyc?.kyc || {}

  return user ? (
    <UserProfileCard
      user={user}
      gridItems={[
        <>
          {portId ? <PortCard portId={portId} /> : null}
          {bankAccountId ? <BankAccountCard bankAccountId={bankAccountId} /> : null}
          {kycId ? <KycCard kycId={kycId} /> : null}
        </>,
      ]}
      role={role}
    />
  ) : (
    <></>
  )
}
export default UserProfile
