import React, { useCallback, useMemo } from 'react'
import ListPage from '../../../components/templates/ListPage'
import ButtonViewTable from '../../../components/ButtonViewTable'
import UserProfileNameCitizen from '../../../components/UserProfileNameCitizen'
import { useHistory } from 'react-router'
import * as paths from '../../../constants/path'
import {
  getColorStatusKycText,
  replaceUnderScoreAndCapitalize,
  useRouter,
} from '../../../../utils/helper'
import { EnumGetKycListFilter, useGetkycs } from '../../../services/kyc/kyc-query'
import { Kyc } from '../../../services/kyc/kyc-typed'
import Text from '../../../components/common/Text'
import { Link } from 'react-router-dom'
import ExportKycReportButton from './ExportKycReportButton'
import dayjs from 'dayjs'
import { Box } from '@material-ui/core'
import Select from '../../../components/common/Select'
import { values } from 'lodash'
import { OnChangeValue } from 'react-select'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/react'
import { appConfig } from '../../../constants/app-config'

const column = (onClick: any) => {
  return [
    {
      title: 'User',
      dataIndex: 'name',
      render: (data: string, kyc: Kyc) => {
        const { lastnameTh, citizenId, firstnameTh } = kyc
        return (
          <UserProfileNameCitizen
            firstnameTh={firstnameTh} //datatest
            lastnameTh={lastnameTh}
            citizenId={citizenId}
          />
        )
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text: string, kyc: Kyc) => {
        const { email, userId } = kyc || {}
        return email ? (
          <Link to={`/users/${userId}`}>
            <Text>{email}</Text>
          </Link>
        ) : (
          <Text>''</Text>
        )
      },
    },
    {
      title: 'Mobile No.',
      dataIndex: 'mobileNo',
      render: (text: string, kyc: Kyc) => {
        const { mobileNo, userId } = kyc || {}
        return mobileNo ? (
          <Link to={`/users/${userId}`}>
            <Text>{mobileNo}</Text>
          </Link>
        ) : (
          <Text>''</Text>
        )
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (text: string, record: { createdAt: string }) => {
        const { createdAt } = record
        return <Text>{dayjs(createdAt).format('D MMMM  YYYY HH:mm ')}</Text>
      },
    },
    {
      title: 'Status',
      dataIndex: 'ekycStatus',
      render: (text: string, record: { ekycStatus: string }) => {
        const { ekycStatus } = record
        return (
          <Text variant="h6" style={{ color: getColorStatusKycText(ekycStatus) }}>
            {replaceUnderScoreAndCapitalize(ekycStatus)}
          </Text>
        )
      },
    },
    {
      title: '',
      dataIndex: 'buttonView',
      render: (text: string, kyc: Kyc) => {
        return <ButtonViewTable onClick={onClick.bind(null, kyc.id)} />
      },
    },
  ]
}

export const KycList = () => {
  const { push, query } = useRouter()
  const { data: kycs } = useGetkycs({ q: query.q, page: query.page, filter: query.filter })
  const history = useHistory()

  const setQueryParam = useCallback(
    params => {
      push(paths.kycs({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )

  const onSearch = useCallback(
    text => {
      setQueryParam({ q: text, page: 1 })
    },
    [setQueryParam],
  )

  const onViewClick = useCallback(
    (kycId: string) => {
      history.push(paths.kycDetail({ routeParam: { kycId } }))
    },
    [history],
  )

  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  const onFilterChange = useCallback(
    (value: OnChangeValue<BaseOptionType, false>) => {
      setQueryParam({ filter: value?.value, page: 1 })
    },
    [setQueryParam],
  )

  const kycFilterOptions = useMemo(() => {
    return values(EnumGetKycListFilter).map(
      (e): BaseOptionType => {
        return {
          label: e
            .toString()
            .toUpperCase()
            .replaceAll('_', ' '),
          value: e.toString(),
        }
      },
    )
  }, [])

  const filterValue = useMemo(() => {
    const filter = query?.filter ?? EnumGetKycListFilter.ALL
    return kycFilterOptions.find(e => e.value === filter)
  }, [kycFilterOptions, query])

  return (
    <ListPage
      searchInputProps={{ onSearch, value: query.q }}
      topbar={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gridColumnGap="8px"
          width="100%"
        >
          {appConfig.ENABLE_FEATURE_TRUE_MONEY && (
            <ClassNames>
              {({ css }) => (
                <Select
                  className={css`
                    min-width: 160px;
                  `}
                  value={filterValue}
                  options={kycFilterOptions}
                  onChange={onFilterChange}
                />
              )}
            </ClassNames>
          )}
          <ExportKycReportButton />
        </Box>
      }
      tableProps={{
        columns: column(onViewClick),
        data: kycs?.items,
        onPageChange,
        pagination: kycs?.meta,
      }}
    />
  )
}
export default KycList
