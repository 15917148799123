import React, { Fragment } from 'react'
import styled from '@emotion/styled'

import Text from '../common/Text'
import { Card } from 'react-bootstrap'
import { useCallback } from 'react'

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`
const RootLayout = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
`

const BodyLayout = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  align-self: self-start;
`

const ItemWarp = styled(Grid)`
  grid-template-columns: 150px auto;
  align-self: self-start;
`

export type ContentType = {
  label?: string
  value?: string | JSX.Element
  linkTo?: string
  position?: 'left' | 'right'
  colSpan?: 1 | 2
}

type DetailPage2Props = {
  title: JSX.Element | string
  contents: ContentType[]
  gridItems?: JSX.Element[]
  extendLayout?: JSX.Element
}

const DetailPage2 = (props: React.PropsWithChildren<DetailPage2Props>) => {
  const { title, contents, gridItems, extendLayout } = props

  const renderContent = useCallback((content: ContentType, index: number) => {
    const { label, value, linkTo, colSpan = 1 } = content
    return (
      <ItemWarp
        key={`${label!}${index}`}
        style={{ gridColumn: colSpan === 1 ? undefined : '1 / -1' }}
      >
        <Text>{label}</Text>
        {typeof value === 'string' || value === undefined || value === null ? (
          <Text bold linkTo={linkTo}>
            {value}
          </Text>
        ) : (
          value
        )}
      </ItemWarp>
    )
  }, [])

  return (
    <RootLayout>
      <Card style={{ gridColumn: '1 / -1' }}>
        <Card.Body>
          <div>
            {typeof title === 'string' ? (
              <Text variant="h5" bold>
                {title}
              </Text>
            ) : (
              title
            )}
          </div>
          <BodyLayout>{contents.map(renderContent)}</BodyLayout>
          {extendLayout ? extendLayout : null}
        </Card.Body>
      </Card>
      {gridItems?.map((gridItem, index) => (
        <Fragment key={`${index}`}>{gridItem}</Fragment>
      ))}
    </RootLayout>
  )
}

export default DetailPage2
