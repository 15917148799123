import { useQuery, useQueryClient, useMutation } from 'react-query'
import { IGetSettingDetailResponse, IPatchSettingDetailBodyParams } from './setting-type'
import { api } from '../../../utils/api'

export const BASE_SETTING_URL = 'internal/setting'
export const BASE_SERVER_MAINTENANCE_URL = `${BASE_SETTING_URL}/maintenance`

export const useGetSettingDetail = () => {
  return useQuery([BASE_SETTING_URL], async () => {
    const response = await api.savvyGold.get<IGetSettingDetailResponse>(BASE_SERVER_MAINTENANCE_URL)
    return response.data.data
  })
}

export const usePatchSetting = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IPatchSettingDetailBodyParams) => {
      const { data } = await api.savvyGold.patch(`${BASE_SERVER_MAINTENANCE_URL}`, params)
      return data
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries([BASE_SETTING_URL])
      },
    },
  )
}
