import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { Form } from 'react-final-form'
import { Button } from 'react-bootstrap'
import { Dialog } from '@material-ui/core'
import { DateRangePickerValue } from '../../../components/common/DateRangePicker'
import { DateRangePickerField, SelectField } from '../../../components/fields'
import dayjs from 'dayjs'
import moment from 'moment'
import { pascalize } from 'humps'
import { EKycState } from '../../../services/kyc/kyc-typed'
import { map } from 'lodash'

const FormLayout = styled.form`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 24px;

  background-color: white;
  padding: 36px;

  width: 100%;
  min-width: 400px;
  max-width: 600px;
`

const ContentLayout = styled.div`
  width: 80%;
  display: flex;
  flex-flow: column;
  grid-row-gap: 20px;
`

const ButtonStyled = styled(Button)`
  width: max-content;
`

const kycStatusOptions = map(EKycState, value => ({
  value: `${value}`,
  label: pascalize(value),
}))

kycStatusOptions.unshift({
  value: '',
  label: 'All',
})

const MIN_YEAR = 2020
const MAX_DAYS_SPAN = 60
const MAX_MONTHS_SPAN = Math.floor(MAX_DAYS_SPAN / 30)

export type ExportKycFilterFormValues = {
  filterRange?: DateRangePickerValue
  status?: BaseOptionType<EKycState | ''>
}

type DialogExportKycFilterProps = {
  visible: boolean
  onClose: () => void
  onSubmit: (values: ExportKycFilterFormValues) => void
}

const DialogExportKycFilter = (props: DialogExportKycFilterProps) => {
  const { visible, onClose, onSubmit } = props

  const now = useMemo(() => {
    return dayjs()
  }, [])

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      hideBackdrop={false}
      onBackdropClick={onClose}
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
      PaperProps={{
        style: {
          borderRadius: 8,
        },
      }}
    >
      <Form<ExportKycFilterFormValues>
        onSubmit={onSubmit}
        initialValues={{
          status: {
            value: '',
            label: 'All',
          },
        }}
        validate={values => {
          const errors: Partial<Record<keyof ExportKycFilterFormValues, string>> = {}
          if (values.filterRange) {
            const { startDate, endDate } = values.filterRange
            const monthsDiff = dayjs(endDate).diff(dayjs(startDate), 'month')
            if (Math.abs(monthsDiff) > MAX_MONTHS_SPAN) {
              errors.filterRange = `The start date should be greater that ${MAX_MONTHS_SPAN} months of the end date .`
            }
          }
          return errors
        }}
      >
        {({ handleSubmit, values }) => {
          console.log({ values })
          return (
            <FormLayout onSubmit={handleSubmit}>
              <ContentLayout>
                <DateRangePickerField
                  name="filterRange"
                  label="Date Range"
                  option={{
                    minDate: moment(now.toDate())
                      .year(MIN_YEAR)
                      .startOf('years'),
                    maxDate: moment(now.toDate()),
                    minYear: MIN_YEAR,
                    showCustomRangeLabel: false,
                    maxSpan: {
                      days: MAX_DAYS_SPAN - 1,
                    },
                    ranges: {
                      Today: [moment(), moment()],
                      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                      'Last 60 Days': [moment().subtract(59, 'days'), moment()],
                      // 'This Year': [moment().startOf('year'), moment()],
                      // 'Last 4 Years': [moment().subtract(4, 'years'), moment()],
                    },
                  }}
                />
                <SelectField
                  name="status"
                  label="Status"
                  placeholder="Status"
                  options={kycStatusOptions}
                />
              </ContentLayout>
              <ButtonStyled type="submit">Submit</ButtonStyled>
            </FormLayout>
          )
        }}
      </Form>
    </Dialog>
  )
}

export default DialogExportKycFilter
