import numeral from 'numeral'
import React, { useMemo } from 'react'
import PortalCard from '../../../components/templates/PortalCard'
import { useGetPort } from '../../../services/port/port-query'

interface UserIdProps {
  portId: string
}

export const PortCard = (props: UserIdProps) => {
  const { portId } = props
  const { data: port } = useGetPort(portId)
  const { sgGoldBalance = '' } = port || {}

  const dataPort = useMemo(() => {
    let b = Number(sgGoldBalance)
    const goldbalance = b / 15.244
    const balance = numeral(goldbalance).format('0,0.0000')
    const balancegram = numeral(b).format('0,0.0000')
    const dcaDate = port?.dcaPlan?.date ? `every ${port?.dcaPlan?.date} of month` : '-'
    return [
      { label: 'Balance', value: balance ? `${balance} Baht Gold` : '-' },
      { label: 'Balance', value: balancegram ? `${balancegram} g.` : '-' },
      { label: 'DCA date', value: dcaDate },
    ]
  }, [port, sgGoldBalance])

  return <PortalCard title={'Port'} contents={dataPort} viewMoreUrl={`/ports/${portId}`} />
}

export default PortCard
