import React, { useCallback, useMemo } from 'react'
import { useRouter } from '../../../../utils/helper'
import ListPage from '../../../components/templates/ListPage'
import * as paths from '../../../constants/path'
import Button from '../../../components/common/Button'
import Text from '../../../components/common/Text'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { Chip } from '@material-ui/core'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import { useGetAllBanner } from '../../../services/banner/banner-query'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`
const ButtonStyled = styled(Button)`
  padding: 7px 13px;
`

const BannerList = () => {
  const { push, query } = useRouter()
  const { data: bannerData } = useGetAllBanner({ page: query.page, searchTitle: query.filterName })
  const { items, meta } = bannerData || {}

  const onViewClick = useCallback(
    record => {
      const routeParam: any = {
        id: record.id,
      }

      push(paths.bannerDetail({ routeParam }))
    },
    [push],
  )

  const onCreateClick = useCallback(
    record => {
      push(paths.bannerCreate())
    },
    [push],
  )

  const columns = useMemo(() => {
    return [
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        render: (text: string, record: { isActive: boolean }) => {
          const { isActive } = record
          return isActive ? (
            <Chip label="Active" color="primary" style={{ color: 'white' }} />
          ) : (
            <Chip label="Inactive" variant="outlined" color="default" />
          )
        },
      },
      {
        title: 'Publish Date',
        dataIndex: 'publishDate',
        render: (text: string, record: { publishDate: string }) => {
          const { publishDate } = record
          return <Text>{dayjs(publishDate).format('D MMMM  YYYY')}</Text>
        },
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        render: (text: string, record: { endDate: string }) => {
          const { endDate } = record
          return endDate ? (
            <Text>{dayjs(endDate).format('D MMMM  YYYY ')}</Text>
          ) : (
            <Text>{`Not published yet`}</Text>
          )
        },
      },
      {
        title: '',
        dataIndex: 'action',
        render: (text: string, record: {}) => (
          <Button onClick={() => onViewClick(record)}>View</Button>
        ),
      },
    ]
  }, [onViewClick])

  const setQueryParam = useCallback(
    params => {
      push(paths.banner({ queryParam: { ...query, ...params } }))
    },
    [push, query],
  )
  const onSearch = useCallback(
    text => {
      setQueryParam({ filterName: text, page: 1 })
    },
    [setQueryParam],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  return (
    <ListPage
      topbar={
        <Layout>
          <Authorize role={ROLE.MANAGER}>
            <ButtonStyled variant="warning" onClick={onCreateClick}>
              Create
            </ButtonStyled>
          </Authorize>
        </Layout>
      }
      tableProps={{
        columns,
        data: items,
        onPageChange,
        pagination: {
          currentPage: meta?.currentPage || 0,
          itemCount: meta?.itemCount || 0,
          itemsPerPage: meta?.itemsPerPage || 0,
          totalItems: meta?.totalItems || 0,
          totalPages: meta?.totalPages || 0,
        },
      }}
      searchInputProps={{ onSearch, value: query.filterName, placeholder: 'Search Title' }}
    />
  )
}

export default BannerList
