import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import { getColorTransactionStatus, replaceUnderScoreAndCapitalize } from '../../../../utils/helper'
import Text from '../../../components/common/Text'
import { EnumTransactionStatus } from '../../../services/transaction/transaction-typed'

const Layout = styled(Box)`
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 30px;

  > p {
    margin: 0;
  }
`

interface ITransactionStatusTag {
  status: EnumTransactionStatus
}
const TransactionStatusTag = (props: ITransactionStatusTag) => {
  const { status } = props
  return (
    // <Layout
    //   bgcolor={
    //     status === EnumTransactionStatus.REFUND ? getColorTransactionStatus(status) : 'unset'
    //   }
    //   color={status !== EnumTransactionStatus.REFUND ? getColorTransactionStatus(status) : 'white'}
    // >
    //   <Text bold>{replaceUnderScoreAndCapitalize(status)}</Text>
    // </Layout>
    <Layout color={getColorTransactionStatus(status)}>
      <Text bold>{replaceUnderScoreAndCapitalize(status)}</Text>
    </Layout>
  )
}

export default TransactionStatusTag
