import React from 'react'
import { TextField, TextFieldProps as MaterailTextFieldProps } from '@material-ui/core'
import dayjs from 'dayjs'

type DataPickerProps = MaterailTextFieldProps & {
  input: any
  meta: any
}

const DataPiker = (props: DataPickerProps) => {
  const { input, meta, ...rest } = props
  return (
    <TextField
      {...input}
      {...rest}
      inputProps={{
        min: dayjs().format('YYYY-MM-DDThh:mm'), // new Date().toISOString().slice(0, 16), ///"YYYY-MM-DDThh:mm"
      }}
    />
  )
}

export default DataPiker
